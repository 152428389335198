import { useMemo } from 'react';

import {
  InputPhoneProps,
  InputTextProps,
  InputWebsiteProps,
} from 'components/Input';
import { useLocalization } from 'providers/LocalizationProvider';

interface FormFields {
  businessName: InputTextProps;
  legalName: InputTextProps;
  website: InputWebsiteProps;
  phone: InputPhoneProps;
}

export function useFields() {
  const { t } = useLocalization('business_details_page');

  return useMemo<FormFields>(
    () => ({
      businessName: {
        label: t('input.business_name.label'),
        helper: t('input.business_name.helper_text'),
        controllerOptions: {
          name: 'businessName',
          rules: {
            required: t('input.business_name.error_message'),
            maxLength: {
              value: 90,
              message: t('input.business_name.max_limit_characters'),
            },
          },
        },
      },
      legalName: {
        label: t('input.company_legal_name.label'),
        helper: t('company_legal_name.helper_text'),
        controllerOptions: {
          name: 'legalName',
          rules: {
            required: t('input.company_legal_name.error_message'),
          },
        },
      },
      website: {
        label: t('input.website.label'),
        invalidMessage: t('input.website.error_invalid'),
        controllerOptions: {
          name: 'website',
        },
      },
      phone: {
        label: t('input.phone_number.label'),
        helper: t('phone_number.helper_text'),
        invalidMessage: t('phone_number.error_invalid'),
        controllerOptions: {
          name: 'phone',
          required: t('phone_number.error_message'),
        },
      },
    }),
    [t],
  );
}
