import { resolveAddress } from 'utils/address';

import { AddressFormValues } from '../Address';

export function resolvePartialLead({
  googlePlace,
  additionalAddressInfo,
}: AddressFormValues) {
  return {
    formattedAddress: googlePlace.formatted_address,
    additionalAddressInfo,
    googlePlaceId: googlePlace.place_id,
    googlePlaceRating: googlePlace.rating,
    latitude: googlePlace.geometry?.location?.lat(),
    longitude: googlePlace.geometry?.location?.lng(),
    address: resolveAddress(googlePlace.address_components),
  };
}
