import { theme } from '@gympass/yoga';
import styled from 'styled-components';

const { borders, colors, spacing } = theme;

export const footerHeight = 41;

export const Footer = styled.footer`
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${spacing.xsmall}px 0;
  background-color: ${colors.white};
  border-top: ${borders.small}px solid ${colors.light};

  svg {
    margin-right: ${spacing.xxsmall}px;
  }
`;
