import { useMemo } from 'react';

import { InputFileProps } from 'components/Input';
import { useLocalization } from 'providers/LocalizationProvider';
import verifyPDF from 'utils/verifypdf';

export interface FormFields {
  file: InputFileProps;
}

export function useFields() {
  const { t } = useLocalization();

  return useMemo<FormFields>(
    () => ({
      file: {
        labels: {
          label: t('digital_certificate_page.card_2.upload'),
          helper: t('digital_certificate_page.card_2.helper_text'),
          update: t('input.file.update'),
          remove: t('input.file.delete'),
          invalidMessage: t(
            'digital_certificate_page.card_2.upload.error_not_a_pdf',
          ),
        },
        accept: {
          'application/pdf': ['.pdf'],
        },
        controllerOptions: {
          name: 'file',
          rules: {
            validate: async (val?: File) => {
              if (!val) {
                return t(
                  'digital_certificate_page.card_2.upload.error_not_a_pdf',
                );
              }
              if (!(await verifyPDF(val))) {
                return t(
                  'digital_certificate_page.card_2.upload.error_pdf_without_signature',
                );
              }
              return true;
            },
          },
        },
      },
    }),
    [t],
  );
}
