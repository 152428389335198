import { UseLocalization } from 'providers/LocalizationProvider';

const decimalComma = {
  thousandSeparator: '.',
  decimalSeparator: ',',
};

const decimalPoint = {
  thousandSeparator: ',',
  decimalSeparator: '.',
};

const separators = {
  ar: decimalComma,
  br: decimalComma,
  cl: decimalComma,
  de: decimalComma,
  es: decimalComma,
  ie: decimalComma,
  it: decimalComma,
  mx: decimalPoint,
  gb: decimalPoint,
  us: decimalPoint,
};

export function resolveMaskProperties({ country, currency }: UseLocalization) {
  if (!country) {
    return {
      ...decimalPoint,
      prefix: `$ `,
    };
  }

  return {
    ...(separators[country] ?? decimalComma),
    prefix: `${currency?.symbol} `,
  };
}
