import Input from 'components/Input';

import * as S from './Confirmation.styles';
import { useFields } from './hooks';

function Confirmation() {
  const fields = useFields();

  return (
    <S.InputContainer>
      <Input.File {...fields.file} />
    </S.InputContainer>
  );
}

export default Confirmation;
