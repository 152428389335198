import { Box, Text } from '@gympass/yoga';
import { useFormContext } from 'react-hook-form';

import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import { useLocalization } from 'providers/LocalizationProvider';

import { priceStringToFloat } from '../../utils';

const plans = [
  {
    duration: 'annual',
    checked: false,
    period: 12,
  },
  {
    duration: 'semi_annual',
    checked: false,
    period: 6,
  },
  {
    duration: 'quarterly',
    checked: false,
    period: 3,
  },
  {
    duration: 'monthly',
    checked: false,
    period: 1,
  },
];

function MembershipPlanGymFacility() {
  const { currency, t } = useLocalization('pricing_page');
  const { watch } = useFormContext();
  const [durationPlan, price] = watch(['duration', 'price']);

  const currentPlan =
    plans.find(plan => plan.duration === durationPlan) ?? plans[0];
  const amount = currentPlan.period;
  const currentPrice = price ? priceStringToFloat(price) : 0;
  const currentPricePerPeriod = (currentPrice * amount).toFixed(2);

  const options = plans.map(({ checked, duration }) => ({
    label: t(`section_1.input.option.${duration}`),
    selected: currentPlan?.duration === duration ? true : checked,
    value: duration,
  }));

  return (
    <Box marginVertical="large">
      <>
        <Text.Medium marginBottom="large" lineHeight="medium">
          {t('section_1.version_2.title')}
        </Text.Medium>

        <Dropdown
          options={options}
          label=""
          controllerOptions={{
            name: 'duration',
          }}
        />
        <br />
        <br />
        <Input.Price
          label={t('section_1.input.price.monthly.label')}
          controllerOptions={{
            name: 'price',
            rules: {
              required: t('section_1.input.price.error_message'),
              validate: (value: string) => {
                if (parseFloat(value.replace(/[^\d.,]/g, '')) <= 0) {
                  return t('price.greater_than_0');
                }
                return true;
              },
            },
          }}
        />

        <Text
          marginTop="small"
          bgColor="clear"
          padding="small"
          borderRadius="8"
        >
          {t('section_1.version_2.calculation', {
            currencySymbol: currency?.symbol ?? '',
            currentPrice: String(currentPrice),
            currentPricePerPeriod,
          })}
        </Text>
      </>
    </Box>
  );
}

export default MembershipPlanGymFacility;
