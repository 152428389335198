import { FormProvider, useForm } from 'react-hook-form';

import StepTemplate from 'components/StepTemplate';
import useRecordFormErrorView from 'hooks/useRecordFormErrorView';
import useEventData from 'pages/Signup/hooks/useEventData';
import { useEventTracking } from 'providers/EventTrackingProvider';
import { useLead } from 'providers/LeadProvider';
import { useLocalization } from 'providers/LocalizationProvider';
import { useSnackbar } from 'providers/SnackbarProvider';
import { useSteps } from 'providers/StepsProvider';
import { useUpdateLead } from 'services/partnersSelfSignup';
import { base64ToFile } from 'utils/file';

import Form from './Form';
import { resolvePartialLead } from './utils';

export interface BusinessInfoFormValues {
  googlePlace?: google.maps.places.PlaceResult;
  formattedAddress?: string;
  additionalAddressInfo?: string;
  businessName: string;
  legalName: string;
  website?: string;
  taxId?: string;
  w9?: File;
  phone: string;
}

function BusinessInfo() {
  const { t } = useLocalization();
  const { lead, selfSignUpId, updateLead } = useLead();
  const { showSnackbar } = useSnackbar();
  const { next, updateLastStepSubmitted, current } = useSteps();
  const { mutateAsync, isLoading } = useUpdateLead();
  const { recordClick, recordErrorView } = useEventTracking();
  const { category, contextData } = useEventData();
  const methods = useForm<BusinessInfoFormValues>({
    mode: 'onChange',
    defaultValues: {
      businessName: lead?.businessName,
      legalName: lead?.legalName,
      website: lead?.website,
      taxId: lead?.taxId,
      phone: lead?.phone,
      w9: lead?.w9?.file?.id ? undefined : base64ToFile(lead?.w9?.file),
    },
  });

  useRecordFormErrorView<BusinessInfoFormValues>(methods);

  const onSubmit = async (values: BusinessInfoFormValues) => {
    const shouldSubmitW9 = methods.formState.dirtyFields.w9;

    const trackingValues = {
      category,
      contextData: {
        formValues: values,
        ...contextData,
      },
    };

    recordClick({
      ...trackingValues,
      label: 'continue',
    });

    try {
      const submittedLeadData = await resolvePartialLead(values);

      await mutateAsync({
        step: current,
        isLastStep: false,
        selfSignUpId: selfSignUpId as string,
        lastStepSubmitted: updateLastStepSubmitted(),
        lead: shouldSubmitW9
          ? { ...lead, ...submittedLeadData, memberships: undefined }
          : {
              ...lead,
              ...submittedLeadData,
              w9: undefined,
              memberships: undefined,
            },
      });

      updateLead(submittedLeadData);
      next();
    } catch {
      recordErrorView({
        ...trackingValues,
        label: 'something_went_wrong',
      });
      showSnackbar({ message: t('generic_error') });
    }
  };

  return (
    <FormProvider {...methods}>
      <StepTemplate<BusinessInfoFormValues>
        formAriaLabel={t('business_details_page.form.aria_label')}
        title={t('business_details_page.title.new_address_step')}
        onSubmit={onSubmit}
        loading={isLoading}
      >
        <Form />
      </StepTemplate>
    </FormProvider>
  );
}

export default BusinessInfo;
