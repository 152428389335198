import { Text } from '@gympass/yoga';
import { AlertCircle } from '@gympass/yoga-icons';

import Input from 'components/Input';
import RadioGroup, { RadioGroupContext } from 'components/RadioGroup';
import { Lead } from 'providers/LeadProvider';
import { useLocalization } from 'providers/LocalizationProvider';

import * as S from './Form.styles';
import { useFields } from './hooks';

type BusinessTypeOption = NonNullable<Lead['businessType']>;

function Form() {
  const { t } = useLocalization('partner_type_page');
  const { businessType, businessTypeOptions, numberOfLocations } = useFields();

  return (
    <S.Container>
      <RadioGroup {...businessType}>
        <RadioGroupContext.Consumer>
          {context =>
            businessTypeOptions.map(option => {
              const hasLocations =
                context?.value !== 'personal_trainer' &&
                context?.value === option.value;
              const isPersonalTrainer =
                option.value === 'personal_trainer' &&
                context?.value === 'personal_trainer';

              return (
                <RadioGroup.Card<BusinessTypeOption>
                  key={option.value}
                  {...option}
                >
                  {hasLocations && (
                    <S.Locations>
                      <Text marginBottom="large" fontWeight="medium">
                        {t('locations.title')}
                      </Text>
                      <Input.Number {...numberOfLocations} />
                    </S.Locations>
                  )}
                  {isPersonalTrainer && (
                    <S.BannerInformative
                      variant="informative"
                      icon={AlertCircle}
                      message={t('redirect_banner')}
                    />
                  )}
                </RadioGroup.Card>
              );
            })
          }
        </RadioGroupContext.Consumer>
      </RadioGroup>
    </S.Container>
  );
}

export default Form;
