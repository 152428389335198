import { FormProvider, useForm } from 'react-hook-form';

import StepTemplate from 'components/StepTemplate';
import useRecordFormErrorView from 'hooks/useRecordFormErrorView';
import useEventData from 'pages/Signup/hooks/useEventData';
import { useEventTracking } from 'providers/EventTrackingProvider';
import { useGooglePlacesServices } from 'providers/GooglePlacesProvider';
import { useLead } from 'providers/LeadProvider';
import { useLocalization } from 'providers/LocalizationProvider';
import { useSnackbar } from 'providers/SnackbarProvider';
import { useSteps } from 'providers/StepsProvider';
import { useUpdateLead } from 'services/partnersSelfSignup';

import Form from './Form';
import { resolvePartialLead } from './utils';

export interface AddressFormValues {
  googlePlace: google.maps.places.PlaceResult;
  additionalAddressInfo?: string;
}

function Address() {
  const { t } = useLocalization();
  const { lead, selfSignUpId, updateLead } = useLead();
  const { showSnackbar } = useSnackbar();
  const { next, updateLastStepSubmitted, current } = useSteps();
  const { mutateAsync, isLoading } = useUpdateLead();
  const { recordClick, recordErrorView } = useEventTracking();
  const { category, contextData } = useEventData();
  const { place } = useGooglePlacesServices();
  const googlePlace =
    !place && lead?.formattedAddress
      ? {
          formatted_address: lead?.formattedAddress,
          place_id: lead?.googlePlaceId,
          rating: lead?.googlePlaceRating,
        }
      : place;

  const methods = useForm<AddressFormValues>({
    defaultValues: {
      googlePlace,
      additionalAddressInfo: lead?.additionalAddressInfo,
    },
  });

  useRecordFormErrorView<AddressFormValues>(methods);

  const onSubmit = async (values: AddressFormValues) => {
    const trackingValues = {
      category,
      contextData: {
        formValues: values,
        ...contextData,
      },
    };

    recordClick({
      ...trackingValues,
      label: 'continue',
    });

    try {
      const submittedLeadData = resolvePartialLead(values);

      await mutateAsync({
        step: current,
        isLastStep: false,
        selfSignUpId: selfSignUpId as string,
        lastStepSubmitted: updateLastStepSubmitted(),
        lead: {
          ...lead,
          w9: undefined,
          businessName: undefined,
          website: undefined,
          memberships: undefined,
          ...submittedLeadData,
        },
      });

      updateLead({
        ...submittedLeadData,
        businessName: values.googlePlace.name,
        website: values.googlePlace.website,
      });
      next();
    } catch {
      recordErrorView({
        ...trackingValues,
        label: 'something_went_wrong',
      });
      showSnackbar({ message: t('generic_error') });
    }
  };

  return (
    <FormProvider {...methods}>
      <StepTemplate<AddressFormValues>
        formAriaLabel={t('address_page.form.aria_label')}
        title={t('address_page.title')}
        onSubmit={onSubmit}
        loading={isLoading}
      >
        <Form />
      </StepTemplate>
    </FormProvider>
  );
}

export default Address;
