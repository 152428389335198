export default {
  HOST: process.env.HOST,
  PARTNERS_ENVIRONMENT: process.env.PARTNERS_ENVIRONMENT,
  PARTNERS_SELF_SIGNUP_BFF_URL: process.env.PARTNERS_SELF_SIGNUP_BFF_URL,
  ACCOUNT_MANAGER_URL: process.env.ACCOUNT_MANAGER_URL,
  GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,
  TRACKING_URL: process.env.TRACKING_URL,
  TRACKING_ENABLED: process.env.TRACKING_ENABLED,
  DD_RUM_ENABLED: process.env.DD_RUM_ENABLED,
  OPTIMIZELY_PAGE_NAME: process.env.OPTIMIZELY_PAGE_NAME,
  OPTIMIZELY_EXPERIMENT_ID: process.env.OPTIMIZELY_EXPERIMENT_ID,
  OPTIMIZELY_VARIANT_ID: process.env.OPTIMIZELY_VARIANT_ID,
  OPTIMIZELY_EVENT_NAME: process.env.OPTIMIZELY_EVENT_NAME,
  OPTIMIZELY_COUNTRY: process.env.OPTIMIZELY_COUNTRY,
  OPTIMIZELY_BUSINESS_TYPE: process.env.OPTIMIZELY_BUSINESS_TYPE,
} as Record<string, string>;
