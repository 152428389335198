import { theme } from '@gympass/yoga';
import styled from 'styled-components';

const { spacing } = theme;

export const Form = styled.form`
  display: grid;
  gap: ${spacing.xlarge}px;
  padding-bottom: ${spacing.xxlarge}px;
`;
