import Activities from './Activities';
import Address from './Address';
import BusinessInfo from './BusinessInfo';
import BusinessType from './BusinessType';
import DigitalCertificate from './DigitalCertificate';
import Onboarding from './Onboarding';
import Pricing from './Pricing';
import Register from './Register';
import Success from './Success';

export type StepName =
  | 'register'
  | 'onboarding'
  | 'business_type'
  | 'address'
  | 'business_info'
  | 'activities'
  | 'pricing'
  | 'digital_certificate'
  | 'success';

const steps: Record<
  number,
  {
    Component: () => JSX.Element;
    name: StepName;
    monitoringIndex?: number;
  }
> = {
  1: {
    Component: Register,
    name: 'register',
    monitoringIndex: 1,
  },
  2: {
    Component: Onboarding,
    name: 'onboarding',
    monitoringIndex: 1.3,
  },
  3: {
    Component: BusinessType,
    name: 'business_type',
    monitoringIndex: 2,
  },
  4: {
    Component: Address,
    name: 'address',
    monitoringIndex: 3,
  },
  5: {
    Component: BusinessInfo,
    name: 'business_info',
    monitoringIndex: 4,
  },
  6: {
    Component: Activities,
    name: 'activities',
    monitoringIndex: 5,
  },
  7: {
    Component: Pricing,
    name: 'pricing',
    monitoringIndex: 6,
  },
  8: { Component: Success, name: 'success', monitoringIndex: 7 },
};

const stepsWithDigitalCertificateStep: Record<
  number,
  {
    Component: () => JSX.Element;
    name: StepName;
    monitoringIndex?: number;
  }
> = {
  1: {
    Component: Register,
    name: 'register',
    monitoringIndex: 1,
  },
  2: {
    Component: Onboarding,
    name: 'onboarding',
    monitoringIndex: 1.3,
  },
  3: {
    Component: BusinessType,
    name: 'business_type',
    monitoringIndex: 2,
  },
  4: {
    Component: Address,
    name: 'address',
    monitoringIndex: 3,
  },
  5: {
    Component: BusinessInfo,
    name: 'business_info',
    monitoringIndex: 4,
  },
  6: {
    Component: Activities,
    name: 'activities',
    monitoringIndex: 5,
  },
  7: {
    Component: Pricing,
    name: 'pricing',
    monitoringIndex: 6,
  },
  8: {
    Component: DigitalCertificate,
    name: 'digital_certificate',
    monitoringIndex: undefined,
  },
  9: { Component: Success, name: 'success', monitoringIndex: 7 },
};

export { stepsWithDigitalCertificateStep };
export default steps;
