import { useMemo } from 'react';

import { InputEmailProps, InputProps } from 'components/Input';
import { useLocalization } from 'providers/LocalizationProvider';

import {
  CountryDropdown,
  fullNameMask,
  onCountrySelect,
  resolveCountryOptions,
  validateFullName,
} from '../utils/validations';

interface FormFields {
  fullName: InputProps;
  legalName: InputProps;
  businessEmail: InputEmailProps;
  country: CountryDropdown;
}

export function useFields() {
  const {
    t,
    country: initialCountry,
    setCountry,
  } = useLocalization('register_page');

  return useMemo<FormFields>(
    () => ({
      fullName: {
        label: t('input.full_name.label'),
        mask: fullNameMask,
        controllerOptions: {
          name: 'fullName',
          rules: {
            required: t('input.full_name.error_message'),
            validate: (input: string) => validateFullName(input, t),
          },
        },
      },
      legalName: {
        label: t('input.company_legal_name.label'),
        helper: t('input.company_legal_name.helper_text'),
        controllerOptions: {
          name: 'legalName',
          rules: {
            required: t('input.company_legal_name.error_message'),
          },
        },
      },
      businessEmail: {
        label: t('input.business_email.label'),
        helper: t('input.business_email.aria_label'),
        invalidMessage: t('input.business_email.error_invalid'),
        controllerOptions: {
          name: 'businessEmail',
          rules: {
            required: t('input.business_email.error_message'),
          },
        },
      },
      country: {
        label: t('input.business_country.label'),
        options: resolveCountryOptions(initialCountry, t),
        onSelect: onCountrySelect(setCountry),
        controllerOptions: {
          name: 'country',
          rules: {
            required: t('input.business_country.error_message'),
          },
        },
      },
    }),
    [t, initialCountry, setCountry],
  );
}
