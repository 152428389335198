import { v4 as uuidv4 } from 'uuid';

import config from 'config';
import { LocalizationContext } from 'providers/LocalizationProvider';
import { Country } from 'providers/LocalizationProvider/utils';

import { RegisterFormValues } from '../Form';

const oldFormsByCountry = {
  ar: 'https://digital.gympass.com/setup-gyms/?country=AR&_gl=1*1pl30oz*_gcl_au*MTExMTA1MzcxMC4xNjg5MjAxMDEy&_ga=2.27029817.545902128.1689201012-865697656.1689201012',
  cl: 'https://digital.gympass.com/setup-gyms/?country=CL&_gl=1*ntq3ub*_gcl_au*MTExMTA1MzcxMC4xNjg5MjAxMDEy&_ga=2.124418574.545902128.1689201012-865697656.1689201012',
  mx: 'https://digital.gympass.com/setup-gyms/?country=MX&_gl=1*1o0xq8h*_gcl_au*MTExMTA1MzcxMC4xNjg5MjAxMDEy&_ga=2.89800223.545902128.1689201012-865697656.1689201012',
  us: 'https://script.google.com/macros/s/AKfycbw6FUS1AOu2Tx7_FNMbZ9joe2NM5_shvt-SepOCMYJJxqie2d4HCkPTD9mWNPq2CZ8LXQ/exec?type=BM&country=US',
  de: 'https://digital.gympass.com/setup-gyms/?country=DE',
  es: 'https://digital.gympass.com/setup-gyms/?country=ES',
  ie: 'https://digital.gympass.com/setup-gyms/?country=UK',
  it: 'https://digital.gympass.com/setup-gyms/?country=IT',
  gb: 'https://digital.gympass.com/setup-gyms/?country=UK',
};

export function goToOldForm(country: Exclude<Country, 'br'>) {
  return window.open(oldFormsByCountry[country], '_self');
}

export function resolvePartialLead(
  data: RegisterFormValues,
  { country, currency }: LocalizationContext,
) {
  if (!country || !currency) {
    throw new Error();
  }

  const { fullName, legalName, businessEmail } = data;
  const splitedNames = fullName.trim().split(' ');

  return {
    firstName: splitedNames[0],
    lastName: splitedNames[splitedNames.length - 1],
    legalName,
    businessEmail,
    country,
    currency: currency.iso,
  };
}

export function requestEmailValidation(
  values: ReturnType<typeof resolvePartialLead>,
) {
  const userId = uuidv4();
  sessionStorage.setItem(
    'emailValidationState',
    JSON.stringify({ userId, ...values }),
  );

  const source = new URL(window.location.href).searchParams.get('source');

  const params = new URLSearchParams();
  params.append('email', values.businessEmail);
  params.append('source_id', 'partners-self-signup');
  params.append('callback_url', `${config.HOST}?source=${source}`);
  params.append('user_state', userId);

  const url = `${config.ACCOUNT_MANAGER_URL}/email-verification?${params}`;

  window.open(url, '_self');
}
