import { theme } from '@gympass/yoga';
import styled, { css } from 'styled-components';

const { borders, colors, radii, spacing } = theme;

interface CardProps {
  checked: boolean;
}

export const Card = styled.label<CardProps>`
  padding: ${spacing.small}px;
  border-radius: ${radii.small}px;
  cursor: pointer;

  ${({ checked }) => css`
    border: ${borders.small}px solid ${checked ? colors.white : colors.light};
    background-color: ${checked ? colors.yoga : 'transparent'};
  `};

  &:hover {
    border: ${borders.small}px solid ${colors.stamina};
  }

  &:focus {
    border: ${borders.small}px solid ${colors.deepPurple};
  }

  svg {
    margin-bottom: ${spacing.xxsmall}px;
  }
`;

export const Input = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
`;
