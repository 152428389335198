import { theme } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';
import styled from 'styled-components';

const { spacing } = theme;

interface ContainerProps {
  numberOfOptions: number;
}

export const Container = styled.section<ContainerProps>`
  display: grid;
  gap: ${spacing.small}px;

  ${({ numberOfOptions }) => media.md`
    grid-template-columns: repeat(${numberOfOptions}, 1fr);
    gap: ${spacing.large}px;
  `};
`;
