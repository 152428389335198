import { Country } from 'providers/LocalizationProvider/utils';

const personalTrainerLinks: Record<Country, string> = {
  br: 'https://new-ventures.bygympass.com/trainiac_br/',
  ar: 'https://new-ventures.bygympass.com/trainiac_es/?_gl=1*1mrjh5f*_gcl_aw*R0NMLjE2Nzk0MzY0NzUuQ2p3S0NBandxLVdnQmhCTUVpd0F6S1NINkhpU0dkWjJ5WkVabGNpN3hyZ1lZajdIZ0k5ZjFnU3BseDFYVlJXTmwyc1FqTjVwWlIzNWhob0NhUE1RQXZEX0J3RQ',
  cl: 'https://new-ventures.bygympass.com/trainiac_es/?_gl=1*1o4a4gn*_gcl_aw*R0NMLjE2Nzk0MzY0NzUuQ2p3S0NBandxLVdnQmhCTUVpd0F6S1NINkhpU0dkWjJ5WkVabGNpN3hyZ1lZajdIZ0k5ZjFnU3BseDFYVlJXTmwyc1FqTjVwWlIzNWhob0NhUE1RQXZEX0J3RQ',
  de: 'https://new-ventures.bygympass.com/trainiac_de/',
  es: 'https://new-ventures.bygympass.com/trainiac_es/',
  ie: 'https://new-ventures.bygympass.com/trainiac_uk/',
  it: 'https://new-ventures.bygympass.com/trainiac_it/',
  mx: 'https://new-ventures.bygympass.com/trainiac_es/?_gl=1*t7r3fr*_gcl_aw*R0NMLjE2Nzk0MzY0NzUuQ2p3S0NBandxLVdnQmhCTUVpd0F6S1NINkhpU0dkWjJ5WkVabGNpN3hyZ1lZajdIZ0k5ZjFnU3BseDFYVlJXTmwyc1FqTjVwWlIzNWhob0NhUE1RQXZEX0J3RQ',
  gb: 'https://new-ventures.bygympass.com/trainiac_uk/',
  us: 'https://new-ventures.bygympass.com/trainiac_us/',
};

export const goToTraniacPage = (country: Country) =>
  window.open(personalTrainerLinks[country], '_self');
