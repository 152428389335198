import { useEffect } from 'react';

import GympassLogo from 'components/GympassLogo';
import useEventData from 'pages/Signup/hooks/useEventData';
import useOptimizely from 'pages/Signup/hooks/useOptimizely';
import { useEventTracking } from 'providers/EventTrackingProvider';
import { useLocalization } from 'providers/LocalizationProvider';
import { useSteps } from 'providers/StepsProvider';
import { goToGympassWebsite } from 'utils/redirects';

import * as S from './Success.styles';

function Success() {
  const { t, language } = useLocalization();
  const { recordClick } = useEventTracking();
  const { category, contextData } = useEventData();
  const { sendOptimizelyEvent } = useOptimizely();
  const { isRebrandingEnabled } = useSteps();

  const onClick = () => {
    recordClick({
      category,
      label: 'explore_gympass',
      contextData,
    });
    goToGympassWebsite(language);
  };

  useEffect(() => {
    sendOptimizelyEvent('event');
  }, [sendOptimizelyEvent]);

  return (
    <>
      <GympassLogo
        label={t('gympass_link.aria_label')}
        onClick={() => goToGympassWebsite(language)}
      />

      <S.Container>
        <S.Content>
          {isRebrandingEnabled ? (
            <S.HiFiveImage viewBox="50 50 200 250" />
          ) : (
            <S.Image viewBox="50 50 300 350" />
          )}
        </S.Content>

        <S.Content>
          <S.Title>{t('success_page.title')}</S.Title>
          <S.Subtitle>{t('success_page.subtitle')}</S.Subtitle>

          <S.ExploreButton onClick={onClick}>
            {t('footer.explore_gympass_button')}
          </S.ExploreButton>
        </S.Content>
      </S.Container>
    </>
  );
}

export default Success;
