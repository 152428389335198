import config from 'config';
import { useFlags } from 'providers/FlagsProvider';

interface Optimizely {
  get?: (type: 'state') => {
    getExperimentStates: () => {
      [key: string]: { variation?: { name: string; id: string } };
    };
  };
  push: (args: {
    type: string;
    eventName?: string;
    pageName?: string;
    attributes?: Record<string, unknown>;
  }) => void;
}

declare global {
  interface Window {
    optimizely?: Optimizely;
  }
}

const idExperiment = config.OPTIMIZELY_EXPERIMENT_ID;
const idVariant = config.OPTIMIZELY_VARIANT_ID;

const sendOptimizelyEvent = (type: 'page' | 'event') => {
  window?.optimizely?.push({
    type,
    ...(type === 'page'
      ? { pageName: config.OPTIMIZELY_PAGE_NAME }
      : { eventName: config.OPTIMIZELY_EVENT_NAME }),
  });
};

const useOptimizely = () => {
  const isEnabled = useFlags();

  if (isEnabled.digital_certificate) {
    const experimentStates = window?.optimizely
      ?.get?.('state')
      .getExperimentStates();

    if (!experimentStates || Object.keys(experimentStates).length === 0) {
      return {
        isOnExperiment: false,
        sendOptimizelyEvent,
      };
    }

    const experimentValues = experimentStates[idExperiment];

    return {
      isOnExperiment: experimentValues?.variation?.id === idVariant,
      sendOptimizelyEvent,
    };
  }

  return {
    isOnExperiment: false,
    sendOptimizelyEvent,
  };
};

export default useOptimizely;
