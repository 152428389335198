import { useQuery } from '@tanstack/react-query';
import { PropsWithChildren, createContext, useContext } from 'react';

import Loading from 'components/Loading';
import {
  GetFeaturesResponse,
  GetFeaturesVariables,
  getFeatures,
} from 'services/partnersSelfSignup';

import { defaultValues, features } from './utils';

export interface FlagsContext {
  [key: string]: boolean;
}

export const FlagsContext = createContext<FlagsContext>({});

export function FlagsProvider({ children }: PropsWithChildren) {
  const { data, isLoading } = useQuery({
    queryKey: ['features'],
    queryFn: () => getFeatures(features),
    retry: false,
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <FlagsContext.Provider value={data ?? defaultValues}>
      {children}
    </FlagsContext.Provider>
  );
}

export function useFlags() {
  const values = useContext(FlagsContext);
  return Object.entries(values).reduce<GetFeaturesResponse>(
    (acc, [name, value]) => ({
      ...acc,
      [name.replace('partners.self_signup.', '')]: value,
    }),
    {},
  );
}

export function useFlag(feature: GetFeaturesVariables[number]) {
  const { data, isLoading } = useQuery({
    queryKey: [feature],
    queryFn: () => getFeatures([feature]),
    retry: false,
    refetchOnWindowFocus: false,
  });

  return {
    isLoading,
    isEnabled: data ? data[feature.name] : feature.fallbackValue,
  };
}
