import { theme } from '@gympass/yoga';
import styled from 'styled-components';

const { colors, elevations, radii, borders, spacing } = theme;

type ContainerProps = {
  aspectRatio?: number;
};

export const Container = styled.div<ContainerProps>`
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: ${({ aspectRatio }) => aspectRatio ?? 16 / 9};
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: ${radii.regular}px;
  border: ${borders.small}px solid ${colors.light};
  overflow: hidden;
`;

export const Actions = styled.div`
  position: absolute;
  top: ${spacing.xxsmall}px;
  right: ${spacing.xxsmall}px;
`;

export const Button = styled.button`
  width: ${spacing.xlarge}px;
  height: ${spacing.xlarge}px;
  background-color: ${colors.white};
  border-radius: ${radii.regular}px;
  padding: ${spacing.xxxsmall}px;
  box-shadow: ${elevations.small};
  border: none;
  outline: none;
  cursor: pointer;
`;
