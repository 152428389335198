export type Language =
  | 'de'
  | 'it'
  | 'es'
  | 'es-AR'
  | 'es-CL'
  | 'es-MX'
  | 'en'
  | 'en-GB'
  | 'en-US'
  | 'en-IE'
  | 'pt-BR'
  | 'pt';

export type Country =
  | 'ar'
  | 'br'
  | 'cl'
  | 'de'
  | 'es'
  | 'ie'
  | 'it'
  | 'mx'
  | 'gb'
  | 'us';

export type Currency = {
  iso: 'ARS' | 'BRL' | 'CLP' | 'EUR' | 'MXN' | 'GBP' | 'USD';
  symbol: '$' | 'R$' | '€' | '£';
};

export const gympassCountries: Country[] = [
  'ar',
  'br',
  'cl',
  'de',
  'es',
  'ie',
  'it',
  'mx',
  'gb',
  'us',
];

export const languageByCountry: Record<Country, Language> = {
  ar: 'es-AR',
  br: 'pt-BR',
  cl: 'es-CL',
  de: 'de',
  es: 'es',
  ie: 'en-IE',
  it: 'it',
  mx: 'es-MX',
  gb: 'en-GB',
  us: 'en-US',
};

const gympassLanguages: Language[] = [
  'de',
  'it',
  'es',
  'es-AR',
  'es-CL',
  'es-MX',
  'en',
  'en-GB',
  'en-US',
  'en-IE',
  'pt-BR',
  'pt',
];

const gympassCountriesTimeZones: Record<string, Country> = {
  'America/Argentina/Buenos_Aires': 'ar',
  'America/Argentina/Cordoba': 'ar',
  'America/Argentina/Salta': 'ar',
  'America/Argentina/Jujuy': 'ar',
  'America/Argentina/Tucuman': 'ar',
  'America/Argentina/Catamarca': 'ar',
  'America/Argentina/La_Rioja': 'ar',
  'America/Argentina/San_Juan': 'ar',
  'America/Argentina/Mendoza': 'ar',
  'America/Argentina/San_Luis': 'ar',
  'America/Argentina/Rio_Gallegos': 'ar',
  'America/Argentina/Ushuaia': 'ar',
  'America/Noronha': 'br',
  'America/Belem': 'br',
  'America/Fortaleza': 'br',
  'America/Recife': 'br',
  'America/Araguaina': 'br',
  'America/Maceio': 'br',
  'America/Bahia': 'br',
  'America/Sao_Paulo': 'br',
  'America/Campo_Grande': 'br',
  'America/Cuiaba': 'br',
  'America/Santarem': 'br',
  'America/Porto_Velho': 'br',
  'America/Boa_Vista': 'br',
  'America/Manaus': 'br',
  'America/Eirunepe': 'br',
  'America/Rio_Branco': 'br',
  'America/Santiago': 'cl',
  'America/Punta_Arenas': 'cl',
  'Pacific/Easter': 'cl',
  'Europe/Berlin': 'de',
  'Europe/Madrid': 'es',
  'Africa/Ceuta': 'es',
  'Atlantic/Canary': 'es',
  'Europe/Dublin': 'ie',
  'Europe/Rome': 'it',
  'America/Cancun': 'mx',
  'America/Merida': 'mx',
  'America/Monterrey': 'mx',
  'America/Matamoros': 'mx',
  'America/Chihuahua': 'mx',
  'America/Ciudad_Juarez': 'mx',
  'America/Ojinaga': 'mx',
  'America/Mazatlan': 'mx',
  'America/Bahia_Banderas': 'mx',
  'America/Hermosillo': 'mx',
  'America/Tijuana': 'mx',
  'Europe/London': 'gb',
  'America/New_York': 'us',
  'America/Detroit': 'us',
  'America/Kentucky/Louisville': 'us',
  'America/Kentucky/Monticello': 'us',
  'America/Indiana/Indianapolis': 'us',
  'America/Indiana/Vincennes': 'us',
  'America/Indiana/Winamac': 'us',
  'America/Indiana/Marengo': 'us',
  'America/Indiana/Petersburg': 'us',
  'America/Indiana/Vevay': 'us',
  'America/Chicago': 'us',
  'America/Indiana/Tell_City': 'us',
  'America/Indiana/Knox': 'us',
  'America/Menominee': 'us',
  'America/North_Dakota/Center': 'us',
  'America/North_Dakota/New_Salem': 'us',
  'America/North_Dakota/Beulah': 'us',
  'America/Denver': 'us',
  'America/Boise': 'us',
  'America/Phoenix': 'us',
  'America/Los_Angeles': 'us',
  'America/Anchorage': 'us',
  'America/Juneau': 'us',
  'America/Sitka': 'us',
  'America/Metlakatla': 'us',
  'America/Yakutat': 'us',
  'America/Nome': 'us',
  'America/Adak': 'us',
  'Pacific/Honolulu': 'us',
};

const currencyByCountry: Record<Country, Currency> = {
  ar: {
    iso: 'ARS',
    symbol: '$',
  },
  br: {
    iso: 'BRL',
    symbol: 'R$',
  },
  cl: {
    iso: 'CLP',
    symbol: '$',
  },
  de: {
    iso: 'EUR',
    symbol: '€',
  },
  es: {
    iso: 'EUR',
    symbol: '€',
  },
  ie: {
    iso: 'EUR',
    symbol: '€',
  },
  it: {
    iso: 'EUR',
    symbol: '€',
  },
  mx: {
    iso: 'MXN',
    symbol: '$',
  },
  gb: {
    iso: 'GBP',
    symbol: '£',
  },
  us: {
    iso: 'USD',
    symbol: '$',
  },
};

export const defaultLanguage: Language = 'en';

function resolveLanguage() {
  const [preferedLanguage] = gympassLanguages.reduce<Language[]>(
    (matches, gympassLanguage) => {
      if (navigator.language === gympassLanguage) {
        matches.unshift(gympassLanguage);
      } else if (navigator.language.match(gympassLanguage)) {
        matches.push(gympassLanguage);
      }

      return matches;
    },
    [],
  );

  return preferedLanguage ?? defaultLanguage;
}

function resolveCountry(): Country | undefined {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const country = gympassCountriesTimeZones[timeZone];

  return country;
}

export function resolveCurrency(country?: Country) {
  if (!country) {
    return undefined;
  }

  return currencyByCountry[country];
}

export function resolveInitialLocalization() {
  const country = resolveCountry();

  return {
    language: resolveLanguage(),
    country,
    currency: resolveCurrency(country),
  };
}
