import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import Input from 'components/Input';
import { useLead } from 'providers/LeadProvider';

import * as S from './Form.styles';
import TaxInfo from './TaxInfo';
import { useFields } from './hooks';

function Form() {
  const fields = useFields();
  const { trigger } = useFormContext();
  const { lead } = useLead();

  useEffect(() => {
    if (lead?.businessName) {
      trigger('businessName');
    }
  }, [lead, trigger]);

  return (
    <>
      <S.Container>
        <Input {...fields.businessName} />
        <Input {...fields.legalName} />
        <Input.Website {...fields.website} />
        <Input.Phone {...fields.phone} />
      </S.Container>
      <S.FullDivider />
      <S.Container>
        <TaxInfo />
      </S.Container>
    </>
  );
}

export default Form;
