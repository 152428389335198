import { PropsWithChildren, useMemo } from 'react';

import GympassLogo from 'components/GympassLogo';
import useEventData from 'pages/Signup/hooks/useEventData';
import { useEventTracking } from 'providers/EventTrackingProvider';
import { useSteps } from 'providers/StepsProvider';

import Footer from './Footer';
import Header from './Header';
import * as S from './StepTemplate.style';

interface StepTemplateWithoutFormProps {
  title: string;
  disableOnBack?: boolean;
  className?: string;
}

function StepTemplateWithoutForm({
  children,
  title,
  disableOnBack = false,
  className,
}: PropsWithChildren<StepTemplateWithoutFormProps>) {
  const { prev, next } = useSteps();

  const { recordClick } = useEventTracking();
  const { category, contextData } = useEventData();

  const actions = useMemo(() => {
    const onForward = () => {
      recordClick({
        category,
        label: 'continue',
        contextData: {
          noChanges: true,
          ...contextData,
        },
      });
      next();
    };

    const onBack = () => {
      recordClick({ category, label: 'on_back', contextData });
      prev();
    };

    return {
      onForward,
      onBack: disableOnBack ? undefined : onBack,
    };
  }, [disableOnBack, recordClick, category, contextData, next, prev]);

  return (
    <S.Container>
      <S.SectionTitle>
        <GympassLogo width={80} height={15} />
        <S.Title>{title}</S.Title>
      </S.SectionTitle>
      <div>
        <Header />
        <S.FormFields className={className}>{children}</S.FormFields>
        <Footer
          onBack={actions.onBack}
          onClick={actions.onForward}
          buttonType="button"
        />
      </div>
    </S.Container>
  );
}

export default StepTemplateWithoutForm;
