import { theme } from '@gympass/yoga';
import '@yoga-inner/fonts';
import { createGlobalStyle } from 'styled-components';

import reset from './reset';

export default createGlobalStyle`
  ${reset};

  html {
    box-sizing: border-box;
  }

  *:not(h1) {
    font-family: ${theme.baseFont.family} !important;
  }
   
  *, *:before, *:after {
    box-sizing: inherit;
  }

  html, body, #root {
    min-height: 100vh;
    min-height: 100svh;
  }
`;
