import { Text } from '@gympass/yoga';

import { useLocalization } from 'providers/LocalizationProvider';

import Footer from './Footer';
import Form from './Form';
import Header from './Header';
import NeedHelp from './NeedHelp';
import * as S from './Register.styles';

function Register() {
  const { t } = useLocalization('register_page');

  return (
    <>
      <Header />
      <S.Main>
        <S.Headings>
          <S.Title>{t('title')}</S.Title>
          <Text.H5>{t('subtitle')}</Text.H5>
        </S.Headings>
        <S.Content>
          <Form />
          <NeedHelp />
        </S.Content>
      </S.Main>
      <Footer />
    </>
  );
}

export default Register;
