import { Text, theme } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';
import styled from 'styled-components';

import { footerHeight } from './Footer/Footer.styles';
import {
  headerDesktopHeight,
  headerMobileHeight,
} from './Header/Header.styles';

const { spacing } = theme;

export const Main = styled.main`
  min-height: calc(100vh - ${headerMobileHeight + footerHeight}px);
  padding: ${spacing.medium}px ${spacing.medium}px 100px;

  ${media.md`
    min-height: calc(100vh - ${headerDesktopHeight + footerHeight}px);
    display: grid;
    grid-template: auto / 3fr 2fr;
    align-items: start;
    gap: ${spacing.xhuge}px;
    padding: ${spacing.xxlarge}px	${spacing.xxxlarge}px;
  `};
`;

export const Headings = styled.div`
  ${media.md`
    margin-top: ${spacing.xxlarge}px;
    padding: ${spacing.huge}px 0 110px;
  `};
`;

export const Title = styled(Text.Display1).attrs({
  as: 'h1',
})`
  margin-bottom: ${spacing.small}px;
  font-size: 2.5rem;
  line-height: 110%;

  ${media.md`
    font-size: 4.5rem;
    line-height: 110%;
  `};
`;

export const Content = styled.section`
  margin-top: ${spacing.xlarge}px;

  ${media.md`
    display: grid;
    width: 100%;
    max-width: 820px;
    margin: ${spacing.xlarge}px auto 0;
  `};
`;
