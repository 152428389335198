import { useMemo } from 'react';

import { InputFileProps } from 'components/Input';
import { useLocalization } from 'providers/LocalizationProvider';

export interface FormFields {
  file: InputFileProps;
}

export function useFields() {
  const { t: translate } = useLocalization();
  const { t } = useLocalization('pricing_page.section_2.input');

  return useMemo<FormFields>(
    () => ({
      file: {
        labels: {
          label: t('upload.label'),
          helper: t('upload.helper_text'),
          update: translate('input.file.update'),
          remove: translate('input.file.delete'),
          invalidMessage: t('upload.error_message'),
        },
        accept: {
          'image/jpeg': ['.jpg', '.jpeg'],
          'image/png': ['.png'],
          'application/pdf': ['.pdf'],
        },
        controllerOptions: {
          shouldUnregister: true,
          name: 'file',
          rules: {
            required: t('upload.error_required'),
          },
        },
      },
    }),
    [translate, t],
  );
}
