export interface Base64File {
  id?: string;
  name: string;
  encoded: string;
}

export function base64ToFile(base64File?: Base64File): File | undefined {
  if (!base64File) return undefined;

  const [metadata, encoded] = base64File.encoded.split(',');
  const type = metadata.replace(/$[data:]/, '');

  return new File([encoded], base64File.name, {
    type,
  });
}

export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = reject;
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject();
      }

      reader.abort();
    };
  });
}

export function fileToArrayBuffer(file: File) {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result as ArrayBuffer);
    };
  });
}

export async function generateBase64File(
  file?: File,
): Promise<Base64File | undefined> {
  if (!file) return undefined;

  const encoded = await fileToBase64(file);
  if (!encoded) return undefined;

  return {
    name: file.name,
    encoded,
  };
}
