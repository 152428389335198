import { useEffect, useLayoutEffect } from 'react';

import WellhubFavicon from 'assets/favicon.ico';
import Loading from 'components/Loading';
import RecoveryLeadDialog from 'components/RecoveryLeadDialog/';
import withGoogleMapsAPI from 'hocs/withGoogleMapsAPI';
import { useFlag } from 'providers/FlagsProvider';
import GooglePlacesProvider from 'providers/GooglePlacesProvider';
import { LeadProvider } from 'providers/LeadProvider';
import { useLocalization } from 'providers/LocalizationProvider';
import { StepsProvider } from 'providers/StepsProvider';

import Step, { steps } from './Step';
import useFlowInitialization from './hooks/useFlowInitialization';

function Signup() {
  const { t } = useLocalization();
  const { currentStep, selfSignUpId, leadRecord, loading, isLeadRecovered } =
    useFlowInitialization();

  const totalSteps = Object.keys(steps).length;

  const urlParam = new URLSearchParams(window.location.search);

  const { isEnabled, isLoading: isFlagLoading } = useFlag({
    name: 'partners.self_signup.rebranding',
    context: { userId: urlParam.get('rebranding') },
    fallbackValue: false,
  });

  useEffect(() => {
    document.title = t('html.title');
  }, [t]);

  useLayoutEffect(() => {
    const faviconUpdate = async () => {
      const favicon =
        document.querySelector<HTMLLinkElement>('link[rel="icon"]');

      if (isEnabled && favicon) {
        favicon.href = WellhubFavicon;
      }
    };

    faviconUpdate();
  }, [isEnabled]);

  return (
    <GooglePlacesProvider>
      {loading || isFlagLoading ? (
        <Loading />
      ) : (
        <LeadProvider selfSignUpId={selfSignUpId} leadRecord={leadRecord}>
          <StepsProvider
            initialTotalSteps={totalSteps}
            initialStep={currentStep}
            isRebrandingEnabled={isEnabled}
          >
            <Step isLeadRecovered={isLeadRecovered} />
            <RecoveryLeadDialog isOpen={isLeadRecovered} />
          </StepsProvider>
        </LeadProvider>
      )}
    </GooglePlacesProvider>
  );
}

export default withGoogleMapsAPI(Signup);
