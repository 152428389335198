import { useMemo } from 'react';

import { steps, stepsWithDigitalCertificateStep } from 'pages/Signup/Step';
import { useLead } from 'providers/LeadProvider';
import { useSteps } from 'providers/StepsProvider';

import useOptimizely from '../useOptimizely';

function useEventData() {
  const { current } = useSteps();
  const { stepName, selfSignUpId, lead } = useLead();

  const { isOnExperiment } = useOptimizely();
  const currSteps = isOnExperiment ? stepsWithDigitalCertificateStep : steps;

  return useMemo(
    () => ({
      category: stepName ?? 'empty',
      contextData: {
        stepOrder: currSteps[current].monitoringIndex,
        selfSignUpId: selfSignUpId ?? 'empty',
        leadId: lead?.id ?? 'empty',
        businessEmail: lead?.businessEmail ?? 'empty',
      },
    }),
    [stepName, currSteps, current, selfSignUpId, lead?.id, lead?.businessEmail],
  );
}

export default useEventData;
