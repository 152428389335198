import { Input as YogaInput } from '@gympass/yoga';
import { ChangeEvent } from 'react';
import { useController } from 'react-hook-form';

import { InputProps } from '..';

export interface InputNumberProps extends InputProps {
  mask?: (input: string) => string;
}

function InputNumber({
  controllerOptions,
  label,
  helper,
  full = true,
  cleanable = false,
  onClean,
  mask,
}: InputNumberProps) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController(controllerOptions);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    onChange(mask?.(target.value) ?? target.value);
  };

  return (
    <YogaInput.Number
      value={value}
      onChange={handleChange}
      label={label}
      error={error?.message}
      helper={helper}
      full={full}
      cleanable={cleanable}
      onClean={onClean}
      inputMode="numeric"
    />
  );
}

export default InputNumber;
