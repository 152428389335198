import { Input as YogaInput } from '@gympass/yoga';
import { isPossibleNumber } from 'libphonenumber-js';
import { useController } from 'react-hook-form';

import { useLocalization } from 'providers/LocalizationProvider';

import { InputProps } from '..';

export interface InputPhoneProps extends InputProps {
  invalidMessage: string;
}

function InputPhone({
  controllerOptions,
  invalidMessage,
  label,
  helper,
  full = true,
  cleanable = false,
  onClean,
}: InputPhoneProps) {
  const { country } = useLocalization();
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    defaultValue: '',
    ...controllerOptions,
    rules: {
      ...controllerOptions.rules,
      validate: (input?: string) => {
        return Boolean(input && isPossibleNumber(input)) || invalidMessage;
      },
    },
  });

  const handleChange = (newValue: string) => {
    onChange(newValue.length > 0 ? `+${newValue}` : '');
  };

  return (
    <YogaInput.Phone
      defaultCountry={country}
      value={value}
      onChange={handleChange}
      label={label}
      error={error?.message}
      helper={helper}
      full={full}
      cleanable={cleanable}
      onClean={onClean}
      inputMode="tel"
    />
  );
}

export default InputPhone;
