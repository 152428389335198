import tokens from '@gympass/yoga-tokens';
import { useCallback, useEffect, useState } from 'react';

const { breakpoints } = tokens;

const useDeviceViewport = () => {
  const getSize = useCallback(() => {
    const width = window.innerWidth;

    const TABLET_BREAKPOINT = breakpoints.md.width;

    const DESKTOP_BREAKPOINT = breakpoints.lg.width;

    return {
      width,
      height: window.innerHeight,
      isMobile: width < TABLET_BREAKPOINT,
      isTablet: width >= TABLET_BREAKPOINT && width < DESKTOP_BREAKPOINT,
      isDesktop: width >= DESKTOP_BREAKPOINT,
    };
  }, []);

  const getPosition = useCallback(
    () => ({
      scrollX: window.scrollX,
      scrollY: window.scrollY,
    }),
    [],
  );

  const [windowSize, setWindowSize] = useState(getSize);
  const [windowPosition, setWindowPosition] = useState(getPosition);

  useEffect(() => {
    const resizeHandler = () => setWindowSize(getSize());
    const scrollHandler = () => setWindowPosition(getPosition());

    window.addEventListener('resize', resizeHandler);
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [getSize, getPosition]);

  useEffect(() => window.scrollTo(0, 0), []);

  return {
    ...windowPosition,
    ...windowSize,
  };
};
export default useDeviceViewport;
