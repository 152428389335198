import { Text } from '@gympass/yoga';
import { CheckedHalf, Doc, Map, Smartphone } from '@gympass/yoga-icons';
import { useState } from 'react';

import GoogleDialog from 'components/GoogleDialog';
import { StepTemplateWithoutForm } from 'components/StepTemplate';
import useEventData from 'pages/Signup/hooks/useEventData';
import { useEventTracking } from 'providers/EventTrackingProvider';
import { useLocalization } from 'providers/LocalizationProvider';
import { goToGoogleBusinessRegister } from 'utils/redirects';

import * as S from './Onboarding.styles';

function Onboarding() {
  const [isGoogleDialogOpen, setIsGoogleDialogOpen] = useState(false);

  const { t } = useLocalization('next_steps_page');

  const toogleGoogleDialog = () => setIsGoogleDialogOpen(!isGoogleDialogOpen);

  const { recordClick } = useEventTracking();
  const { category, contextData } = useEventData();

  const onClick = () => {
    recordClick({
      category,
      label: 'add_google_profile',
      contextData: {
        ...contextData,
      },
    });

    goToGoogleBusinessRegister();
  };

  return (
    <>
      <StepTemplateWithoutForm title={t('title')} disableOnBack>
        <S.Container>
          <S.TextContainer>
            <S.TitleTag icon={Map}>{`1. ${t('step_1.title')}`}</S.TitleTag>
            <Text>{t('step_1.subtitle')}</Text>
            <S.BannerInfo
              variant="informative"
              message={t('step_1.snackbar.description')}
              primaryButton={{
                label: t('step_1.snackbar.button'),
                action: toogleGoogleDialog,
              }}
            />
          </S.TextContainer>
          <S.TextContainer>
            <S.TitleTag icon={CheckedHalf}>
              {`2. ${t('step_2.title')}`}
            </S.TitleTag>
            <Text>{t('step_2.subtitle')}</Text>
          </S.TextContainer>
          <S.TextContainer>
            <S.TitleTag icon={Doc}>{`3. ${t('step_3.title')}`}</S.TitleTag>
            <Text>{t('step_3.subtitle')}</Text>
          </S.TextContainer>
          <S.TextContainer>
            <S.TitleTag icon={Smartphone}>{`4. ${t(
              'step_4.title',
            )}`}</S.TitleTag>
            <Text>{t('step_4.subtitle')}</Text>
          </S.TextContainer>
        </S.Container>
      </StepTemplateWithoutForm>
      <GoogleDialog
        isOpen={isGoogleDialogOpen}
        onClose={toogleGoogleDialog}
        onAccept={onClick}
      />
    </>
  );
}

export default Onboarding;
