/* eslint-disable react/jsx-props-no-spreading */
import { Input as YogaInput } from '@gympass/yoga';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { HTMLAttributes } from 'react';
import { useController } from 'react-hook-form';

import withYogaMUITheme from 'hocs/withYogaMUITheme';

import { InputProps } from '..';

export interface InputSearchProps<
  OptionsData,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
> extends InputProps {
  inputMode?: HTMLAttributes<HTMLElement>['inputMode'];
  groupBy?: AutocompleteProps<
    OptionsData,
    Multiple,
    DisableClearable,
    false
  >['groupBy'];
  getOptionLabel: AutocompleteProps<
    OptionsData,
    Multiple,
    DisableClearable,
    false
  >['getOptionLabel'];
  multiple: Multiple;
  options: AutocompleteProps<
    OptionsData,
    Multiple,
    DisableClearable,
    false
  >['options'];
  noOptionsText: string;
  filterSelectedOptions?: AutocompleteProps<
    OptionsData,
    Multiple,
    DisableClearable,
    false
  >['filterSelectedOptions'];
  isOptionEqualToValue?: AutocompleteProps<
    OptionsData,
    Multiple,
    DisableClearable,
    false
  >['isOptionEqualToValue'];
}

function InputSearch<
  OptionsData,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
>({
  label,
  helper,
  full = true,
  inputMode = 'text',
  cleanable = false,
  onClean,
  groupBy,
  getOptionLabel,
  multiple,
  options,
  noOptionsText,
  filterSelectedOptions,
  controllerOptions,
  isOptionEqualToValue,
}: InputSearchProps<OptionsData, Multiple, DisableClearable>) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController(controllerOptions);

  return (
    <Autocomplete
      multiple={multiple}
      options={options}
      getOptionLabel={getOptionLabel}
      onChange={(_event, selectedValue, reason) => {
        if (reason === 'selectOption') {
          onChange(selectedValue);
        }
      }}
      groupBy={groupBy}
      noOptionsText={noOptionsText}
      isOptionEqualToValue={isOptionEqualToValue}
      filterSelectedOptions={filterSelectedOptions}
      value={value}
      renderInput={params => {
        return (
          <div ref={params.InputProps.ref}>
            <YogaInput
              {...params.inputProps}
              error={error?.message}
              label={label}
              helper={helper}
              full={full}
              inputMode={inputMode}
              cleanable={cleanable}
              onClean={onClean}
            />
          </div>
        );
      }}
    />
  );
}

export default withYogaMUITheme(InputSearch);
