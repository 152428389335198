import de from 'translations/de.json';
import enGB from 'translations/en-GB.json';
import enIE from 'translations/en-IE.json';
import enUS from 'translations/en-US.json';
import en from 'translations/en.json';
import esAR from 'translations/es-AR.json';
import esCL from 'translations/es-CL.json';
import esMX from 'translations/es-MX.json';
import es from 'translations/es.json';
import it from 'translations/it.json';
import activities from 'translations/partner_activities.json';
import ptBR from 'translations/pt-BR.json';
import pt from 'translations/pt.json';

export default {
  de: Object.assign(de, activities.de),
  'en-GB': Object.assign(enGB, activities['en-GB']),
  'en-IE': Object.assign(enIE, activities['en-IE']),
  'en-US': Object.assign(enUS, activities['en-US']),
  en: Object.assign(en, activities.en),
  'es-AR': Object.assign(esAR, activities['es-AR']),
  'es-CL': Object.assign(esCL, activities['es-CL']),
  'es-MX': Object.assign(esMX, activities['es-MX']),
  es: Object.assign(es, activities.es),
  it: Object.assign(it, activities.it),
  'pt-BR': Object.assign(ptBR, activities['pt-BR']),
  pt: Object.assign(pt, activities.pt),
};
