import { useMemo } from 'react';

import { InputFileProps, InputTaxIDProps } from 'components/Input';
import { useLead } from 'providers/LeadProvider';
import { useLocalization } from 'providers/LocalizationProvider';
import { Base64File } from 'utils/file';

interface FormFields {
  taxId: InputTaxIDProps;
  w9: InputFileProps;
}

export function useFields() {
  const { t: translate } = useLocalization();
  const { t, country } = useLocalization('business_details_page');
  const { lead } = useLead();

  return useMemo<FormFields>(
    () => ({
      taxId: {
        label: t(`input.tax_id.${country}.label`),
        helper: country !== 'us' ? t('tax_id.helper_text') : undefined,
        invalidMessage: t(`input.tax_id.${country}.error_invalid`),
        controllerOptions: {
          name: 'taxId',
          rules: {
            required: t(`input.tax_id.${country}.error_required`),
          },
        },
      },
      w9: {
        labels: {
          label: t('tax_id.us.w9.label'),
          helper: t('tax_id.us.w9.helper_text'),
          invalidMessage: t('tax_id.us.w9.error_invalid'),
          update: translate('input.file.update'),
          remove: translate('input.file.delete'),
        },
        accept: { 'application/pdf': ['.pdf'] },
        controllerOptions: {
          name: 'w9',
          rules: {
            validate: (val?: Base64File) => {
              if (val || lead?.w9) {
                return true;
              }
              return false || t('tax_id.us.w9.error_required');
            },
          },
        },
      },
    }),
    [translate, t, country, lead],
  );
}
