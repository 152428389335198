import { deDE, enUS, esES, itIT, ptBR } from '@mui/material/locale';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { PropsWithChildren } from 'react';
import { useTheme } from 'styled-components';

import {
  LocalizationContext,
  useLocalization,
} from 'providers/LocalizationProvider';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type YogaTheme = Record<string, any>;

const createYogaMUITheme = (
  yogaTheme: YogaTheme,
  language: NonNullable<LocalizationContext['language']>,
) =>
  createTheme(
    {
      palette: {
        primary: {
          main: yogaTheme.colors.primary,
        },
        secondary: {
          main: yogaTheme.colors.secondary,
        },
        background: {
          default: yogaTheme.colors.elements.backgroundAndDisabled,
        },
        text: {
          primary: yogaTheme.colors.text.primary,
          secondary: yogaTheme.colors.text.secondary,
        },
      },
      typography: {
        fontFamily: yogaTheme.baseFont.family,
        fontWeightLight: yogaTheme.fontWeights.light,
        fontWeightRegular: yogaTheme.fontWeights.regular,
        fontWeightMedium: yogaTheme.fontWeights.medium,
        fontWeightBold: yogaTheme.fontWeights.bold,
      },
    },
    {
      de: deDE,
      it: itIT,
      es: esES,
      'es-AR': esES,
      'es-CL': esES,
      'es-MX': esES,
      en: enUS,
      'en-GB': enUS,
      'en-US': enUS,
      'en-IE': enUS,
      'pt-BR': ptBR,
      pt: ptBR,
    }[language],
  );

function withYogaMUITheme<ComponentProps>(
  MUIComponent: (props: PropsWithChildren<ComponentProps>) => JSX.Element,
) {
  function ThemeWrapper(props: PropsWithChildren<ComponentProps>) {
    const { yoga } = useTheme() as { yoga: YogaTheme };
    const { language } = useLocalization();

    return (
      <ThemeProvider theme={createYogaMUITheme(yoga, language)}>
        <MUIComponent {...props} />
      </ThemeProvider>
    );
  }

  return ThemeWrapper;
}

export default withYogaMUITheme;
