import { datadogRum } from '@datadog/browser-rum';

import config from 'config';

export function initRUM(): typeof datadogRum | undefined {
  if (!JSON.parse(config.DD_RUM_ENABLED || 'false')) {
    return undefined;
  }

  datadogRum.init({
    applicationId: 'c12fcb6a-28df-45f9-baef-5c5ce4e93e15',
    clientToken: 'puba2254a1ac7e85577a452dbc373c1332a',
    site: 'datadoghq.com',
    service: 'partners-self-signup-spa',
    env: config.PARTNERS_ENVIRONMENT,
    version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    allowedTracingUrls: [
      /https:\/\/(?!snowplow-collector).*\.gympass\.(com|cloud)/,
    ],
  });

  datadogRum.startSessionReplayRecording();

  return datadogRum;
}
