import { Dropdown as YogaDropdown } from '@gympass/yoga';
import { UseControllerProps, useController } from 'react-hook-form';

export interface DropdownProps<OptionValue> {
  controllerOptions: UseControllerProps;
  label: string;
  options: {
    label: string;
    value: OptionValue;
    selected: boolean;
  }[];
  onSelect?: (option: DropdownProps<OptionValue>['options'][number]) => void;
  full?: boolean;
}

function Dropdown<OptionValue>({
  controllerOptions,
  label,
  options,
  onSelect,
  full = true,
}: DropdownProps<OptionValue>) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    ...controllerOptions,
    defaultValue: options.find(({ selected }) => selected)?.value ?? '',
  });

  const handleChange = (
    option: DropdownProps<OptionValue>['options'][number],
  ) => {
    if (option === null) return;
    onSelect?.(option);
    onChange(option.value);
  };

  return (
    <YogaDropdown
      value={value}
      label={label}
      options={options}
      full={full}
      onChange={handleChange}
      error={error?.message}
    />
  );
}

export default Dropdown;
