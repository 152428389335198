import { resolveAddress } from 'utils/address';
import { generateBase64File } from 'utils/file';

import { BusinessInfoFormValues } from '../BusinessInfo';

export async function resolvePartialLead({
  googlePlace,
  ...fields
}: BusinessInfoFormValues) {
  const w9File = fields.w9 && { file: await generateBase64File(fields.w9) };

  const commonFields = {
    businessName: fields.businessName,
    legalName: fields.legalName,
    website: fields.website,
    taxId: fields.taxId,
    w9: w9File,
    phone: fields.phone,
  };

  if (googlePlace) {
    // when Address step is off
    return {
      formattedAddress: fields.formattedAddress,
      additionalAddressInfo: fields.additionalAddressInfo,
      googlePlaceId: googlePlace.place_id,
      googlePlaceRating: googlePlace.rating,
      latitude: googlePlace.geometry?.location?.lat(),
      longitude: googlePlace.geometry?.location?.lng(),
      address: resolveAddress(googlePlace.address_components),
      ...commonFields,
    };
  }
  return commonFields;
}
