import { Box, Button, Tag, Text } from '@gympass/yoga';
import { ExternalLink } from '@gympass/yoga-icons';

import Dialog from 'components/Dialog';
import { useLocalization } from 'providers/LocalizationProvider';
import { SERASA_LINK, SERPRO_LINK, SOLUTI_LINK } from 'utils/links';

interface LearnMoreDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
}

function LearnMoreDialog({ isOpen, closeDialog }: LearnMoreDialogProps) {
  const { t } = useLocalization('digital_certificate_page');

  return (
    <Dialog
      isOpen={isOpen}
      onClose={closeDialog}
      header={t('learn_more.title')}
      content={
        <Box
          d="flex"
          gap="large"
          flexDirection="column"
          alignItems="flex-start"
          textAlign="left"
        >
          <Text.Small style={{ whiteSpace: 'break-spaces' }}>
            {t('learn_more.subtitle')}
          </Text.Small>

          <Box
            d="flex"
            gap="xxsmall"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Button.Link icon={ExternalLink} href={SERPRO_LINK} target="_blank">
              {t('learn_more.serpro_button')}
            </Button.Link>
            <Tag.Informative variant="informative">
              {t('learn_more.recommendation_tag')}
            </Tag.Informative>
          </Box>

          <Button.Link icon={ExternalLink} href={SERASA_LINK} target="_blank">
            {t('learn_more.serasa_button')}
          </Button.Link>
          <Button.Link icon={ExternalLink} href={SOLUTI_LINK} target="_blank">
            {t('learn_more.soluti_button')}
          </Button.Link>
        </Box>
      }
      footer={
        <Button full onClick={closeDialog}>
          {t('learn_more.got_it_button')}
        </Button>
      }
    />
  );
}

export default LearnMoreDialog;
