import { useEffect, useRef } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';

import useEventData from 'pages/Signup/hooks/useEventData';
import { useEventTracking } from 'providers/EventTrackingProvider';

const useRecordFormErrorView = <Fields extends FieldValues>(
  methods: UseFormReturn<Fields>,
) => {
  const previousSubmitCount = useRef(0);
  const { recordErrorView } = useEventTracking();
  const { category, contextData } = useEventData();
  const { submitCount, errors } = methods.formState;

  useEffect(() => {
    if (previousSubmitCount.current !== submitCount) {
      previousSubmitCount.current = submitCount;
      const formErrors = Object.entries(errors).map(
        ([fieldName, fieldProperties]) => ({
          field: fieldName,
          reason: fieldProperties?.type,
        }),
      );
      if (!formErrors.length) return;

      recordErrorView({
        category,
        contextData: {
          ...contextData,
          formErrors,
          formValues: methods.getValues(),
        },
        label: 'error_invalid_filling',
      });
    }
  }, [category, contextData, errors, methods, recordErrorView, submitCount]);
};

export default useRecordFormErrorView;
