import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import Input from 'components/Input';
import useActivities from 'hooks/useActivities';
import { ActivitiesFormValues } from 'pages/Signup/Step/steps/Activities/Activities';
import { useLocalization } from 'providers/LocalizationProvider';
import { useSnackbar } from 'providers/SnackbarProvider';

import ActivitiesSection from '../ActivitiesSection';
import * as S from './Form.styles';

function Form() {
  const { t } = useLocalization();
  const { showSnackbar } = useSnackbar();
  const inputName = 'activities' as const;

  const selectedActivities = useWatch<ActivitiesFormValues, 'activities'>({
    name: inputName,
  });

  const {
    initialActivities,
    categorizeSelectedActivities,
    filterSelectedActivites,
  } = useActivities();

  const { setValue } = useFormContext<ActivitiesFormValues>();

  const handleRemoveActivity = (id: string, category: string) => {
    const filteredActivities = filterSelectedActivites(
      id,
      category,
      selectedActivities,
    );
    setValue(inputName, filteredActivities, {
      shouldDirty: true,
    });
  };

  const categorizedActivities = useMemo(
    () =>
      !selectedActivities.length
        ? []
        : categorizeSelectedActivities(selectedActivities),
    [selectedActivities, categorizeSelectedActivities],
  );

  return (
    <S.Container>
      <Input.Search
        controllerOptions={{
          name: inputName,
          rules: {
            required: t('services_page.input.search_services.error_message'),
            validate: activities => {
              if (activities.length > 10) {
                showSnackbar({
                  message: t('services_page.max_services_error'),
                });
                return false;
              }
              return true;
            },
          },
        }}
        multiple
        options={initialActivities}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={option => t(option.activity_key)}
        groupBy={option => t(option.category_key)}
        noOptionsText={t('services_page.input.search_services.no_results')}
        filterSelectedOptions
        label={t('services_page.input.search_services.label')}
        helper={t('services_page.input.search_services.helper_text')}
      />

      {categorizedActivities.map(activity => {
        return (
          <ActivitiesSection
            key={activity.category_key}
            category_key={activity.category_key}
            activities={activity.activities}
            onRemoveActivity={handleRemoveActivity}
          />
        );
      })}
    </S.Container>
  );
}

export default Form;
