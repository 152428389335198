import type { Language } from 'providers/LocalizationProvider/utils';

export const SERPRO_LINK =
  'https://www.serpro.gov.br/links-fixos-superiores/assinador-digital/assinador-serpro/arquivos/manual_usuario_assinador_desktop.pdf';

export const SERASA_LINK = 'https://www.youtube.com/watch?v=Xp-PyLQ80Ho';

export const SOLUTI_LINK =
  'https://solutiresponde.com.br/como-assinar-um-documento-utilizando-o-certificado-digital/';

export const HELP_CENTER_URLS: Record<Language, string> = {
  en: 'https://helpcenter.gympass.com/en-us/articles?utm_source=self-signup&tab=gym-partners',
  'en-US':
    'https://helpcenter.gympass.com/en-us/articles?utm_source=self-signup&tab=gym-partners',
  'en-GB':
    'https://helpcenter.gympass.com/en-gb/articles?utm_source=self-signup&tab=im-a-partner',
  'en-IE':
    'https://helpcenter.gympass.com/en-ie/articles?utm_source=self-signup&tab=im-a-partner',
  es: 'https://helpcenter.gympass.com/es-es/articles?utm_source=self-signup&tab=gimnasios',
  'es-AR':
    'https://helpcenter.gympass.com/es-ar/articles?utm_source=self-signup&tab=gimnasios',
  'es-CL':
    'https://helpcenter.gympass.com/es-cl/articles?utm_source=self-signup&tab=gimnasios',
  'es-MX':
    'https://helpcenter.gympass.com/es-mx/articles?utm_source=self-signup&tab=gimnasios',
  'pt-BR':
    'https://helpcenter.gympass.com/pt-br/articles?utm_source=self-signup&tab=parceiros',
  pt: 'https://helpcenter.gympass.com/pt-br/articles?utm_source=self-signup&tab=parceiros',
  de: 'https://helpcenter.gympass.com/de-de/articles?utm_source=self-signup&tab=partnereinrichtungen',
  it: 'https://helpcenter.gympass.com/it-it/articles?utm_source=self-signup&tab=partners',
};
