import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import StepTemplate from 'components/StepTemplate';
import config from 'config';
import useRecordFormErrorView from 'hooks/useRecordFormErrorView';
import useEventData from 'pages/Signup/hooks/useEventData';
import useOptimizely from 'pages/Signup/hooks/useOptimizely';
import { useEventTracking } from 'providers/EventTrackingProvider';
import { Lead, useLead } from 'providers/LeadProvider';
import { useLocalization } from 'providers/LocalizationProvider';
import { Country } from 'providers/LocalizationProvider/utils';
import { useSnackbar } from 'providers/SnackbarProvider';
import { useSteps } from 'providers/StepsProvider';
import { useUpdateLead } from 'services/partnersSelfSignup';

import Form from './Form';
import { goToTraniacPage } from './utils/handlers';

interface BusinessTypeFormValues {
  businessType: NonNullable<Lead['businessType']>;
  numberOfLocations?: Lead['numberOfLocations'];
}

function BusinessType() {
  const { t } = useLocalization();
  const { lead, selfSignUpId, updateLead } = useLead();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const { mutateAsync, isLoading } = useUpdateLead();
  const { showSnackbar } = useSnackbar();
  const { next, updateLastStepSubmitted, current } = useSteps();
  const { recordClick, recordErrorView } = useEventTracking();
  const { category, contextData } = useEventData();
  const { sendOptimizelyEvent } = useOptimizely();
  const methods = useForm<BusinessTypeFormValues>({
    defaultValues: {
      businessType: lead?.businessType,
      numberOfLocations: lead?.numberOfLocations,
    },
  });

  useRecordFormErrorView<BusinessTypeFormValues>(methods);

  const onSubmit = async (values: BusinessTypeFormValues) => {
    const shouldBeRedirected = values.businessType === 'personal_trainer';

    const trackingValues = {
      category,
      contextData: {
        formValues: values,
        ...contextData,
      },
    };
    recordClick({
      ...trackingValues,
      contextData: {
        ...trackingValues.contextData,
        redirected: false,
      },
      label: 'continue',
    });

    if (
      values.businessType === config.OPTIMIZELY_BUSINESS_TYPE &&
      lead?.country === config.OPTIMIZELY_COUNTRY
    ) {
      sendOptimizelyEvent('page');
    }

    try {
      await mutateAsync({
        step: current,
        isLastStep: false,
        selfSignUpId: selfSignUpId as string,
        lastStepSubmitted: updateLastStepSubmitted(),
        lead: {
          ...lead,
          w9: undefined,
          memberships: undefined,
          ...values,
        },
      });

      if (shouldBeRedirected) {
        goToTraniacPage(lead?.country as Country);
        setIsRedirecting(true);
        return;
      }

      updateLead(values);
      next();
    } catch {
      recordErrorView({
        ...trackingValues,
        label: 'something_went_wrong',
      });
      showSnackbar({ message: t('generic_error') });
    }
  };

  return (
    <FormProvider {...methods}>
      <StepTemplate<BusinessTypeFormValues>
        title={t('partner_type_page.title')}
        formAriaLabel={t('partner_type_page.form.aria_label')}
        onSubmit={onSubmit}
        loading={isLoading || isRedirecting}
      >
        <Form />
      </StepTemplate>
    </FormProvider>
  );
}

export default BusinessType;
