import { theme } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';
import styled from 'styled-components';

const { spacing, colors } = theme;

type FooterProps = {
  hasBackButton: boolean;
};

export const footerHeight = 97;

export const Container = styled.footer<FooterProps>`
  position: sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: ${({ hasBackButton }) =>
    hasBackButton ? 'space-between' : 'end'};
  align-items: end;
  gap: ${spacing.small}px;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.light};
  padding: ${spacing.small}px ${spacing.medium}px;
  margin-top: ${spacing.small}px;

  ${media.md`
    padding: ${spacing.small}px ${spacing.xxxlarge}px;  
  `}
`;
