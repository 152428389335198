import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { FunctionComponent } from 'react';

import Loading from 'components/Loading';
import config from 'config';

function withGoogleMapsAPI(Component: FunctionComponent) {
  const render = (status: Status) =>
    status === Status.LOADING ? <Loading /> : <Component />;

  function APIWrapper() {
    return (
      <Wrapper
        apiKey={config.GOOGLE_MAPS_API_KEY}
        libraries={['places']}
        render={render}
      />
    );
  }

  return APIWrapper;
}

export default withGoogleMapsAPI;
