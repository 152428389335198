import { theme } from '@gympass/yoga';
import styled from 'styled-components';

const { borders, colors, spacing } = theme;

export const Container = styled.section`
  display: flex;
  justify-content: space-between;
  padding-top: ${spacing.large}px;
  border-top: ${borders.small}px solid ${colors.light};
`;
