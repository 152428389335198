import { Menu, Text } from '@gympass/yoga';
import { MenuMore, Refresh, Trash } from '@gympass/yoga-icons';
import tokens from '@gympass/yoga-tokens';
import { MouseEvent, PropsWithChildren, useEffect, useState } from 'react';

import * as S from './ImagePreview.styles';

interface ImagePreviewProps {
  file: File;
  updateText: string;
  updateFile: () => void;
  removeText: string;
  removeFile: (event: MouseEvent<HTMLElement>) => void;
}

function ImagePreview({
  file,
  updateText,
  updateFile,
  removeText,
  removeFile,
  children,
}: PropsWithChildren<ImagePreviewProps>) {
  const [preview, setPreview] = useState<{
    source?: string;
    aspectRatio?: number;
  }>({});

  useEffect(() => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      setPreview({
        source: image.src,
        aspectRatio: image.width / image.height,
      });
    };

    return () => {
      URL.revokeObjectURL(image.src);
    };
  }, [file]);

  return (
    <S.Container aspectRatio={preview.aspectRatio}>
      <S.Image src={preview.source} alt={file.name} />
      <S.Actions>
        <Menu onMouseHover={false}>
          <Menu.Action>
            <S.Button type="button">
              <MenuMore width={24} height={24} fill={tokens.colors.vibin} />
            </S.Button>
          </Menu.Action>

          <Menu.List align="end">
            <Menu.Item
              onClick={updateFile}
              icon={Refresh}
              iconColor={tokens.colors.vibin}
            >
              {children}
              <Text>{updateText}</Text>
            </Menu.Item>

            <Menu.Item
              icon={Trash}
              onClick={removeFile}
              iconColor={tokens.colors.vibin}
            >
              <Text>{removeText}</Text>
            </Menu.Item>
          </Menu.List>
        </Menu>
      </S.Actions>
    </S.Container>
  );
}

export default ImagePreview;
