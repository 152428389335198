import { Box, Text, theme } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';
import styled from 'styled-components';

const { spacing } = theme;

export const Label = styled(Text).attrs({
  as: 'label',
})`
  display: flex;
  align-items: center;
  gap: ${spacing.xxsmall}px;
  cursor: pointer;
`;

export const InputContainer = styled(Box)`
  margin-top: ${spacing.large}px;
  width: 100%;

  ${media.md`
    width: 25rem;  
  `}
`;
