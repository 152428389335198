import { useEffect, useRef, useState } from 'react';

import { useEventTracking } from 'providers/EventTrackingProvider';
import { useFlags } from 'providers/FlagsProvider';
import { Lead } from 'providers/LeadProvider';
import { useLocalization } from 'providers/LocalizationProvider';
import { Country } from 'providers/LocalizationProvider/utils';
import { useSnackbar } from 'providers/SnackbarProvider';
import { useCreateLead, useGetLead } from 'services/partnersSelfSignup';

import useEventData from '../useEventData';

function useFlowInitialization() {
  const { t, setCountry } = useLocalization();
  const isEnabled = useFlags();
  const [currentStep, setCurrentStep] = useState(1);
  const [selfSignUpId, setSelfSignUpId] = useState<string>();
  const [leadRecord, setLeadRecord] = useState<Lead>();
  const isLeadRecovered = useRef(false);
  const { mutateAsync: createLeadMutate } = useCreateLead();
  const { mutateAsync: getLeadMutate } = useGetLead();
  const { showSnackbar } = useSnackbar();
  const { recordErrorView } = useEventTracking();
  const { category, contextData } = useEventData();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isEnabled.email_validation) return;

    setLoading(true);
    const url = new URL(window.location.href);
    const validationId = url.searchParams.get('validation_id');

    if (!validationId) {
      setLoading(false);
      return;
    }
    const sessionId = url.searchParams.get('user_state');
    const source = url.searchParams.get('source');
    const email = url.searchParams.get('email');
    const storageItem = sessionStorage.getItem('emailValidationState');
    const { userId, ...lead } = JSON.parse(storageItem ?? '{}');

    const createLead = async () => {
      try {
        const response = await createLeadMutate({
          step: 1,
          isLastStep: false,
          lastStepSubmitted: 1,
          emailValidationId: validationId as string,
          lead,
        });

        setCountry(lead.country);
        setSelfSignUpId(response.selfSignUpId);
        setLeadRecord({ id: response.lead.id, ...lead });
        setCurrentStep(2);
      } catch (error) {
        recordErrorView({
          category,
          label: 'email_validation',
          contextData: {
            ...contextData,
            formValues: lead,
            validationEmail: email,
          },
        });
        showSnackbar({ message: t('generic_error') });
      }
    };

    const getLead = async () => {
      try {
        const recoveredLead = await getLeadMutate({
          businessEmail: email as string,
          validationId: validationId as string,
        });
        if (!recoveredLead) return false;

        const country = recoveredLead.lead?.country?.toLowerCase() as Country;

        isLeadRecovered.current = true;
        setCountry(country);
        setSelfSignUpId(recoveredLead.selfSignUpId);
        setLeadRecord({
          ...recoveredLead.lead,
          country,
        });
        setCurrentStep(
          recoveredLead.lastStepSubmitted === 1
            ? 2
            : recoveredLead.lastStepSubmitted,
        );
        return true;
      } catch {
        recordErrorView({
          category,
          label: 'email_validation_lead_not_found',
          contextData: {
            ...contextData,
            formValues: lead,
            validationEmail: email,
          },
        });
        showSnackbar({ message: t('generic_error') });
        return true;
      }
    };

    const resetState = () => {
      sessionStorage.removeItem('emailValidationState');
      window.history.replaceState({}, '', `/?source=${source}`);
      setLoading(false);
    };

    const init = async () => {
      if (sessionId !== userId || email !== lead?.businessEmail) {
        recordErrorView({
          category,
          label: 'email_validation',
          contextData: {
            ...contextData,
            formValues: lead,
            validationEmail: email,
          },
        });
        showSnackbar({ message: t('generic_error') });
        resetState();
        return;
      }

      if (!isEnabled.recovery_lead || !(await getLead())) {
        await createLead();
      }

      resetState();
    };

    init();
  }, [
    isEnabled.email_validation,
    isEnabled.recovery_lead,
    setCountry,
    recordErrorView,
    showSnackbar,
    t,
    category,
    contextData,
    getLeadMutate,
    createLeadMutate,
  ]);

  return {
    currentStep,
    selfSignUpId,
    leadRecord,
    loading,
    isLeadRecovered: isLeadRecovered.current,
  };
}

export default useFlowInitialization;
