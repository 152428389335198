import { Banner, Text } from '@gympass/yoga';
import { AlertCircle } from '@gympass/yoga-icons';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import googleLogo from 'assets/images/google-logo.png';
import BusinessRegister from 'components/BusinessRegister';
import Input from 'components/Input';
import { useGooglePlacesServices } from 'providers/GooglePlacesProvider';
import { useLead } from 'providers/LeadProvider';
import { useLocalization } from 'providers/LocalizationProvider';

import * as S from './Form.styles';
import { useFields } from './hooks';

function Form() {
  const { t } = useLocalization('address_page');

  const { place } = useGooglePlacesServices();
  const fields = useFields();
  const { setValue, getValues } = useFormContext();
  const { lead } = useLead();
  const formValues = getValues();

  useEffect(() => {
    const googlePlace =
      !place && lead?.formattedAddress
        ? {
            formatted_address: lead?.formattedAddress,
            place_id: lead?.googlePlaceId,
            rating: lead?.googlePlaceRating,
          }
        : place;

    setValue('googlePlace', googlePlace, {
      shouldDirty: true,
      shouldValidate: !!googlePlace,
    });
  }, [place, setValue, lead]);

  return (
    <S.Container>
      <Banner
        icon={AlertCircle}
        marginBottom="medium"
        variant="informative"
        message={t('address_snackbar.description')}
      />
      <div>
        <Input.GooglePlace {...fields.googlePlace} />
        <S.PoweredByGoogle>
          Powered by <img src={googleLogo} alt="Google" />
        </S.PoweredByGoogle>

        <S.List>
          <li>{t('google_place.helper_text_1')}</li>
          <li>{t('google_place.helper_text_2')}</li>
        </S.List>
      </div>

      <S.Divider />
      {formValues?.googlePlace && (
        <>
          <Text color="deep" fontSize="xsmall">
            {t('results.title')}
          </Text>

          <S.FormattedAddress>
            {formValues.googlePlace.formatted_address}
          </S.FormattedAddress>
          <Input {...fields.additionalAddressInfo} />
          <S.Divider />
        </>
      )}

      <BusinessRegister />
    </S.Container>
  );
}

export default Form;
