import RadioCard from './RadioCard';
import Group from './RadioGroup';

export { RadioGroupContext } from './RadioGroup';

type RadioComponents = {
  Card: typeof RadioCard;
};

const RadioGroup: typeof Group & RadioComponents = Object.assign(Group, {
  Card: RadioCard,
});

export type { RadioGroupProps } from './RadioGroup';
export type { RadioCardProps } from './RadioCard';
export default RadioGroup;
