import { Box, Button, Card, List, Text } from '@gympass/yoga';
import { ArrowRight, ExternalLink } from '@gympass/yoga-icons';
import { useState } from 'react';

import Dialog from 'components/Dialog';
import GoogleDialog from 'components/GoogleDialog';
import useEventData from 'pages/Signup/hooks/useEventData';
import { useEventTracking } from 'providers/EventTrackingProvider';
import { useLocalization } from 'providers/LocalizationProvider';
import { goToGoogleBusinessRegister } from 'utils/redirects';

import * as S from './BusinessRegister.styles';

function BusinessRegister() {
  const { t } = useLocalization('business_register');
  const { t: addressTrans } = useLocalization('address_page');

  const { category, contextData } = useEventData();
  const [isRedirectWarningOpen, setIsRedirectWarningOpen] = useState(false);
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);
  const { recordClick } = useEventTracking();

  const onClick = () => {
    const { value } = document.getElementsByTagName('input')[0];
    recordClick({
      category,
      label: 'add_google_profile',
      contextData: {
        ...contextData,
        searchInputValue: value,
      },
    });

    goToGoogleBusinessRegister();
  };

  const toggleRedirectWarning = () => {
    setIsRedirectWarningOpen(!isRedirectWarningOpen);
  };

  const toggleInfoDialog = () => {
    setIsInfoDialogOpen(!isInfoDialogOpen);
  };

  return (
    <>
      <Card>
        <List>
          <List.LinkItem
            onClick={toggleInfoDialog}
            style={{ cursor: 'pointer' }}
          >
            <Box display="flex" justifyContent="space-between">
              <Text.Small>
                {addressTrans('card.google_details.title')}
              </Text.Small>
              <S.IconButton icon={ArrowRight} inverted type="button" />
            </Box>
          </List.LinkItem>
          <List.LinkItem
            onClick={toggleRedirectWarning}
            style={{ cursor: 'pointer' }}
          >
            <Box display="flex" justifyContent="space-between">
              <Text.Small>{t('title')}</Text.Small>
              <Button.Link small icon={ExternalLink} type="button">
                {t('button.open_redirect_warning.label')}
              </Button.Link>
            </Box>
          </List.LinkItem>
        </List>
      </Card>

      <Dialog
        isOpen={isRedirectWarningOpen}
        onClose={toggleRedirectWarning}
        header={t('redirect_warning.title')}
        content={t('redirect_warning.subtitle')}
        footer={
          <>
            <Button icon={ExternalLink} onClick={onClick}>
              {t('redirect_warning.button.add_profile.label')}
            </Button>
            <Button.Text onClick={toggleRedirectWarning}>
              {t('redirect_warning.button.search_again.label')}
            </Button.Text>
          </>
        }
      />
      <GoogleDialog
        isOpen={isInfoDialogOpen}
        onClose={toggleInfoDialog}
        onAccept={onClick}
      />
    </>
  );
}

export default BusinessRegister;
