import { Input as YogaInput } from '@gympass/yoga';
import { ChangeEvent, HTMLAttributes } from 'react';
import { useController } from 'react-hook-form';

import { InputProps } from '..';

export interface InputTextProps extends InputProps {
  maxLength?: number;
  hideMaxLength?: boolean;
  inputMode?: HTMLAttributes<HTMLElement>['inputMode'];
  mask?: (input: string) => string;
}

function InputText({
  controllerOptions,
  label,
  helper,
  maxLength = 255,
  hideMaxLength = true,
  full = true,
  inputMode = 'text',
  cleanable = false,
  onClean,
  disabled = false,
  mask,
}: InputTextProps) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController(controllerOptions);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    onChange(mask?.(target.value) ?? target.value);
  };

  return (
    <YogaInput
      value={value}
      label={label}
      helper={helper}
      maxLength={maxLength}
      hideMaxLength={hideMaxLength}
      full={full}
      onChange={handleChange}
      error={error?.message}
      inputMode={inputMode}
      cleanable={cleanable}
      onClean={onClean}
      disabled={disabled}
    />
  );
}

export default InputText;
