import { Banner, Tag, theme } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';
import styled from 'styled-components';

const { spacing, fontSizes, colors } = theme;

export const Container = styled.div`
  padding: 0 ${spacing.medium}px;
  display: grid;
  gap: ${spacing.xlarge}px;
  ${media.md`
    padding: ${spacing.xxlarge}px ${spacing.xxxlarge}px;
    gap: ${spacing.xxlarge}px;
  `}
`;

export const TextContainer = styled.div`
  display: grid;
  gap: ${spacing.xsmall}px;
  ${media.md`
    gap: ${spacing.small}px;
  `}
`;

export const TitleTag = styled(Tag.Informative)`
  background-color: ${colors.yoga};
  justify-self: start;
  font-size: ${fontSizes.small}px;
  padding: ${spacing.xxsmall}px ${spacing.xsmall}px;
`;

export const BannerInfo = styled(Banner)`
  justify-self: start;
`;
