import { FormProvider, useForm } from 'react-hook-form';

import StepTemplate from 'components/StepTemplate';
import useActivities from 'hooks/useActivities';
import useRecordFormErrorView from 'hooks/useRecordFormErrorView';
import useEventData from 'pages/Signup/hooks/useEventData';
import { useEventTracking } from 'providers/EventTrackingProvider';
import { Lead, useLead } from 'providers/LeadProvider';
import { useLocalization } from 'providers/LocalizationProvider';
import { useSnackbar } from 'providers/SnackbarProvider';
import { useSteps } from 'providers/StepsProvider';
import { useUpdateLead } from 'services/partnersSelfSignup';

import Form from './Form';

export type Activities = NonNullable<Lead['activities']>;

export interface CategorizedActivity {
  category_key: string;
  activities: Activities;
}

export interface ActivitiesFormValues {
  activities: Activities;
}

function Activities() {
  const { lead, updateLead, selfSignUpId } = useLead();
  const { mutateAsync, isLoading } = useUpdateLead();
  const { showSnackbar } = useSnackbar();
  const { t } = useLocalization();
  const { next, updateLastStepSubmitted, current } = useSteps();
  const { resolveActivities, categorizeSelectedActivitiesById } =
    useActivities();
  const { recordClick, recordErrorView } = useEventTracking();
  const { category, contextData } = useEventData();
  const methods = useForm<ActivitiesFormValues>({
    defaultValues: {
      activities:
        lead?.activities ||
        categorizeSelectedActivitiesById(lead?.activitiesIds ?? []) ||
        [],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useRecordFormErrorView<ActivitiesFormValues>(methods);

  const onSubmit = async (values: ActivitiesFormValues) => {
    const trackingValues = {
      category,
      contextData: {
        formValues: values,
        ...contextData,
      },
    };
    recordClick({
      ...trackingValues,
      label: 'continue',
    });

    const { activitiesIds, productName } = resolveActivities(values.activities);

    try {
      await mutateAsync({
        step: current,
        isLastStep: false,
        selfSignUpId: selfSignUpId ?? '',
        lastStepSubmitted: updateLastStepSubmitted(),
        lead: {
          ...lead,
          w9: undefined,
          memberships: undefined,
          activitiesIds,
          productName,
        },
      });
      updateLead({
        activitiesIds,
        productName,
        activities: values.activities,
      });
      next();
    } catch (error) {
      recordErrorView({
        ...trackingValues,
        label: 'something_went_wrong',
      });
      showSnackbar({ message: t('generic_error') });
    }
  };

  return (
    <FormProvider {...methods}>
      <StepTemplate
        formAriaLabel={t('services_page.form.aria_label')}
        onSubmit={onSubmit}
        title={t('services_page.title')}
        loading={isLoading}
      >
        <Form />
      </StepTemplate>
    </FormProvider>
  );
}

export default Activities;
