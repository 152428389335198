import gympassLogoVibin from 'assets/images/gympass-logo-vibin.png';
import wellhubLogoVibin from 'assets/images/wellhub-logo-vibin.png';
import useEventData from 'pages/Signup/hooks/useEventData';
import { useEventTracking } from 'providers/EventTrackingProvider';
import { useSteps } from 'providers/StepsProvider';

import * as S from './GympassLogo.styles';

export interface GympassLogoProps {
  label?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
}

function Image({
  width,
  height,
}: Required<Pick<GympassLogoProps, 'height' | 'width'>>) {
  const { isRebrandingEnabled } = useSteps();

  const logo = isRebrandingEnabled ? (
    <img src={wellhubLogoVibin} alt="wellhub-logo" width={width * 1.5} />
  ) : (
    <img
      src={gympassLogoVibin}
      alt="gympass-logo"
      width={width}
      height={height}
    />
  );

  return logo;
}

function GympassLogo({
  label,
  onClick,
  width = 82,
  height = 16,
}: GympassLogoProps) {
  const { recordClick } = useEventTracking();
  const { category, contextData } = useEventData();

  const handleClick =
    onClick &&
    (() => {
      recordClick({
        category,
        label: 'gympass_logo',
        contextData,
      });
      onClick();
    });

  return onClick && label ? (
    <S.LogoButton type="button" aria-label={label} onClick={handleClick}>
      <Image width={width} height={height} />
    </S.LogoButton>
  ) : (
    <Image width={width} height={height} />
  );
}

export default GympassLogo;
