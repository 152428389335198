export const features = [
  { name: 'partners.self_signup.email_validation', fallbackValue: false },
  { name: 'partners.self_signup.recovery_lead', fallbackValue: false },
  { name: 'partners.self_signup.digital_certificate', fallbackValue: false },
];

export const defaultValues = features.reduce(
  (acc, { name, fallbackValue }) => ({
    ...acc,
    [name]: fallbackValue,
  }),
  {},
);
