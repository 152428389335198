import { Button, Progress } from '@gympass/yoga';
import { HelpCircle } from '@gympass/yoga-icons';
import { useCallback, useMemo } from 'react';

import useDeviceViewport from 'hooks/useDeviceViewport';
import useEventData from 'pages/Signup/hooks/useEventData';
import { useEventTracking } from 'providers/EventTrackingProvider';
import { useLocalization } from 'providers/LocalizationProvider';
import { useSteps } from 'providers/StepsProvider';
import { goToHelpCenter } from 'utils/redirects';

import * as S from './Header.styles';

function Header() {
  const { current, total } = useSteps();
  const { t, language } = useLocalization('header.button.help_center');
  const { isMobile, scrollY } = useDeviceViewport();
  const { recordClick } = useEventTracking();
  const { category, contextData } = useEventData();

  const onClick = useCallback(() => {
    recordClick({
      category,
      label: 'help_center',
      contextData,
    });

    goToHelpCenter(language);
  }, [recordClick, category, contextData, language]);

  const progress = useMemo(
    () => ({
      total: total - 1,
      current: current - 1,
    }),
    [total, current],
  );

  return (
    <S.Container hasBackground={scrollY > 20}>
      {isMobile ? (
        <Button.Icon
          onClick={onClick}
          icon={HelpCircle}
          aria-label={t('aria_label')}
          inverted
        />
      ) : (
        <Button.Outline
          onClick={onClick}
          icon={HelpCircle}
          aria-label={t('aria_label')}
          small
        >
          {t('label')}
        </Button.Outline>
      )}

      <Progress
        max={progress.total}
        value={progress.current}
        variant="secondary"
      />
    </S.Container>
  );
}

export default Header;
