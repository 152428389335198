import { theme } from '@gympass/yoga';
import styled from 'styled-components';

const { spacing } = theme;

export const LogoButton = styled.button`
  padding: 0;
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin: ${spacing.medium}px ${spacing.xsmall}px;
`;
