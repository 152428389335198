import { Box, Checkbox, Text } from '@gympass/yoga';
import { ChangeEvent, useCallback, useState } from 'react';

import { useLocalization } from 'providers/LocalizationProvider';

import { AvailablePlans } from '..';
import MembershipDetails from './MembershipDetails';

function MembershipPlan() {
  const { t } = useLocalization('pricing_page.section_1');

  const [plans, setPlans] = useState<AvailablePlans>({
    annual: false,
    semi_annual: false,
    quarterly: false,
    monthly: false,
  });

  const togglePlan = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;
      setPlans({ ...plans, [name]: checked });
    },
    [plans],
  );

  return (
    <Box marginVertical="large">
      <Text.Medium marginBottom="large" lineHeight="medium">
        {t('title')}
      </Text.Medium>

      {Object.entries(plans).map(([duration, checked]) => (
        <Box key={duration} marginVertical="large">
          <Checkbox
            label={t(`input.option.${duration}`)}
            name={duration}
            onChange={togglePlan}
            checked={checked}
          />
          {checked && (
            <Box marginVertical="large">
              <MembershipDetails duration={duration as keyof AvailablePlans} />
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
}

export default MembershipPlan;
