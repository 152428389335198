import { Lead } from 'providers/LeadProvider';

import { AvailablePlans } from '../Form';

export function priceStringToFloat(value: string) {
  const onlyDigits = value.replace(/[^\d.,]/g, '');
  const noThousandSeparators = onlyDigits.replace(/([.,])(?!.{2}$)/g, '');
  const floatString = noThousandSeparators.replace(',', '.');

  return parseFloat(floatString);
}

type Plan = NonNullable<Lead['memberships']>['plans'][number];

type PlanDetails = Plan['details'][number];

export function resolvePlans(
  fields: Record<string, string | number>,
  isGymFacility: boolean,
) {
  if (isGymFacility) {
    return [
      {
        duration: fields.duration as keyof AvailablePlans,
        details: [
          {
            usageRestriction: 0 as PlanDetails['usageRestriction'],
            price: priceStringToFloat(fields.price as string),
          },
        ],
      },
    ];
  }
  const plansByDuration = Object.entries(fields).reduce<
    Record<keyof AvailablePlans, PlanDetails[]>
  >(
    (plans, [field, value]) => {
      const [duration, detailProperty] = field.split('-');
      const plan = plans[duration as keyof AvailablePlans];
      const detailValue =
        typeof value === 'string' ? priceStringToFloat(value) : value;
      if (!plan.length) {
        plan.push({ [detailProperty]: detailValue } as PlanDetails);
      } else {
        plan[0][detailProperty as keyof PlanDetails] =
          detailValue as PlanDetails['usageRestriction'];
      }

      return plans;
    },
    {
      annual: [],
      semi_annual: [],
      quarterly: [],
      monthly: [],
    },
  );

  return Object.entries(plansByDuration).reduce<Plan[] | never[]>(
    (plans, [duration, details]) => {
      if (!details.length) {
        return plans;
      }

      return [
        ...plans,
        {
          duration: duration as keyof AvailablePlans,
          details,
        },
      ];
    },
    [],
  );
}
