import { useLocalization } from 'providers/LocalizationProvider';

import { AvailablePlans } from '../..';
import { resolveUsageRestrictionOptions } from '../utils';

export function useFields(duration: keyof AvailablePlans) {
  const { t } = useLocalization('pricing_page.section_1.input');

  return {
    usageRestriction: {
      label: t('usage.label'),
      options: resolveUsageRestrictionOptions(t),
      controllerOptions: {
        name: `${duration}-usageRestriction`,
        shouldUnregister: true,
        rules: {
          required: t('usage.error_message'),
        },
      },
    },
    price: {
      label: t(`price.${duration}.label`),
      controllerOptions: {
        name: `${duration}-price`,
        shouldUnregister: true,
        rules: {
          required: t('price.error_message'),
          validate: (value: string) => {
            const onlyDigits = value.replace(/[^\d.,]/g, '');
            if (parseFloat(onlyDigits) <= 0) {
              return t('price.greater_than_0');
            }
            return true;
          },
        },
      },
    },
  };
}
