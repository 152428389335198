import { Text, Divider as YogaDivider, theme } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';
import styled from 'styled-components';

const { spacing, colors, fontSizes, lineHeights } = theme;

export const Container = styled.section`
  display: grid;
  padding-inline: ${spacing.medium}px;
  margin: ${spacing.xlarge}px auto;

  ${media.md`
    max-width: 656px;
    padding-inline: ${spacing.xxxlarge}px;
    margin: ${spacing.xxlarge}px auto;
  `};
`;

export const FormattedAddress = styled(Text)`
  margin: ${spacing.medium}px 0 ${spacing.xlarge}px 0;
`;

export const Divider = styled(YogaDivider)`
  margin: ${spacing.large}px 0;
`;

export const PoweredByGoogle = styled(Text)`
  display: flex;
  gap: ${spacing.xxxsmall}px;
  align-items: center;
  justify-content: end;
  margin-top: ${spacing.xxsmall}px;
  color: ${colors.deep};
  font-size: ${fontSizes.xsmall}px;

  img {
    margin-top: 2px;
  }
`;

export const List = styled.ul`
  margin: ${spacing.small}px ${spacing.small}px;

  li {
    list-style-type: disc;
    list-style-position: inside;
    font-size: ${fontSizes.xsmall}px;
    line-height: ${lineHeights.xsmall}px;

    &:not(:last-child) {
      margin-bottom: ${spacing.xxsmall}px;
    }

    &::marker {
      color: ${colors.vibin};
    }
  }
`;
