import { Text, theme } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';
import styled from 'styled-components';

import { footerHeight } from './Footer/Footer.styles';
import {
  desktopHeaderHeight,
  mobileHeaderHeight,
} from './Header/Header.styles';

const { spacing, colors, fontSizes, lineHeights } = theme;

export const Container = styled.main`
  ${media.md`
    display: grid;
    grid-template-columns: 2fr 3fr;
    height: 100%;
  `}
`;

export const FormFields = styled.section`
  min-height: calc(100svh - ${footerHeight + mobileHeaderHeight}px);
  min-height: calc(100vh - ${footerHeight + mobileHeaderHeight}px);

  ${media.md`
    min-height: unset;
    height: calc(100svh - ${footerHeight + desktopHeaderHeight}px);
    height: calc(100vh - ${footerHeight + desktopHeaderHeight}px);
    overflow-y: auto;
  `}
`;

export const SectionTitle = styled.section`
  display: grid;
  gap: ${spacing.small}px;
  padding: ${spacing.small}px ${spacing.medium}px;
  margin-bottom: ${spacing.xlarge}px;
  margin-top: ${mobileHeaderHeight}px;
  background-color: ${colors.white};

  ${media.md`
    grid-template-rows: min-content auto;
    height: 100%;
    padding: ${spacing.xxlarge}px;
    margin: 0;
    background-color: ${colors.sand};
  `}
`;

export const Title = styled(Text.Display1).attrs({
  as: 'h1',
})`
  font-size: ${fontSizes.xxlarge}px;
  line-height: ${lineHeights.xlarge}px;

  ${media.md`
    text-align: center;
    font-size: ${fontSizes.xhuge}px;
    line-height: ${lineHeights.xhuge}px;
    justify-self: center;
    align-self: center;
  `};
`;
