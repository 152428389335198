import { LocalizationContext } from 'providers/LocalizationProvider';

import { validateCNPJ } from './CNPJ';
import { validateCUIT } from './CUIT';
import { validateRUT } from './RUT';

type ValidationProperties = Record<
  NonNullable<LocalizationContext['country']>,
  { regex: RegExp; mask: string; validate?: (val: string) => boolean }
>;

const validationProperties: ValidationProperties = {
  ar: {
    regex: /^\d{11}$/,
    mask: '99-99999999-9',
    validate: validateCUIT,
  },
  br: {
    regex: /^\d{14}$/,
    mask: '99.999.999/9999-99',
    validate: validateCNPJ,
  },
  cl: {
    regex: /^\d{8}[K0-9]$/,
    mask: '99.999.999-*',
    validate: validateRUT,
  },
  de: {
    regex: /^(DE)?\d{9}$/,
    mask: 'DE999999999',
  },
  es: {
    regex: /^(ES)?[A-Z]\d{7}[A-Z0-9]$/,
    mask: 'ESa9999999*',
  },
  ie: {
    regex: /^(IE)?\d{7}[A-Z]{1,2}$/,
    mask: 'IE9999999aa',
  },
  it: {
    regex: /^(IT)?\d{11}$/,
    mask: 'IT99999999999',
  },
  mx: {
    regex: /^[A-Z]{3,4}\d{6}[A-Z\d]{3}$/,
    mask: 'aaa*99999****',
  },
  gb: {
    regex: /^(GB)?\d{9}(\d{3})?$/,
    mask: 'GB999999999999',
  },
  us: {
    regex: /^\d{9}$/,
    mask: '99-9999999',
  },
};

export function resolveValidationProperties(
  country: keyof ValidationProperties,
) {
  return validationProperties[country];
}
