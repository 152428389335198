import { Icon, Text } from '@gympass/yoga';
import tokens from '@gympass/yoga-tokens';
import { ReactNode, useContext } from 'react';

import { RadioGroupContext } from '..';
import * as S from './RadioCard.styles';

export interface RadioCardProps<RadioOptionValue> {
  value: RadioOptionValue;
  icon: ReactNode;
  title: string;
  description: string;
  ariaLabel: string;
  children?: ReactNode;
}

function RadioCard<RadioOptionValue extends string>({
  value,
  icon,
  title,
  description,
  ariaLabel,
  children,
}: RadioCardProps<RadioOptionValue>) {
  const context = useContext(RadioGroupContext);
  const checked = value === context?.value;

  return (
    <>
      <S.Card
        key={value}
        htmlFor={value}
        aria-label={ariaLabel}
        checked={checked}
      >
        <S.Input
          id={value}
          type="radio"
          name={context?.value}
          value={value}
          onChange={context?.handleChange}
          checked={checked}
        />
        <Icon
          as={icon}
          width={20}
          height={20}
          fill={tokens.colors.vibin}
          aria-hidden
        />
        <Text fontWeight="medium" aria-hidden>
          {title}
        </Text>
        <Text.Small aria-hidden>{description}</Text.Small>
      </S.Card>
      {children}
    </>
  );
}

export default RadioCard;
