import { Button, Text } from '@gympass/yoga';

import useEventData from 'pages/Signup/hooks/useEventData';
import { useEventTracking } from 'providers/EventTrackingProvider';
import { useLocalization } from 'providers/LocalizationProvider';
import { goToHelpCenter } from 'utils/redirects';

import * as S from './NeedHelp.styles';

function NeedHelp() {
  const { t, language } = useLocalization('register_page');
  const { recordClick } = useEventTracking();
  const { category, contextData } = useEventData();

  const onClick = () => {
    recordClick({
      category,
      label: 'get_in_touch',
      contextData,
    });
    goToHelpCenter(language);
  };

  return (
    <S.Container>
      <Text aria-hidden>{t('get_in_touch.label')}</Text>
      <Button.Link onClick={onClick} aria-label={t('get_in_touch.aria_label')}>
        {t('get_in_touch.button')}
      </Button.Link>
    </S.Container>
  );
}

export default NeedHelp;
