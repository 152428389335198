import config from 'config';

export type GetFeaturesVariables = {
  name: string;
  context?: Record<string, unknown>;
  fallbackValue?: boolean;
}[];

export type GetFeaturesResponse = Record<string, boolean>;

export async function getFeatures(
  variables: GetFeaturesVariables,
): Promise<GetFeaturesResponse> {
  const url = `${config.PARTNERS_SELF_SIGNUP_BFF_URL}/features`;

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(variables),
  });

  if (!response.ok) {
    throw new Error(`${response.status} (${response.statusText})`);
  }

  const result: GetFeaturesResponse = await response.json();

  return result;
}
