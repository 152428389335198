import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

import { StepName } from 'pages/Signup/Step/steps';
import { LocalizationContext } from 'providers/LocalizationProvider';
import { Base64File } from 'utils/file';

export interface Lead {
  id?: string;
  firstName?: string;
  lastName?: string;
  legalName?: string;
  businessEmail?: string;
  country?: LocalizationContext['country'];
  currency?: NonNullable<LocalizationContext['currency']>['iso'];
  businessType?: 'gym' | 'studio' | 'wellness_service' | 'personal_trainer';
  numberOfLocations?: number;
  businessName?: string;
  website?: string;
  phone?: string;
  taxId?: string;
  w9?: {
    file?: Base64File;
  };
  googlePlaceId?: string;
  googlePlaceRating?: number;
  formattedAddress?: string;
  additionalAddressInfo?: string;
  latitude?: number;
  longitude?: number;
  address?: {
    city: string;
    postalCode: string;
    street: string;
  } & RequireEither<{
    state: string;
    stateCode: string;
  }>;
  productName?: string;
  activities?: {
    id: string;
    category_key: string;
    activity_key: string;
  }[];
  activitiesIds?: string[];
  memberships?: {
    plans: {
      duration: 'annual' | 'semi_annual' | 'quarterly' | 'monthly';
      details: {
        usageRestriction: 0 | 1 | 2 | 3 | 4 | 5 | 6;
        price: number;
      }[];
    }[];
    confirmation: {
      url?: string;
      file?: Base64File;
    };
  };
  digitalCertificate?: {
    file?: Base64File;
  };
}

export interface LeadContext {
  selfSignUpId?: string;
  stepName?: StepName;
  lead?: Lead;
  setSelfSignUpId: (id: string) => void;
  setStepName: (stepName: StepName) => void;
  updateLead: (data: Partial<Lead>) => void;
}

export const LeadContext = createContext<LeadContext>({
  setSelfSignUpId: () => null,
  updateLead: () => null,
  setStepName: () => null,
});

interface LeadProviderProps {
  selfSignUpId?: string;
  leadRecord?: Partial<Lead>;
}

export function LeadProvider({
  children,
  selfSignUpId,
  leadRecord = {},
}: PropsWithChildren<LeadProviderProps>) {
  const [id, setSelfSignUpId] = useState<string | undefined>(
    () => selfSignUpId,
  );
  const [stepName, setStepName] = useState<StepName | undefined>();

  const [lead, setLead] = useState<Lead>(leadRecord);

  const value = useMemo(() => {
    const updateLead = (data: Partial<Lead>) => {
      setLead({ ...lead, ...data });
    };

    return {
      selfSignUpId: id,
      lead,
      setSelfSignUpId,
      updateLead,
      setStepName,
      stepName,
    };
  }, [id, lead, stepName]);

  return <LeadContext.Provider value={value}>{children}</LeadContext.Provider>;
}

export function useLead() {
  const value = useContext(LeadContext);
  return value;
}
