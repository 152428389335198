import { Box } from '@gympass/yoga';

import Dropdown from 'components/Dropdown';
import Input from 'components/Input';

import { AvailablePlans } from '../..';
import { useFields } from '../hooks';
import { UsageRestriction } from '../utils/validations';

export interface MembershipDetailsProps {
  duration: keyof AvailablePlans;
}

function MembershipDetails({ duration }: MembershipDetailsProps) {
  const fields = useFields(duration);
  return (
    <Box
      display="grid"
      gap="medium"
      maxWidth={250}
      padding="small"
      border="small"
      borderRadius="small"
      color="elements.lineAndBorders"
    >
      <Dropdown<UsageRestriction> {...fields.usageRestriction} />
      <Input.Price {...fields.price} />
    </Box>
  );
}

export default MembershipDetails;
