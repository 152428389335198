import { BottomSheet, Dialog as YogaDialog } from '@gympass/yoga';
import { ReactNode } from 'react';

import useDeviceViewport from 'hooks/useDeviceViewport';

interface DialogProps {
  header: ReactNode;
  content: ReactNode;
  footer: ReactNode;
  onClose: () => void;
  isOpen: boolean;
}

function Dialog({ header, content, footer, onClose, isOpen }: DialogProps) {
  const { isMobile } = useDeviceViewport();

  const Component = isMobile ? BottomSheet : YogaDialog;

  return (
    <Component isOpen={isOpen} onClose={onClose} zIndex={9999}>
      <Component.Header>{header}</Component.Header>
      <Component.Content>{content}</Component.Content>

      <Component.Footer>{footer}</Component.Footer>
    </Component>
  );
}

export default Dialog;
