import { Input } from '@gympass/yoga';
import { useController } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import { useLocalization } from 'providers/LocalizationProvider';

import { InputProps } from '..';
import { resolveMaskProperties } from './utils';

function CustomInput(props: Record<string, unknown>) {
  return <Input {...props} inputMode="decimal" full />;
}

function InputPrice({
  controllerOptions,
  label,
  helper,
  full = true,
  cleanable = false,
  onClean,
}: InputProps) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController(controllerOptions);
  const localization = useLocalization();

  return (
    <NumericFormat
      label={label}
      helper={helper}
      value={value}
      decimalScale={2}
      allowNegative={false}
      onChange={onChange}
      customInput={CustomInput}
      allowedDecimalSeparators={['.', ',']}
      {...resolveMaskProperties(localization)}
      cleanable={cleanable}
      onClean={onClean}
      error={error?.message}
      full={full}
      inputMode="decimal"
    />
  );
}

export default InputPrice;
