import { theme } from '@gympass/yoga';
import styled, { css } from 'styled-components';

import { LoadingProps } from './Loading';

const { colors, spacing } = theme;

function resolveSize({ contained }: LoadingProps) {
  return contained ? 6 : spacing.xxsmall;
}

export const Container = styled.div<LoadingProps>`
  display: flex;
  gap: ${spacing.xsmall}px;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${({ contained }) =>
    contained
      ? css`
          height: 100%;
        `
      : css`
          height: 100svh;
          height: 100vh;
        `};

  span {
    position: relative;
    width: ${resolveSize}px;
    height: ${resolveSize}px;
    border-radius: 100%;
    background-color: ${colors.light};
    color: ${colors.light};

    &:first-child {
      animation: flashing 1.1s ease-in-out infinite reverse;
      animation-delay: 0s;
    }

    &:nth-child(2) {
      animation: flashing 1.1s ease-in-out infinite reverse;
      animation-delay: 0.5s;
    }

    &:last-child {
      animation: flashing 1.1s ease-in-out infinite reverse;
      animation-delay: 0.8s;
    }
  }

  @keyframes flashing {
    0% {
      background-color: ${colors.light};
    }

    66% {
      transform: scale(1.5);
    }

    100% {
      background-color: ${colors.light};
      filter: brightness(50%);
    }
  }
`;
