export function validateRUT(rut: string): boolean {
  const body = rut.slice(0, -1);
  const checkDigit = rut.slice(-1).toUpperCase();

  let multiplier = 2;
  const sum = Array.from(body)
    .reverse()
    .map(d => parseInt(d, 10))
    .reduce((total: number, digit) => {
      const result = total + digit * multiplier;
      multiplier = multiplier % 7 === 0 ? 2 : multiplier + 1;
      return result;
    }, 0);

  const mod11 = 11 - (sum % 11);
  if (mod11 === 11) {
    return checkDigit === '0';
  }
  if (mod11 === 10) {
    return checkDigit === 'K';
  }
  return checkDigit === mod11.toString();
}
