import Input from 'components/Input';

import { useFields } from './hooks/useFields';

function Form() {
  const fields = useFields();

  return <Input.File {...fields.file} />;
}

export default Form;
