import { Box, Button, Text } from '@gympass/yoga';
import { ExternalLink } from '@gympass/yoga-icons';

import Dialog from 'components/Dialog';
import { useLocalization } from 'providers/LocalizationProvider';

import * as S from './GoogleDialog.styles';

interface GoogleDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
}

function GoogleDialog({ isOpen, onClose, onAccept }: GoogleDialogProps) {
  const { t } = useLocalization('address_page');

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      header={<Text.H4>{t('card.google_details.modal.title')}</Text.H4>}
      content={
        <Box textAlign="left">
          <Text.Medium>{t('card.google_details.modal.subtitle')}</Text.Medium>
          <S.List>
            <li>
              <Text as="span">{t('card.google_details.modal.reason_1')}</Text>
            </li>
            <li>
              <Text as="span">{t('card.google_details.modal.reason_2')}</Text>
            </li>
            <li>
              <Text as="span">{t('card.google_details.modal.reason_3')}</Text>
            </li>
            <li>
              <Text as="span">{t('card.google_details.modal.reason_4')}</Text>
            </li>
          </S.List>
        </Box>
      }
      footer={
        <>
          <Button icon={ExternalLink} onClick={onAccept}>
            {t('card.google_details.modal.register.button')}
          </Button>
          <Button.Outline onClick={onClose}>
            {t('card.google_details.modal.go_back.button')}
          </Button.Outline>
        </>
      }
    />
  );
}

export default GoogleDialog;
