import { Box, Text } from '@gympass/yoga';
import { ExternalLink } from '@gympass/yoga-icons';

import Input from 'components/Input';
import useEventData from 'pages/Signup/hooks/useEventData';
import { useEventTracking } from 'providers/EventTrackingProvider';
import { useLocalization } from 'providers/LocalizationProvider';
import { goToAboutW9Form } from 'utils/redirects';

import * as S from './TaxInfo.styles';
import { useFields } from './hooks';

function AboutW9() {
  const { t } = useLocalization('business_details_page.tax_id');
  const { recordClick } = useEventTracking();
  const { category, contextData } = useEventData();

  const onClick = () => {
    recordClick({
      category,
      label: 'learn_about_w9',
      contextData,
    });

    goToAboutW9Form();
  };

  return (
    <>
      <Text.Small marginBottom="xxsmall">{t('subtitle_us')}</Text.Small>
      <S.Link
        icon={ExternalLink}
        role="link"
        type="button"
        small
        onClick={onClick}
      >
        {t('cta_us')}
      </S.Link>
    </>
  );
}

function TaxInfo() {
  const { country, t } = useLocalization('business_details_page.tax_id');
  const { taxId, w9 } = useFields();

  const isFromUS = country === 'us';

  return (
    <Box>
      <Text.Small fontWeight="medium" marginBottom="small">
        {t('title_us').toUpperCase()}
      </Text.Small>
      {isFromUS && <AboutW9 />}
      <Box marginBottom="large">
        <Input.TaxID {...taxId} />
      </Box>
      {isFromUS && <Input.File {...w9} />}
    </Box>
  );
}

export default TaxInfo;
