import { DropdownProps } from 'components/Dropdown';
import { UseLocalization } from 'providers/LocalizationProvider';
import { gympassCountries } from 'providers/LocalizationProvider/utils';

const FIRST_NAME_MAX_LENGTH = 40;
const LAST_NAME_MAX_LENGTH = 80;

export function fullNameMask(value: string) {
  const splitedName = value.split(' ');
  const maskedNames = splitedName.map((name, index) => {
    if (index === 0) {
      return name.slice(0, FIRST_NAME_MAX_LENGTH);
    }

    if (index === splitedName.length - 1) {
      return name.slice(0, LAST_NAME_MAX_LENGTH);
    }

    return name;
  });

  return maskedNames.join(' ');
}

export function validateFullName(
  input: string,
  translator: UseLocalization['t'],
) {
  const [, lastName] = input.split(' ');

  return !lastName || lastName.length < 1
    ? translator('input.full_name.error_message')
    : true;
}

export type CountryDropdown = DropdownProps<(typeof gympassCountries)[number]>;

export function resolveCountryOptions(
  initialCountry: UseLocalization['country'],
  translator: UseLocalization['t'],
): CountryDropdown['options'] {
  return gympassCountries.map(country => ({
    label: translator(`input.business_country.option.${country}`),
    value: country,
    selected: country === initialCountry,
  }));
}

export function onCountrySelect(setCountry: UseLocalization['setCountry']) {
  return ({ value }: CountryDropdown['options'][number]) => {
    setCountry(value);
  };
}
