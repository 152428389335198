import { Input } from '@gympass/yoga';
import { Autocomplete } from '@mui/material';
import { SyntheticEvent } from 'react';
import { useController } from 'react-hook-form';

import withYogaMUITheme from 'hocs/withYogaMUITheme';
import useGoogleAutocomplete from 'hooks/useGoogleAutocomplete';

import { InputProps } from '..';

export interface InputGooglePlaceProps extends InputProps {
  noResults?: string | React.ReactNode;
}

function InputGooglePlace({
  label,
  helper,
  noResults,
  controllerOptions,
}: InputGooglePlaceProps) {
  const {
    fieldState: { error },
  } = useController(controllerOptions);

  const { value, options, handleChange, handleSelect, handleClean, loading } =
    useGoogleAutocomplete();

  const onInputChange = (
    _event: SyntheticEvent,
    input: string,
    reason: string,
  ) => {
    if (reason === 'input') {
      handleChange(input);
      return;
    }

    if (reason === 'reset') {
      handleSelect(input);
    }
  };

  return (
    <Autocomplete
      options={options}
      onInputChange={onInputChange}
      filterOptions={o => o}
      inputValue={value}
      loading={loading}
      noOptionsText={noResults}
      renderInput={params => (
        <div ref={params.InputProps.ref}>
          <Input
            {...params.inputProps}
            helper={helper}
            label={label}
            full
            onClean={handleClean}
            error={error?.message}
          />
        </div>
      )}
    />
  );
}

export default withYogaMUITheme<InputGooglePlaceProps>(InputGooglePlace);
