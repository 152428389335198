import allActivities from 'assets/json/activities.json';
import {
  Activities,
  CategorizedActivity,
} from 'pages/Signup/Step/steps/Activities';
import { useTranslationByCountry } from 'providers/LocalizationProvider';

function useActivities() {
  const translate = useTranslationByCountry();

  const categorizeSelectedActivities = (selectedActivities: Activities) => {
    return selectedActivities.reduce<CategorizedActivity[]>(
      (groupedActivities, activity) => {
        const categoryKey = activity.category_key;
        const categoryFound = groupedActivities?.find(
          item => item.category_key === categoryKey,
        );
        if (categoryFound) {
          categoryFound.activities = [...categoryFound.activities, activity];
          return groupedActivities;
        }
        return [
          ...groupedActivities,
          {
            category_key: categoryKey,
            activities: [activity],
          },
        ];
      },
      [
        {
          category_key: selectedActivities[0].category_key || '',
          activities: [],
        },
      ],
    );
  };

  const categorizeSelectedActivitiesById = (selectedActivities: string[]) => {
    return allActivities.filter(activity => {
      return selectedActivities.includes(activity.id);
    });
  };

  const resolveActivities = (activities: Activities) => {
    const categorizedActivities = categorizeSelectedActivities(activities);

    const { activitiesIds, hasOtherCategory, productName } =
      categorizedActivities.reduce<{
        productName: string;
        activitiesIds: string[];
        hasOtherCategory: boolean;
        activitiesQuantity: number;
      }>(
        (activitiesAccumulator, categorizedActivity) => {
          const ids = categorizedActivity.activities.reduce(
            (idsAcc, item) =>
              idsAcc.includes(item.id) ? idsAcc : [...idsAcc, item.id],
            activitiesAccumulator.activitiesIds,
          );

          const currentActivitiesQuantity =
            categorizedActivity.activities.length;

          const currentHasMoreActivities =
            currentActivitiesQuantity >
            activitiesAccumulator.activitiesQuantity;

          const isOtherCategory =
            categorizedActivity.category_key ===
            'partner_activities.others_category';

          const nextActivitiesAccumulator =
            currentHasMoreActivities && !isOtherCategory
              ? {
                  ...activitiesAccumulator,
                  activitiesIds: ids,
                  activitiesQuantity: currentActivitiesQuantity,
                  productName: translate(categorizedActivity.category_key),
                }
              : { ...activitiesAccumulator, activitiesIds: ids };

          if (isOtherCategory) {
            return {
              ...nextActivitiesAccumulator,
              hasOtherCategory: true,
            };
          }

          return nextActivitiesAccumulator;
        },
        {
          productName: '',
          activitiesIds: [],
          hasOtherCategory: false,
          activitiesQuantity: 0, // Numbers of activities for the category with most activities
        },
      );

    if (productName && categorizedActivities.length >= 2) {
      return {
        activitiesIds,
        productName: `${productName} ${translate(
          'partner_activities.other_activities.products_name',
        )}`,
      };
    }

    if (hasOtherCategory) {
      return {
        activitiesIds,
        productName: translate(
          'partner_activities.other_activities.product_name',
        ),
      };
    }

    return {
      activitiesIds,
      productName,
    };
  };

  const filterSelectedActivites = (
    id: string,
    categoryToBeFiltered: string,
    selectedActivities: Activities,
  ) => {
    return selectedActivities.filter(activity => {
      if (activity.category_key === categoryToBeFiltered) {
        return activity.id !== id;
      }
      return activity;
    });
  };

  return {
    categorizeSelectedActivitiesById,
    categorizeSelectedActivities,
    initialActivities: allActivities,
    filterSelectedActivites,
    resolveActivities,
  };
}
export default useActivities;
