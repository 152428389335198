import { theme } from '@gympass/yoga';
import styled from 'styled-components';

const { spacing, fontSizes, lineHeights } = theme;

export const List = styled.ul`
  margin-top: ${spacing.large}px;

  li {
    list-style-type: disc;
    list-style-position: inside;
    font-size: ${fontSizes.medium}px;
    line-height: ${lineHeights.medium}px;
    padding-bottom: ${spacing.small}px;
  }
`;
