function calcChecker(numbers: string) {
  let sum = 0;
  const { length } = numbers;
  let pos = length - 7;
  for (let i = length; i >= 1; i -= 1) {
    sum += Number.parseInt(numbers.charAt(length - i), 10) * pos;
    pos -= 1;
    if (pos < 2) pos = 9;
  }
  return sum % 11 < 2 ? 0 : 11 - (sum % 11);
}

export function validateCNPJ(cnpj: string) {
  const cnpjNumbers = cnpj.replace(/[^\d]+/g, '');

  if (cnpjNumbers === '') return false;

  if (cnpjNumbers.length !== 14) return false;

  if (
    cnpjNumbers === '00000000000000' ||
    cnpjNumbers === '11111111111111' ||
    cnpjNumbers === '22222222222222' ||
    cnpjNumbers === '33333333333333' ||
    cnpjNumbers === '44444444444444' ||
    cnpjNumbers === '55555555555555' ||
    cnpjNumbers === '66666666666666' ||
    cnpjNumbers === '77777777777777' ||
    cnpjNumbers === '88888888888888' ||
    cnpjNumbers === '99999999999999'
  ) {
    return false;
  }

  let numbers = cnpjNumbers.substring(0, 12);
  const checkers = cnpjNumbers.substring(12);
  let result = calcChecker(numbers);
  if (result !== Number.parseInt(checkers.charAt(0), 10)) return false;

  numbers = cnpjNumbers.substring(0, 13);
  result = calcChecker(numbers);
  if (result !== Number.parseInt(checkers.charAt(1), 10)) return false;

  return true;
}
