import { Banner } from '@gympass/yoga';
import { AlertTriangle } from '@gympass/yoga-icons';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import useRecordFormErrorView from 'hooks/useRecordFormErrorView';
import useEventData from 'pages/Signup/hooks/useEventData';
import { useEventTracking } from 'providers/EventTrackingProvider';
import { useFlag, useFlags } from 'providers/FlagsProvider';
import { useLead } from 'providers/LeadProvider';
import { useLocalization } from 'providers/LocalizationProvider';
import { Country } from 'providers/LocalizationProvider/utils';
import { useSnackbar } from 'providers/SnackbarProvider';
import { useSteps } from 'providers/StepsProvider';
import { useCreateLead } from 'services/partnersSelfSignup';

import * as S from './Form.styles';
import { useFields } from './hooks/useFields';
import {
  goToOldForm,
  requestEmailValidation,
  resolvePartialLead,
} from './utils/handlers';
import { CountryDropdown } from './utils/validations';

export interface RegisterFormValues {
  fullName: string;
  legalName: string;
  businessEmail: string;
  country: CountryDropdown['options'][number]['value'];
}

function Form() {
  const { t, ...localization } = useLocalization('register_page');
  const methods = useForm<RegisterFormValues>({
    defaultValues: {
      fullName: '',
      legalName: '',
      businessEmail: '',
      country: localization.country,
    },
  });
  const fields = useFields();
  const { lead, updateLead, setSelfSignUpId } = useLead();
  const { next, updateLastStepSubmitted } = useSteps();
  const { mutateAsync } = useCreateLead();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { recordClick, recordErrorView } = useEventTracking();
  const { category, contextData } = useEventData();
  const flags = useFlags();
  const { country } = useLocalization();
  const { isEnabled } = useFlag({
    name: 'partners.self_signup.country',
    context: { userId: country },
    fallbackValue: true,
  });

  useRecordFormErrorView<RegisterFormValues>(methods);

  const handleSubmit = async (values: RegisterFormValues) => {
    setLoading(true);

    const shouldBeRedirected = !isEnabled;

    const trackingValues = {
      category,
      contextData: {
        formValues: values,
        redirected: shouldBeRedirected,
        ...contextData,
      },
    };

    recordClick({
      ...trackingValues,
      label: 'continue',
    });

    if (shouldBeRedirected) {
      goToOldForm(values.country as Exclude<Country, 'br'>);
      return;
    }

    try {
      const submittedLeadData = resolvePartialLead(values, localization);

      if (flags.email_validation) {
        requestEmailValidation(submittedLeadData);
        return;
      }

      const {
        selfSignUpId,
        lead: { id },
      } = await mutateAsync({
        step: 1,
        isLastStep: false,
        lastStepSubmitted: updateLastStepSubmitted(),
        lead: { ...lead, ...submittedLeadData },
      });

      setSelfSignUpId(selfSignUpId);
      updateLead({ id, ...submittedLeadData });
      next();
    } catch {
      recordErrorView({
        ...trackingValues,
        label: 'something_went_wrong',
      });

      showSnackbar({ message: t('generic_error') });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <S.Form
        onSubmit={methods.handleSubmit(handleSubmit)}
        aria-label={t('form.aria_label')}
      >
        <Dropdown {...fields.country} />
        {!isEnabled && (
          <Banner
            role="alert"
            icon={AlertTriangle}
            variant="attention"
            message={t('business_country.redirect_banner')}
          />
        )}
        <Input {...fields.fullName} />
        <Input {...fields.legalName} />
        <Input.Email {...fields.businessEmail} />
        <Button
          full
          loading={loading}
          ariaLabel={t('forward_button.aria_label')}
        >
          {t('forward_button')}
        </Button>
      </S.Form>
    </FormProvider>
  );
}

export default Form;
