import { Buffer } from 'buffer';
import forge from 'node-forge';

import { fileToArrayBuffer } from './file';

const preparePDF = (pdf: ArrayBuffer) => {
  if (Buffer.isBuffer(pdf)) return pdf;
  return Buffer.from(pdf);
};

const mapEntityAtrributes = (attrs: forge.pki.CertificateField[]) =>
  attrs.reduce((agg, { name, value }) => {
    if (!name) return agg;
    return {
      ...agg,
      name: value as string,
    };
  }, {});

const extractSingleCertificateDetails = (cert: forge.pki.Certificate) => {
  const { issuer, subject, validity } = cert;
  return {
    issuedBy: mapEntityAtrributes(issuer.attributes),
    issuedTo: mapEntityAtrributes(subject.attributes),
    validityPeriod: validity,
    pemCertificate: forge.pki.certificateToPem(cert),
  };
};

const extractCertificatesDetails = (certs: forge.pki.Certificate[]) =>
  certs.map(extractSingleCertificateDetails).map((cert, i) => {
    if (i) return cert;
    return {
      clientCertificate: true,
      ...cert,
    };
  });

const getByteRange = (pdfBuffer: Buffer) => {
  const byteRangeStrings = pdfBuffer
    .toString()
    .match(
      /\/ByteRange\s*\[{1}\s*(?:(?:\d*|\/\*{10})\s+){3}(?:\d+|\/\*{10}){1}\s*\]{1}/g,
    );
  if (!byteRangeStrings) {
    return null;
  }

  const strByteRanges = byteRangeStrings.map(brs =>
    brs.match(/[^[\s]*(?:\d|\/\*{10})/g),
  );

  const byteRanges = strByteRanges.map(n => n?.map(Number));

  return byteRanges;
};

const extractSignature = (pdfBuffer: Buffer) => {
  const byteRanges = getByteRange(pdfBuffer);
  if (!byteRanges) {
    return null;
  }
  const lastIndex = byteRanges.length - 1;
  const lastRange = byteRanges[lastIndex];
  if (!lastRange) {
    return null;
  }

  const endOfByteRange = lastRange[2] + lastRange[3];

  if (pdfBuffer.length > endOfByteRange) {
    return null;
  }

  const signatureStr: string[] = [];
  byteRanges.forEach(byteRange => {
    if (!byteRange) return;

    const signatureHex = pdfBuffer
      .subarray(byteRange[0] + byteRange[1] + 1, byteRange[2])
      .toString('latin1');
    signatureStr.push(Buffer.from(signatureHex, 'hex').toString('latin1'));
  });

  return signatureStr;
};

const getMessageFromSignature = (signature: string) => {
  const p7Asn1 = forge.asn1.fromDer(signature);
  return forge.pkcs7.messageFromAsn1(p7Asn1);
};

const getCertificatesInfoFromPDF = (pdf: ArrayBuffer) => {
  const pdfBuffer = preparePDF(pdf);
  const signatureStr = extractSignature(pdfBuffer);
  return signatureStr?.map(signature => {
    const { certificates } = getMessageFromSignature(
      signature,
    ) as forge.pkcs7.Captured<forge.pkcs7.PkcsSignedData>;
    return extractCertificatesDetails(certificates);
  });
};

const verifyPDF = async (file: File) => {
  const buffer = await fileToArrayBuffer(file);
  const certs = getCertificatesInfoFromPDF(buffer);

  return certs && certs.length > 0;
};

export default verifyPDF;
