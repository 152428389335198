import { FormProvider, useForm } from 'react-hook-form';

import Loading from 'components/Loading';
import StepTemplate from 'components/StepTemplate';
import useRecordFormErrorView from 'hooks/useRecordFormErrorView';
import useEventData from 'pages/Signup/hooks/useEventData';
import useOptimizely from 'pages/Signup/hooks/useOptimizely';
import { useEventTracking } from 'providers/EventTrackingProvider';
import { useFlag } from 'providers/FlagsProvider';
import { useLead } from 'providers/LeadProvider';
import { useLocalization } from 'providers/LocalizationProvider';
import { useSnackbar } from 'providers/SnackbarProvider';
import { useSteps } from 'providers/StepsProvider';
import { useUpdateLead } from 'services/partnersSelfSignup';
import { generateBase64File } from 'utils/file';

import Form from './Form';
import { resolvePlans } from './utils';

export type PricingFormValues = Record<string, string | number> & {
  url: string;
  file: File;
};

function Pricing() {
  const { t } = useLocalization();
  const { lead, selfSignUpId, updateLead } = useLead();
  const { showSnackbar } = useSnackbar();
  const { next, updateLastStepSubmitted, current } = useSteps();
  const { mutateAsync, isLoading } = useUpdateLead();
  const { recordClick, recordErrorView } = useEventTracking();
  const { category, contextData } = useEventData();
  const methods = useForm<PricingFormValues>();
  const { isEnabled, isLoading: isFlagLoading } = useFlag({
    name: 'partners.self_signup.pricing_step.business_type',
    fallbackValue: false,
    context: {
      userId: lead?.businessType,
    },
  });

  const { isOnExperiment } = useOptimizely();

  const isGymFacility = Boolean(isEnabled) && lead?.businessType === 'gym'; // this check (lead?.businessType === 'gym') will be useful when the feature toogle is removed due to be a rollout feature toggle and then the flow is need to work differently for gym business type;

  useRecordFormErrorView<PricingFormValues>(methods);

  const onSubmit = async (values: PricingFormValues) => {
    const formValues = isGymFacility
      ? { file: values.file, [`${values.duration}-price`]: values.price }
      : values;

    const trackingValues = {
      category,
      contextData: {
        formValues,
        ...contextData,
      },
    };

    recordClick({
      ...trackingValues,
      label: 'continue',
    });

    try {
      const { url, file, ...pricing } = values;

      if (!Object.keys(pricing).length) {
        showSnackbar({ message: t('pricing_page.min_plans_error') });
        return;
      }

      const memberships = {
        plans: resolvePlans(pricing, isGymFacility),
        confirmation: {
          url,
          file: await generateBase64File(file),
        },
      };

      await mutateAsync({
        step: current,
        isLastStep: !isOnExperiment,
        selfSignUpId: selfSignUpId as string,
        lastStepSubmitted: updateLastStepSubmitted(),
        lead: { ...lead, w9: undefined, memberships },
      });
      updateLead({ memberships });
      next();
    } catch {
      recordErrorView({
        ...trackingValues,
        label: 'something_went_wrong',
      });
      showSnackbar({ message: t('generic_error') });
    }
  };

  return (
    <FormProvider {...methods}>
      <StepTemplate<PricingFormValues>
        formAriaLabel={t('pricing_page.form.aria_label')}
        title={t('pricing_page.title')}
        onSubmit={onSubmit}
        loading={isLoading}
      >
        {isFlagLoading ? (
          <Loading contained />
        ) : (
          <Form isGymFacility={isGymFacility} />
        )}
      </StepTemplate>
    </FormProvider>
  );
}

export default Pricing;
