import { Box, Button, Text } from '@gympass/yoga';
import { Download, Info } from '@gympass/yoga-icons';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import IdentityDeclarationPtPdf from 'assets/docs/identity-declaration-pt.pdf';
import StepTemplate from 'components/StepTemplate';
import { useLead } from 'providers/LeadProvider';
import { useLocalization } from 'providers/LocalizationProvider';
import { useSnackbar } from 'providers/SnackbarProvider';
import { useSteps } from 'providers/StepsProvider';
import { useUpdateLead } from 'services/partnersSelfSignup';
import { generateBase64File } from 'utils/file';

import * as S from './DigitalCertificate.styles';
import Form from './Form';
import HowToSignDialog from './components/HowToSignDialog';
import LearnMoreDialog from './components/LearnMoreDialog';

interface DigitalCertificateFormValues {
  file: File;
}

function DigitalCertificate() {
  const { t } = useLocalization('digital_certificate_page');
  const { lead, selfSignUpId, updateLead } = useLead();
  const { mutateAsync, isLoading } = useUpdateLead();
  const methods = useForm<DigitalCertificateFormValues>();

  const [learnMoreDialogOpen, setLearnMoreDialogOpen] = useState(false);
  const [howToSignDialogOpen, setHowToSignDialogOpen] = useState(false);

  const { showSnackbar } = useSnackbar();
  const { next, updateLastStepSubmitted, current } = useSteps();

  const onSubmit = async (values: DigitalCertificateFormValues) => {
    try {
      const { file } = values;

      const digitalCertificate = {
        file: await generateBase64File(file),
      };

      await mutateAsync({
        step: current,
        isLastStep: true,
        selfSignUpId: selfSignUpId as string,
        lastStepSubmitted: updateLastStepSubmitted(),
        lead: {
          ...lead,
          memberships: undefined,
          digitalCertificate,
        },
      });
      updateLead({ digitalCertificate });
      next();
    } catch {
      showSnackbar({ message: t('generic_error') });
    }
  };

  const toggleLearnMoreDialogOpen = () =>
    setLearnMoreDialogOpen(!learnMoreDialogOpen);

  const toggleHowToSignDialogOpen = () =>
    setHowToSignDialogOpen(!howToSignDialogOpen);

  return (
    <FormProvider {...methods}>
      <StepTemplate<DigitalCertificateFormValues>
        formAriaLabel={t('title')}
        title={t('title')}
        onSubmit={onSubmit}
        loading={isLoading}
      >
        <S.Container>
          <Box>
            <Text.Medium mb="medium">{t('subtitle')}</Text.Medium>
            <Button.Link icon={Info} onClick={toggleLearnMoreDialogOpen}>
              {t('learn_more_button')}
            </Button.Link>
          </Box>
          <S.Panel>
            <Box d="flex" alignItems="center" gap="xxsmall">
              <S.TitleNumber>
                <Text.Bold color="vibin">1</Text.Bold>
              </S.TitleNumber>
              <Text.Medium>{t('card_1.title')}</Text.Medium>
            </Box>
            <Text.Small>{t('card_1.subtitle')}</Text.Small>
            <S.ButtonContainer>
              <Button.Outline
                icon={Download}
                href={IdentityDeclarationPtPdf}
                download="declaracao_de_identidade"
              >
                {t('card_1.download_button')}
              </Button.Outline>
              <Button.Link icon={Info} onClick={toggleHowToSignDialogOpen}>
                {t('card_1.how_to_sign_button')}
              </Button.Link>
            </S.ButtonContainer>
          </S.Panel>
          <S.Panel>
            <Box d="flex" alignItems="center" gap="xxsmall">
              <S.TitleNumber>
                <Text.Bold color="vibin">2</Text.Bold>
              </S.TitleNumber>
              <Text.Medium>{t('card_2.title')}</Text.Medium>
            </Box>
            <Text.Small>{t('card_2.subtitle')}</Text.Small>
            <Form />
          </S.Panel>
        </S.Container>
        <LearnMoreDialog
          isOpen={learnMoreDialogOpen}
          closeDialog={toggleLearnMoreDialogOpen}
        />
        <HowToSignDialog
          isOpen={howToSignDialogOpen}
          closeDialog={toggleHowToSignDialogOpen}
        />
      </StepTemplate>
    </FormProvider>
  );
}

export default DigitalCertificate;
