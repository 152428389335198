import { Input as YogaInput } from '@gympass/yoga';
import { ChangeEvent } from 'react';
import { useController } from 'react-hook-form';

import { InputProps } from '..';

const WEBSITE_VALIDATION_REGEX =
  /^(https?:\/\/(?!www\.)|https?:\/\/www\.)?([\w-]+\.)+([\w]+)((\/[\w!~'().;?:&=+$,%#\\-]*)*)?$/;

export interface InputWebsiteProps extends InputProps {
  invalidMessage: string;
  maxLength?: number;
  hideMaxLength?: boolean;
}

function InputWebsite({
  controllerOptions,
  label,
  helper,
  invalidMessage,
  maxLength = 255,
  hideMaxLength = true,
  full = true,
  cleanable = false,
  onClean,
}: InputWebsiteProps) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    ...controllerOptions,
    rules: {
      ...controllerOptions.rules,
      pattern: {
        value: WEBSITE_VALIDATION_REGEX,
        message: invalidMessage,
      },
    },
  });

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    onChange(target.value.toLowerCase());
  };

  return (
    <YogaInput
      value={value}
      label={label}
      helper={helper}
      maxLength={maxLength}
      hideMaxLength={hideMaxLength}
      full={full}
      onChange={handleChange}
      error={error?.message}
      cleanable={cleanable}
      onClean={onClean}
      inputMode="url"
    />
  );
}

export default InputWebsite;
