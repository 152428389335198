import { theme } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';
import styled from 'styled-components';

const { spacing, colors } = theme;

type HeaderProps = {
  hasBackground: boolean;
};

export const desktopHeaderHeight = 120;
export const mobileHeaderHeight = 108;

export const Container = styled.header<HeaderProps>`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: ${spacing.small}px ${spacing.medium}px;
  gap: ${spacing.medium}px;
  background-color: ${({ hasBackground }) =>
    hasBackground ? colors.white : 'transparent'};

  ${media.md`
    position: sticky;
    padding: ${spacing.xlarge}px ${spacing.xxxlarge}px ${spacing.small}px;
    gap: ${spacing.xlarge}px;
    background-color: ${colors.white};
  `}
`;
