import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

interface GooglePlacesContext {
  sessionToken?: google.maps.places.AutocompleteSessionToken;
  autocompleteService?: google.maps.places.AutocompleteService;
  placesService?: google.maps.places.PlacesService;
  place?: google.maps.places.PlaceResult;
  setPlace?: Dispatch<
    SetStateAction<google.maps.places.PlaceResult | undefined>
  >;
}

const GooglePlacesContext = createContext<GooglePlacesContext>({});

function initGoogleServices() {
  return {
    autocompleteService: new google.maps.places.AutocompleteService(),
    placesService: new google.maps.places.PlacesService(
      document.createElement('div'),
    ),
  };
}

function generateSessionToken() {
  return new google.maps.places.AutocompleteSessionToken();
}

function GooglePlacesProvider({ children }: PropsWithChildren) {
  const [services] = useState(initGoogleServices);
  const [sessionToken] = useState(generateSessionToken());
  const [place, setPlace] = useState<google.maps.places.PlaceResult>();

  const values = useMemo(
    () => ({ sessionToken, place, setPlace, ...services }),
    [sessionToken, place, setPlace, services],
  );

  return (
    <GooglePlacesContext.Provider value={values}>
      {children}
    </GooglePlacesContext.Provider>
  );
}

export function useGooglePlacesServices() {
  const context = useContext(GooglePlacesContext);
  return context;
}

export default GooglePlacesProvider;
