import { Button } from '@gympass/yoga';
import styled from 'styled-components';

export const Link = styled(Button.Text)`
  padding: 0;
`;

export const IconButton = styled(Button.Icon)`
  height: auto;
`;
