import { FontLoader, ThemeProvider, v3theme } from '@gympass/yoga';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useLayoutEffect } from 'react';

import { datadog } from 'monitoring';
import Signup from 'pages/Signup';
import CookiesConsentManagerProvider from 'providers/CookiesConsentManagerProvider';
import { EventTrackingProvider } from 'providers/EventTrackingProvider';
import FlagsProvider from 'providers/FlagsProvider';
import { LocalizationProvider } from 'providers/LocalizationProvider';
import { SnackbarProvider } from 'providers/SnackbarProvider';
import GlobalStyles from 'styles/GlobalStyles';

const client = new QueryClient();

function App() {
  useLayoutEffect(() => {
    datadog.initRUM();
  }, []);

  return (
    <ThemeProvider theme={v3theme}>
      <GlobalStyles />
      <FontLoader />
      <QueryClientProvider client={client}>
        <LocalizationProvider>
          <FlagsProvider>
            <CookiesConsentManagerProvider>
              <EventTrackingProvider>
                <SnackbarProvider>
                  <Signup />
                </SnackbarProvider>
              </EventTrackingProvider>
            </CookiesConsentManagerProvider>
          </FlagsProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
