import { Button } from '@gympass/yoga';
import { useState } from 'react';

import Dialog from 'components/Dialog';
import useDeviceViewport from 'hooks/useDeviceViewport';
import { useLocalization } from 'providers/LocalizationProvider';
import { useSteps } from 'providers/StepsProvider';

import * as S from './RecoveryLeadDialog.styles';

export interface RecoveryLeadDialogProps {
  isOpen: boolean;
}

function RecoveryLeadDialog({ isOpen }: RecoveryLeadDialogProps) {
  const { t } = useLocalization('welcome_back');
  const { isMobile } = useDeviceViewport();
  const { isRebrandingEnabled } = useSteps();

  const [open, setOpen] = useState(isOpen);

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <Dialog
      isOpen={open}
      onClose={closeDialog}
      header={
        <>
          {isRebrandingEnabled ? (
            <S.GirlImage viewBox="60 30 200 250" />
          ) : (
            <S.Image />
          )}
          <S.Title isMobile={isMobile}>{t('title')}</S.Title>
        </>
      }
      content={t('subtitle')}
      footer={
        <Button full onClick={closeDialog}>
          {t('continue')}
        </Button>
      }
    />
  );
}

export default RecoveryLeadDialog;
