import { Button, Text } from '@gympass/yoga';

import GympassLogo from 'components/GympassLogo';
import useDeviceViewport from 'hooks/useDeviceViewport';
import useEventData from 'pages/Signup/hooks/useEventData';
import { useEventTracking } from 'providers/EventTrackingProvider';
import { useLocalization } from 'providers/LocalizationProvider';
import { goToGympassWebsite, goToPartnersPortal } from 'utils/redirects';

import * as S from './Header.styles';

function Header() {
  const { t, language } = useLocalization();
  const { scrollY } = useDeviceViewport();
  const { recordClick } = useEventTracking();
  const { category, contextData } = useEventData();

  const onClick = () => {
    recordClick({
      category,
      label: 'log_in',
      contextData,
    });

    goToPartnersPortal();
  };

  return (
    <S.Header hasBackground={scrollY > 20}>
      <GympassLogo
        label={t('gympass_link.aria_label')}
        onClick={() => goToGympassWebsite(language)}
      />
      <S.Login>
        <Text.Small aria-hidden>
          {t('register_page.already_a_partner')}
        </Text.Small>
        <Button.Outline
          aria-label={t('register_page.log_in.button.aria_label')}
          onClick={onClick}
          small
        >
          {t('register_page.log_in.button')}
        </Button.Outline>
      </S.Login>
    </S.Header>
  );
}

export default Header;
