import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

import Snackbar from './Snackbar.styles';

export interface SnackbarOptions {
  message: string;
  variant?: 'success' | 'failure' | 'info' | 'attention' | 'informative';
  duration?: number;
}

export interface SnackbarContext {
  showSnackbar: (options: SnackbarOptions) => void;
}

export const SnackbarContext = createContext<SnackbarContext>({
  showSnackbar: () => null,
});

export function SnackbarProvider({ children }: PropsWithChildren) {
  const [isOpen, setIsOpen] = useState(false);
  const [snackbarOptions, setSnackbarOptions] = useState<SnackbarOptions>({
    message: '',
    variant: 'failure',
    duration: 5000,
  });

  const closeSnackbar = () => {
    setIsOpen(false);
  };

  const value = useMemo(
    () => ({
      showSnackbar: (options: SnackbarOptions) => {
        setIsOpen(true);
        setSnackbarOptions({
          ...snackbarOptions,
          ...options,
        });
      },
    }),
    [snackbarOptions, setSnackbarOptions],
  );

  const { message, variant, duration } = snackbarOptions;

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <Snackbar
        open={isOpen}
        message={message}
        variant={variant}
        duration={duration}
        onClose={closeSnackbar}
        hideCloseButton
      />
    </SnackbarContext.Provider>
  );
}

export function useSnackbar() {
  const value = useContext(SnackbarContext);
  return value;
}
