import { PropsWithChildren, useMemo } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';

import GympassLogo from 'components/GympassLogo';
import useEventData from 'pages/Signup/hooks/useEventData';
import { useEventTracking } from 'providers/EventTrackingProvider';
import { useSteps } from 'providers/StepsProvider';

import Footer from './Footer';
import Header from './Header';
import * as S from './StepTemplate.style';

export interface StepTemplateProps<FormValues extends FieldValues> {
  title: string;
  formAriaLabel: string;
  disableOnBack?: boolean;
  onSubmit: (values: FormValues) => void;
  loading?: boolean;
}

function StepTemplate<FormValues extends FieldValues>({
  children,
  title,
  formAriaLabel,
  disableOnBack = false,
  onSubmit,
  loading,
}: PropsWithChildren<StepTemplateProps<FormValues>>) {
  const { next, prev } = useSteps();

  const {
    formState: { isDirty },
    getValues,
    handleSubmit,
  } = useFormContext<FormValues>();
  const { recordClick } = useEventTracking();
  const { category, contextData } = useEventData();

  const actions = useMemo(() => {
    const onForward = () => {
      recordClick({
        category,
        label: 'continue',
        contextData: {
          noChanges: true,
          formValues: getValues(),
          ...contextData,
        },
      });
      next();
    };

    const onBack = () => {
      recordClick({ category, label: 'on_back', contextData });
      prev();
    };

    return {
      onForward,
      onBack: disableOnBack ? undefined : onBack,
    };
  }, [
    next,
    prev,
    recordClick,
    category,
    contextData,
    getValues,
    disableOnBack,
  ]);

  return (
    <S.Container>
      <S.SectionTitle>
        <GympassLogo width={80} height={15} />
        <S.Title>{title}</S.Title>
      </S.SectionTitle>
      <div>
        <Header />
        <form
          aria-label={formAriaLabel}
          onSubmit={handleSubmit(isDirty ? onSubmit : actions.onForward)}
        >
          <S.FormFields>{children}</S.FormFields>
          <Footer onBack={actions.onBack} loading={loading} />
        </form>
      </div>
    </S.Container>
  );
}

export default StepTemplate;
