import { ChangeEvent, PropsWithChildren, createContext, useMemo } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';

import {
  ClickEventData,
  useEventTracking,
} from 'providers/EventTrackingProvider';

import * as S from './RadioGroup.styles';

interface RadioGroupContext {
  value: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const RadioGroupContext = createContext<RadioGroupContext | undefined>(
  undefined,
);

export interface RadioGroupProps {
  controllerOptions: UseControllerProps;
  numberOfOptions: number;
  eventData?: ClickEventData;
}

function RadioGroup({
  controllerOptions,
  numberOfOptions,
  children,
  eventData,
}: PropsWithChildren<RadioGroupProps>) {
  const { field } = useController(controllerOptions);
  const { recordClick } = useEventTracking();

  const value = useMemo(() => {
    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (eventData) {
        recordClick({
          ...eventData,
          label: `${controllerOptions.name}_option_${target.value}`,
        });
      }

      field.onChange(target.value);
    };

    return {
      value: field.value,
      handleChange,
    };
  }, [field, recordClick, eventData, controllerOptions.name]);

  return (
    <RadioGroupContext.Provider value={value}>
      <S.Container role="radiogroup" numberOfOptions={numberOfOptions}>
        {children}
      </S.Container>
    </RadioGroupContext.Provider>
  );
}

export default RadioGroup;
