import { Button, Text, theme } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';
import { Img2d11 } from '@gympass/yoga-illustrations';
import { HiFive } from '@yoga-inner/illustrations';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - ${theme.spacing.xhuge}px);
  align-items: center;

  ${media.md`
    flex-direction: row;
    gap: ${theme.spacing.huge}px;
  `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.medium}px;
  text-align: center;

  ${media.md`
    text-align: left;
  `}
`;

export const Title = styled(Text.Display1).attrs({
  as: 'h1',
})`
  margin-bottom: ${theme.spacing.large}px;

  ${media.md`
    font-size: 4.5rem;
  `}
`;

export const Subtitle = styled(Text.H5)`
  margin-bottom: ${theme.spacing.large}px;

  ${media.md`
    width: 26rem;
  `};
`;

export const Image = styled(Img2d11)`
  width: 15rem;
  height: auto;

  ${media.md`
    width: 22rem;
  `};
`;

export const HiFiveImage = styled(HiFive)`
  width: auto;
  height: auto;

  ${media.md`
    width: 22rem;
  `};
`;

export const ExploreButton = styled(Button)`
  width: 90%;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: ${theme.spacing.medium}px;

  ${media.md`
    position: relative;
    margin: 0;
    width: 13rem;
  `};
`;
