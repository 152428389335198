import { Dumbbell, JumpRope, Spa, User } from '@gympass/yoga-icons';
import { useMemo } from 'react';

import { InputNumberProps } from 'components/Input';
import { RadioCardProps, RadioGroupProps } from 'components/RadioGroup';
import useEventData from 'pages/Signup/hooks/useEventData';
import { Lead } from 'providers/LeadProvider';
import { useLocalization } from 'providers/LocalizationProvider';
import { useSnackbar } from 'providers/SnackbarProvider';

import { numberOfLocationsMask } from '../utils/validations';

interface FormFields {
  businessType: RadioGroupProps;
  businessTypeOptions: RadioCardProps<NonNullable<Lead['businessType']>>[];
  numberOfLocations: InputNumberProps;
}

export function useFields() {
  const { t } = useLocalization('partner_type_page');
  const { showSnackbar } = useSnackbar();
  const { category, contextData } = useEventData();

  const businessTypeOptions = useMemo<FormFields['businessTypeOptions']>(
    () => [
      {
        value: 'gym',
        icon: Dumbbell,
        title: t('gym.title'),
        description: t('gym.description'),
        ariaLabel: t('gym.aria_label'),
      },
      {
        value: 'studio',
        icon: JumpRope,
        title: t('studio.title'),
        description: t('studio.description'),
        ariaLabel: t('studio.aria_label'),
      },
      {
        value: 'personal_trainer',
        icon: User,
        title: t('personal_trainer.title'),
        description: t('personal_trainer.description'),
        ariaLabel: t('personal_trainer.aria_label'),
      },
      {
        value: 'wellness_service',
        icon: Spa,
        title: t('wellness_services.title'),
        description: t('wellness_services.description'),
        ariaLabel: t('wellness_services.aria_label'),
      },
    ],
    [t],
  );

  return useMemo<FormFields>(
    () => ({
      businessType: {
        numberOfOptions: businessTypeOptions.length,
        eventData: { category, contextData },
        controllerOptions: {
          name: 'businessType',
          rules: {
            validate: (value: Lead['businessType']) => {
              if (value) return true;
              showSnackbar({ message: t('error_message') });
              return false;
            },
          },
        },
      },
      businessTypeOptions,
      numberOfLocations: {
        label: t('locations.input.label'),
        full: false,
        mask: numberOfLocationsMask,
        controllerOptions: {
          name: 'numberOfLocations',
          shouldUnregister: true,
          rules: {
            required: t('locations.input.error_message'),
            min: {
              value: 1,
              message: t('locations.input.error_invalid'),
            },
          },
        },
      },
    }),
    [t, businessTypeOptions, showSnackbar, category, contextData],
  );
}
