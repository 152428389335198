import { theme } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';
import styled from 'styled-components';

const { spacing, colors } = theme;

export const Panel = styled.div`
  display: flex;
  padding: ${spacing.large}px ${spacing.small}px;
  flex-direction: column;
  gap: ${spacing.large}px;
  border-radius: ${spacing.small}px;
  border: 1px solid ${colors.light};
`;

export const TitleNumber = styled.div`
  display: flex;
  width: ${spacing.large}px;
  height: ${spacing.large}px;
  padding: ${spacing.xxsmall}px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacing.xxsmall}px;
  border-radius: 50%;
  background-color: ${colors.yoga};
`;

export const Container = styled.div`
  display: grid;
  max-width: 656px;
  padding-inline: ${spacing.medium}px;
  margin: ${spacing.xxlarge}px auto;
  gap: ${spacing.large}px;
  ${media.md`
    padding: 0 ${spacing.xxxlarge}px;
    margin: ${spacing.xxxlarge}px auto;
  `};
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${spacing.large}px;
  flex-direction: column;

  ${media.md`
    gap: ${spacing.small}px;
    flex-direction: row;      
  `}
`;
