import { PropsWithChildren } from 'react';

import Loading from 'components/Loading';

import * as S from './Button.styles';

export interface SubmitButtonProps {
  full?: boolean;
  loading?: boolean;
  ariaLabel?: string;
  type?: 'submit' | 'button';
  onClick?: () => void;
}

function Button({
  children,
  ariaLabel,
  full = false,
  loading = false,
  type = 'submit',
  onClick,
}: PropsWithChildren<SubmitButtonProps>) {
  return (
    <S.Button
      $loading={loading}
      type={type}
      aria-label={ariaLabel}
      full={full}
      disabled={loading}
      aria-busy={loading}
      onClick={onClick}
    >
      {loading ? <Loading contained /> : children}
    </S.Button>
  );
}

export default Button;
