import { Button as YogaButton, theme } from '@gympass/yoga';
import styled, { css } from 'styled-components';

const { colors } = theme;

export const Button = styled(YogaButton)<{ $loading?: boolean }>`
  ${({ $loading }) =>
    $loading
      ? css`
          background-color: ${colors.clear};
        `
      : css`
          background-color: ${colors.vibin};
        `};
`;
