import { Box, Button, Text } from '@gympass/yoga';
import { ExternalLink } from '@gympass/yoga-icons';

import Dialog from 'components/Dialog';
import { useLocalization } from 'providers/LocalizationProvider';
import { SERASA_LINK, SERPRO_LINK, SOLUTI_LINK } from 'utils/links';

interface HowToSignDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
}

function HowToSignDialog({ isOpen, closeDialog }: HowToSignDialogProps) {
  const { t } = useLocalization('digital_certificate_page');

  return (
    <Dialog
      isOpen={isOpen}
      onClose={closeDialog}
      header={t('card_1.how_to_sign.title')}
      content={
        <Box
          d="flex"
          gap="xlarge"
          flexDirection="column"
          alignItems="flex-start"
          textAlign="left"
        >
          <Text style={{ whiteSpace: 'break-spaces' }}>
            {t('card_1.how_to_sign.subtitle')}
          </Text>

          <Box
            d="flex"
            gap="large"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Button.Link icon={ExternalLink} href={SERPRO_LINK} target="_blank">
              {t('card_1.how_to_sign.serpro_button')}
            </Button.Link>

            <Button.Link icon={ExternalLink} href={SERASA_LINK} target="_blank">
              {t('card_1.how_to_sign.serasa_button')}
            </Button.Link>
            <Button.Link icon={ExternalLink} href={SOLUTI_LINK} target="_blank">
              {t('card_1.how_to_sign.soluti_button')}
            </Button.Link>
          </Box>
          <Text.Small c="deep">{t('card_1.how_to_sign.footer')}</Text.Small>
        </Box>
      }
      footer={
        <Button full onClick={closeDialog}>
          {t('card_1.how_to_sign.got_it_button')}
        </Button>
      }
    />
  );
}

export default HowToSignDialog;
