import { Box, Chips, Text } from '@gympass/yoga';
import { Close } from '@gympass/yoga-icons';

import { useLocalization } from 'providers/LocalizationProvider';

import { CategorizedActivity } from '../Activities';

interface ActivitiesSectionProps extends CategorizedActivity {
  onRemoveActivity: (id: string, category: string) => void;
}
function ActivitiesSection({
  category_key: categoryKey,
  activities,
  onRemoveActivity,
}: ActivitiesSectionProps) {
  const { t } = useLocalization();

  return (
    <Box
      as="section"
      borderBottom="small"
      paddingVertical="medium"
      borderColor="elements.lineAndBorders"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="large"
      >
        <Text>{t(categoryKey)}</Text>
        {/* here will have an icon that's why it is wrapped by a div */}
      </Box>
      <Box display="flex" flexWrap="wrap" gap="xxsmall">
        {activities.map(({ activity_key: activityKey, id }) => (
          <Chips
            type="button"
            selected
            onClick={() => onRemoveActivity(id, categoryKey)}
            icons={[Close]}
            key={id}
          >
            {t(activityKey)}
          </Chips>
        ))}
      </Box>
    </Box>
  );
}

export default ActivitiesSection;
