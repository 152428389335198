import { UseControllerProps } from 'react-hook-form';

import InputEmail from './Email';
import InputFile from './File';
import InputGooglePlace from './GooglePlace';
import InputNumber from './Number';
import InputPhone from './Phone';
import InputPrice from './Price';
import InputSearch from './Search';
import InputTaxID from './TaxID';
import InputText from './Text';
import InputWebsite from './Website';

export interface InputProps {
  controllerOptions: UseControllerProps;
  label: string;
  helper?: string;
  full?: boolean;
  cleanable?: boolean;
  onClean?: (string: '') => void;
  disabled?: boolean;
}

type InputComponents = {
  Number: typeof InputNumber;
  Email: typeof InputEmail;
  Website: typeof InputWebsite;
  Phone: typeof InputPhone;
  GooglePlace: typeof InputGooglePlace;
  TaxID: typeof InputTaxID;
  Search: typeof InputSearch;
  Price: typeof InputPrice;
  File: typeof InputFile;
};

const Input: typeof InputText & InputComponents = Object.assign(InputText, {
  Number: InputNumber,
  Email: InputEmail,
  Website: InputWebsite,
  Phone: InputPhone,
  GooglePlace: InputGooglePlace,
  TaxID: InputTaxID,
  Search: InputSearch,
  Price: InputPrice,
  File: InputFile,
});

export type { InputEmailProps } from './Email';
export type { InputNumberProps } from './Number';
export type { InputTextProps } from './Text';
export type { InputWebsiteProps } from './Website';
export type { InputPhoneProps } from './Phone';
export type { InputGooglePlaceProps } from './GooglePlace';
export type { InputTaxIDProps } from './TaxID';
export type { InputSearchProps } from './Search';
export type { InputFileProps } from './File';
export default Input;
