import { theme } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';
import styled from 'styled-components';

const { colors, spacing } = theme;

type HeaderProps = {
  hasBackground: boolean;
};

export const headerMobileHeight = 72;
export const headerDesktopHeight = 80;

export const Header = styled.header<HeaderProps>`
  position: sticky;
  top: 0;
  z-index: 1;
  display: grid;
  grid-template: auto / max-content max-content;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${spacing.medium}px;
  background-color: ${({ hasBackground }) =>
    hasBackground ? colors.white : 'transparent'};

  ${media.md`
    padding: ${spacing.large}px ${spacing.medium}px;
    background-color: ${colors.white};
  `};
`;

export const Login = styled.div`
  display: grid;
  align-items: center;
  grid-template: auto / max-content max-content;

  ${media.md`
    gap: ${spacing.small}px;
  `};

  p {
    display: none;

    ${media.md`
      display: block;
    `};
  }
`;
