import { Icon } from '@gympass/yoga';
import { Close, Upload2 } from '@gympass/yoga-icons';
import { MouseEvent, useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { UseControllerProps, useController } from 'react-hook-form';

import ImagePreview from 'components/ImagePreview';

import * as S from './InputFile.styles';

export interface InputFileProps {
  controllerOptions: UseControllerProps;
  labels: {
    label: string;
    helper: string;
    invalidMessage: string;
    update: string;
    remove: string;
  };
  accept?: Record<string, string[]>;
  maxSize?: number;
}

function InputFile({
  controllerOptions,
  labels: { label, helper, invalidMessage, update, remove },
  accept = { 'image/jpeg': ['.jpg', '.jpeg'], 'image/png': ['.png'] },
  maxSize = 5242880,
}: InputFileProps) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController(controllerOptions);
  const { getRootProps, getInputProps, isDragReject, open } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept,
    maxSize,
    onDropAccepted: ([file]) => {
      if (file.type === 'image/jpeg') {
        const fileName = file.name.split('.')[0];
        onChange(
          new File([file], `${fileName}.jpeg`, {
            type: file.type,
          }),
        );
        return;
      }
      onChange(file);
    },
  });

  const clearFile = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      onChange('');
    },
    [onChange],
  );
  const input = useMemo(() => {
    const hasError = isDragReject || error;
    const errorMessage = isDragReject ? invalidMessage : error?.message;

    return {
      label: value?.name ?? label,
      helperText: hasError ? errorMessage : helper,
      hasError,
    };
  }, [value, label, isDragReject, error, helper, invalidMessage]);

  const icon = useMemo(() => {
    const hasFile = !!value;

    return {
      role: hasFile ? 'button' : 'presentation',
      as: hasFile ? Close : Upload2,
      onClick: hasFile ? clearFile : undefined,
      label: hasFile ? remove : undefined,
    };
  }, [value, remove, clearFile]);

  if (/image/.test(value?.type)) {
    return (
      <ImagePreview
        file={value}
        updateText={update}
        updateFile={open}
        removeText={remove}
        removeFile={clearFile}
      >
        <input aria-label={label} {...getInputProps()} />
      </ImagePreview>
    );
  }

  return (
    <S.Container>
      <S.Dragzone {...getRootProps()} role="button" hasError={input.hasError}>
        <S.Label numberOfLines={1}>{input.label}</S.Label>
        <input aria-label={label} {...getInputProps()} />
        <Icon
          role={icon.role}
          as={icon.as}
          onClick={icon.onClick}
          aria-label={icon.label}
          size="large"
          fill="primary"
          mr="xsmall"
        />
      </S.Dragzone>
      <S.Helper hasError={input.hasError}>{input.helperText}</S.Helper>
    </S.Container>
  );
}

export default InputFile;
