import { theme } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';
import styled from 'styled-components';

const { spacing } = theme;

export const Container = styled.section`
  max-width: 656px;
  padding-inline: ${spacing.medium}px;
  margin: ${spacing.xxlarge}px auto;

  ${media.md`
    padding: 0 ${spacing.xxxlarge}px;
    margin: ${spacing.huge}px auto;
  `};
`;
