import { Text, theme } from '@gympass/yoga';
import styled from 'styled-components';

const { colors, spacing, radii, fontSizes } = theme;

export const Container = styled.div`
  min-height: 92px;
`;

export const Dragzone = styled.div<{ hasError: boolean }>`
  display: grid;
  grid-template: auto / 1fr min-content;
  max-height: ${spacing.xxxlarge}px;
  padding: ${spacing.small}px;
  border: 1px solid
    ${({ hasError }) => (hasError ? colors.verve : colors.light)};
  border-radius: ${radii.small}px;
  outline: none;
  cursor: pointer;

  &:hover,
  &:focus-within {
    border: 1px solid
      ${({ hasError }) => (hasError ? colors.verve : colors.stamina)};
  }
`;

export const Label = styled(Text)`
  font-size: ${fontSizes.small}px;
  color: ${colors.deep};
  cursor: pointer;
`;

export const Helper = styled(Text.Tiny)<{ hasError: boolean }>`
  margin-top: ${spacing.xxxsmall}px;
  font-size: ${fontSizes.xsmall};
  color: ${({ hasError }) => (hasError ? colors.verve : colors.deep)};
`;
