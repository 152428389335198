import { Banner, Text } from '@gympass/yoga';
import { AlertCircle } from '@gympass/yoga-icons';

import { useLocalization } from 'providers/LocalizationProvider';

import Confirmation from './Confirmation';
import * as S from './Form.styles';
import MembershipPlan, { MembershipPlanGymFacility } from './MembershipPlan';

export interface AvailablePlans {
  annual: boolean;
  semi_annual: boolean;
  quarterly: boolean;
  monthly: boolean;
}

interface FormProps {
  isGymFacility: boolean;
}

function Form({ isGymFacility }: FormProps) {
  const { t } = useLocalization('pricing_page');

  return (
    <>
      <S.Container>
        <Banner
          icon={AlertCircle}
          marginTop="medium"
          marginBottom="medium"
          variant="informative"
          message={t('section_1.snackbar.description')}
        />
        {isGymFacility ? <MembershipPlanGymFacility /> : <MembershipPlan />}
      </S.Container>
      <S.FullDivider />
      <S.Container>
        <Text.Medium marginBottom="xxsmall" lineHeight="medium">
          {t('section_2.title')}
        </Text.Medium>
        <Text.Small marginBottom="large">{t('section_2.subtitle')}</Text.Small>
        <Confirmation />
      </S.Container>
    </>
  );
}

export default Form;
