import { Input } from '@gympass/yoga';
import { ChangeEvent, ReactNode } from 'react';
import { useController } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { useLocalization } from 'providers/LocalizationProvider';
import { Country } from 'providers/LocalizationProvider/utils';

import { InputProps } from '..';
import { alphanumericsOnly, resolveValidationProperties } from './utils';

export interface InputTaxIDProps extends InputProps {
  invalidMessage: string;
}

interface CustomInputProps
  extends Omit<InputTaxIDProps, 'controllerOptions' | 'invalidMessage'> {
  error?: string;
  inputMode: string;
}

function CustomInput(props: CustomInputProps) {
  return <Input {...props} />;
}

function InputTaxID({
  controllerOptions,
  invalidMessage,
  label,
  helper,
  full = true,
  cleanable = false,
  onClean,
}: InputTaxIDProps) {
  const { country } = useLocalization();
  const { regex, mask, validate } = resolveValidationProperties(
    country as Country,
  );

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    ...controllerOptions,
    rules: {
      ...controllerOptions.rules,
      pattern: {
        value: regex,
        message: invalidMessage,
      },
      validate: (val: string) => {
        if (!validate) return true;
        return validate(val) || invalidMessage;
      },
    },
  });

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    onChange(alphanumericsOnly(target.value.toUpperCase()));
  };

  return (
    <InputMask
      mask={mask}
      maskChar={null}
      value={value}
      onChange={handleChange}
    >
      {
        (() => (
          <CustomInput
            label={label}
            helper={helper}
            full={full}
            cleanable={cleanable}
            onClean={onClean}
            error={error?.message}
            inputMode="search"
          />
        )) as unknown as ReactNode
      }
    </InputMask>
  );
}

export default InputTaxID;
