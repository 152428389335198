import { Banner, theme } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';
import styled from 'styled-components';

const { spacing } = theme;

export const Container = styled.section`
  padding-inline: ${spacing.medium}px;
  margin: ${spacing.xxlarge}px auto;

  ${media.md`
    padding: 0 ${spacing.xxxlarge}px;
    margin-top: ${spacing.huge}px;
  `};

  ${media.xxl`
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  `};
`;

export const Locations = styled.section`
  margin: ${spacing.xxsmall}px 0 ${spacing.xxlarge}px;

  ${media.md`
    order: 1;
    grid-column-start: 1;
    grid-column-end: 4;
    margin: ${spacing.small}px 0 ${spacing.xxlarge}px;
  `};
`;

export const BannerInformative = styled(Banner)`
  margin: 0;
  ${media.md`
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 2;
  `};
`;
