import { DropdownProps } from 'components/Dropdown';
import { Lead } from 'providers/LeadProvider';
import { UseLocalization } from 'providers/LocalizationProvider';

export type UsageRestriction = NonNullable<
  Lead['memberships']
>['plans'][number]['details'][number]['usageRestriction'];

export function resolveUsageRestrictionOptions(
  t: UseLocalization['t'],
): DropdownProps<UsageRestriction>['options'] {
  return [
    {
      value: 0,
      label: t('usage.options.unlimited'),
      selected: false,
    },
    {
      value: 1,
      label: t('usage.options.1_day'),
      selected: false,
    },
    {
      value: 2,
      label: t('usage.options.2_days'),
      selected: false,
    },
    {
      value: 3,
      label: t('usage.options.3_days'),
      selected: false,
    },
    {
      value: 4,
      label: t('usage.options.4_days'),
      selected: false,
    },
    {
      value: 5,
      label: t('usage.options.5_days'),
      selected: false,
    },
    {
      value: 6,
      label: t('usage.options.6_days'),
      selected: false,
    },
  ];
}
