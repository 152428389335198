import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useRef } from 'react';

import { useEventTracking } from 'providers/EventTrackingProvider';
import { useLead } from 'providers/LeadProvider';
import { useSteps } from 'providers/StepsProvider';

import useEventData from '../hooks/useEventData';
import useOptimizely from '../hooks/useOptimizely';
import steps, { stepsWithDigitalCertificateStep } from './steps';

interface StepProps {
  isLeadRecovered: boolean;
}

function Step({ isLeadRecovered }: StepProps) {
  const { current, updateTotalSteps } = useSteps();
  const { contextData } = useEventData();
  const { setStepName, lead } = useLead();
  const { recordPageView, userId } = useEventTracking();
  const { isOnExperiment } = useOptimizely();
  const { Component, name } = isOnExperiment
    ? stepsWithDigitalCertificateStep[current]
    : steps[current];

  const stepName = useRef('empty');

  useEffect(() => {
    if (stepName.current === name) return;
    stepName.current = name;
    setStepName(name);

    const source = new URL(window.location.href).searchParams.get('source');

    recordPageView({
      category: stepName.current,
      label: source ?? 'empty',
      contextData: {
        ...contextData,
        redirected_on_email_validation: isLeadRecovered,
      },
    });
  }, [recordPageView, name, contextData, setStepName, isLeadRecovered]);

  useEffect(() => {
    if (userId || lead?.id) {
      datadogRum.setUser({
        id: userId,
        leadId: lead?.id,
      });
    }
  }, [lead?.id, userId]);

  useEffect(() => {
    if (isOnExperiment) {
      updateTotalSteps(Object.keys(stepsWithDigitalCertificateStep).length);
    }
  }, [isOnExperiment, updateTotalSteps]);

  return (
    <div id={name}>
      <Component />
    </div>
  );
}

export default Step;
