import * as S from './Loading.styles';

export interface LoadingProps {
  contained?: boolean;
}

function Loading({ contained = false }: LoadingProps) {
  return (
    <S.Container role="status" aria-busy contained={contained}>
      <span />
      <span />
      <span />
    </S.Container>
  );
}

export default Loading;
