import { useState } from 'react';

import { useGooglePlacesServices } from 'providers/GooglePlacesProvider';
import { useLocalization } from 'providers/LocalizationProvider';
import { languageByCountry } from 'providers/LocalizationProvider/utils';

interface Option {
  placeId: string;
  label: string;
}

function useGoogleAutocomplete() {
  const { country } = useLocalization();
  const { sessionToken, autocompleteService, placesService, setPlace } =
    useGooglePlacesServices();
  const [value, setValue] = useState('');
  const [options, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);

  async function handleChange(input: string) {
    setValue(input);

    if (!input) {
      setOptions([]);
      return;
    }

    setLoading(true);
    await autocompleteService?.getPlacePredictions(
      {
        sessionToken,
        input,
        types: ['establishment'],
        ...(country && {
          language: languageByCountry[country],
          componentRestrictions: {
            country,
          },
        }),
      },
      placePredictions => {
        const list = placePredictions ?? [];
        setOptions(
          list.map(option => ({
            placeId: option.place_id,
            label: option.description,
          })),
        );
        setLoading(false);
      },
    );
  }

  async function handleSelect(selected: string) {
    const { placeId } = options.find(({ label }) => label === selected) ?? {};
    if (!placeId) return;

    setValue(selected);

    await placesService?.getDetails(
      {
        sessionToken,
        placeId,
        ...(country && {
          language: languageByCountry[country],
        }),
        fields: [
          'name',
          'rating',
          'formatted_address',
          'address_components',
          'international_phone_number',
          'website',
          'geometry',
        ],
      },
      details => {
        setPlace?.({
          place_id: placeId,
          ...details,
        });
      },
    );
  }

  function handleClean() {
    setValue('');
    setOptions([]);
    setPlace?.(undefined);
  }

  return {
    value,
    options,
    handleChange,
    handleSelect,
    handleClean,
    loading,
  };
}

export default useGoogleAutocomplete;
