import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { IntlProvider, useIntl } from 'react-intl';

import translations from 'translations';

import {
  Country,
  Currency,
  Language,
  defaultLanguage,
  languageByCountry,
  resolveCurrency,
  resolveInitialLocalization,
} from './utils';

export interface LocalizationContext {
  language: Language;
  country?: Country;
  currency?: Currency;
  setCountry: (country: Country) => void;
}

export const LocalizationContext = createContext<LocalizationContext>({
  ...resolveInitialLocalization(),
  setCountry: () => undefined,
});

export function LocalizationProvider({ children }: PropsWithChildren) {
  const [localization, setLocalization] = useState(resolveInitialLocalization);

  const value = useMemo(() => {
    function setCountry(country: Country) {
      setLocalization({
        ...localization,
        country,
        currency: resolveCurrency(country),
      });
    }

    return { ...localization, setCountry };
  }, [localization]);

  return (
    <LocalizationContext.Provider value={value}>
      <IntlProvider
        locale={localization.language}
        defaultLocale={defaultLanguage}
        messages={translations[localization.language]}
      >
        {children}
      </IntlProvider>
    </LocalizationContext.Provider>
  );
}

export interface UseLocalization extends LocalizationContext {
  t: (key: string, values?: Record<string, string>) => string;
}

export function useLocalization(context?: string): UseLocalization {
  const value = useContext(LocalizationContext);
  const intl = useIntl();

  const t = useCallback(
    (key: string, values?: Record<string, string>) =>
      intl.formatMessage(
        {
          id: context ? `${context}.${key}` : key,
        },
        values,
      ),
    [context, intl],
  );

  return { ...value, t };
}

export function useTranslationByCountry() {
  const { country } = useLocalization();

  const language = languageByCountry[country ?? 'us'];
  const copies: Record<string, string> = translations[language];

  const t = useCallback((key: string) => copies[key], [copies]);

  return t;
}
