import { Input as YogaInput } from '@gympass/yoga';
import { ChangeEvent } from 'react';
import { useController } from 'react-hook-form';

import { InputProps } from '..';

const EMAIL_VALIDATION_REGEX =
  /^([A-Z0-9_+-]+\.?)*[A-Z0-9_+-]@([A-Z0-9][A-Z0-9-]*\.)+[A-Z]{2,}$/i;

export interface InputEmailProps extends InputProps {
  invalidMessage: string;
  maxLength?: number;
  hideMaxLength?: boolean;
}

function InputEmail({
  controllerOptions,
  label,
  helper,
  invalidMessage,
  maxLength = 255,
  hideMaxLength = true,
  full = true,
  cleanable = false,
  onClean,
}: InputEmailProps) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    ...controllerOptions,
    rules: {
      ...controllerOptions.rules,
      pattern: {
        value: EMAIL_VALIDATION_REGEX,
        message: invalidMessage,
      },
    },
  });

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    onChange(target.value.toLowerCase());
  };

  return (
    <YogaInput
      value={value}
      label={label}
      helper={helper}
      maxLength={maxLength}
      hideMaxLength={hideMaxLength}
      full={full}
      onChange={handleChange}
      error={error?.message}
      cleanable={cleanable}
      onClean={onClean}
      inputMode="email"
    />
  );
}

export default InputEmail;
