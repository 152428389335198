import { Text, theme } from '@gympass/yoga';
import { Img2d06 } from '@gympass/yoga-illustrations';
import { Girl } from '@yoga-inner/illustrations';
import styled from 'styled-components';

export const Image = styled(Img2d06)`
  width: 7rem;
  height: auto;
  margin-bottom: ${theme.spacing.small}px;
`;

export const GirlImage = styled(Girl)`
  width: 7rem;
  height: auto;
  margin-bottom: ${theme.spacing.small}px;
`;

export const Title = styled(Text.Medium)<{ isMobile: boolean }>`
  font-size: ${p =>
    p.isMobile ? theme.fontSizes.medium : theme.fontSizes.xlarge}px;
`;
