import { useMemo } from 'react';

import { InputGooglePlaceProps, InputTextProps } from 'components/Input';
import { useLocalization } from 'providers/LocalizationProvider';

interface FormFields {
  googlePlace: InputGooglePlaceProps;
  additionalAddressInfo: InputTextProps;
}

export function useFields() {
  const { t } = useLocalization('business_details_page');

  return useMemo<FormFields>(
    () => ({
      googlePlace: {
        noResults: t('input.google_place.no_results'),
        label: t('input.google_place.label'),
        controllerOptions: {
          name: 'googlePlace',
          rules: {
            required: t('input.google_place.error_required'),
          },
        },
      },
      additionalAddressInfo: {
        label: t('input.additional_business_address_info.label'),
        helper: t('input.additional_business_address_info.helper_text'),
        controllerOptions: {
          shouldUnregister: true,
          name: 'additionalAddressInfo',
          rules: {
            maxLength: {
              value: 100,
              message: t(
                'input.additional_business_address_info.max_limit_characters',
              ),
            },
          },
        },
      },
    }),
    [t],
  );
}
