import { ICookieBanner } from '@gympass/cookies-consent-manager';

import { Country, Language } from 'providers/LocalizationProvider/utils';

export interface CookieCategories extends Record<string, boolean> {
  marketing: boolean;
  analytics: boolean;
  functional: boolean;
  essential: true;
}

export const bannerLanguages: Record<Language, ICookieBanner['language']> = {
  it: 'it-it',
  'es-MX': 'es-mx',
  es: 'es-es',
  'es-AR': 'es-ar',
  'es-CL': 'es-cl',
  de: 'de-de',
  'en-GB': 'en-uk',
  en: 'en-us',
  'en-US': 'en-us',
  'en-IE': 'en-ie',
  'pt-BR': 'pt-br',
  pt: 'pt-br',
};

export const initialCategories: Record<'optIn' | 'optOut', CookieCategories> = {
  optIn: {
    marketing: false,
    analytics: false,
    functional: false,
    essential: true,
  },
  optOut: {
    marketing: true,
    analytics: true,
    functional: true,
    essential: true,
  },
};

export const initialCategoriesByCountry: Record<Country, CookieCategories> = {
  us: initialCategories.optOut,
  mx: initialCategories.optOut,
  br: initialCategories.optOut,
  ar: initialCategories.optOut,
  cl: initialCategories.optOut,
  gb: initialCategories.optIn,
  ie: initialCategories.optIn,
  de: initialCategories.optIn,
  es: initialCategories.optIn,
  it: initialCategories.optIn,
};

export function resolveInitialCategories(country?: Country) {
  if (!country) {
    return initialCategories.optIn;
  }

  return initialCategoriesByCountry[country];
}
