import { useMutation } from '@tanstack/react-query';

import config from 'config';
import { Lead, LeadContext } from 'providers/LeadProvider';

export interface CreateLeadVariables {
  step: 1;
  isLastStep: false;
  lastStepSubmitted: number;
  emailValidationId?: string;
  lead: {
    firstName: NonNullable<Lead['firstName']>;
    lastName: NonNullable<Lead['lastName']>;
    legalName: NonNullable<Lead['legalName']>;
    businessEmail: NonNullable<Lead['businessEmail']>;
    country: NonNullable<Lead['country']>;
    currency: NonNullable<Lead['currency']>;
  };
}

export interface CreateLeadResponse {
  selfSignUpId: NonNullable<LeadContext['selfSignUpId']>;
  lead: {
    id: NonNullable<Lead['id']>;
  };
}

async function postLead(
  variables: CreateLeadVariables,
): Promise<CreateLeadResponse> {
  const url = `${config.PARTNERS_SELF_SIGNUP_BFF_URL}/signup`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(variables),
  });

  if (!response.ok) {
    throw new Error(`${response.status} (${response.statusText})`);
  }

  const result = await response.json();

  return result;
}

export function useCreateLead() {
  return useMutation({
    mutationFn: postLead,
  });
}

export interface UpdateLeadVariables {
  step: number;
  isLastStep: boolean;
  lastStepSubmitted: number;
  selfSignUpId: NonNullable<LeadContext['selfSignUpId']>;
  lead: Lead;
}

async function updateLead(variables: UpdateLeadVariables) {
  const url = `${config.PARTNERS_SELF_SIGNUP_BFF_URL}/signup/${variables.selfSignUpId}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(variables),
  });

  if (!response.ok) {
    throw new Error(`${response.status} (${response.statusText})`);
  }

  const result = await response.json();

  return result;
}

export function useUpdateLead() {
  return useMutation({
    mutationFn: updateLead,
  });
}

export interface GetLeadVariables {
  businessEmail: string;
  validationId: string;
}

export interface GetLeadResponse {
  step: number;
  lastStepSubmitted: number;
  selfSignUpId: string;
  lead: Lead;
}

async function getLead(
  variables: GetLeadVariables,
): Promise<GetLeadResponse | undefined> {
  const url = new URL(`${config.PARTNERS_SELF_SIGNUP_BFF_URL}/signup`);
  url.searchParams.append('businessEmail', variables.businessEmail);
  url.searchParams.append('emailValidationId', variables.validationId);

  const response = await fetch(url);

  if (!response.ok) {
    if (response.status === 404) {
      return undefined;
    }
    throw new Error(`${response.status} (${response.statusText})`);
  }

  const result = await response.json();

  return result;
}

export function useGetLead() {
  return useMutation({
    mutationFn: getLead,
  });
}
