import { LocalizationContext } from 'providers/LocalizationProvider';
import type { Language } from 'providers/LocalizationProvider/utils/localization';

import { HELP_CENTER_URLS } from './links';

const gympassWebsiteLanguages: Record<LocalizationContext['language'], string> =
  {
    de: 'de-de',
    'en-GB': 'en-uk',
    'en-IE': 'en-uk',
    'en-US': 'en-us',
    en: 'en-us',
    'es-AR': 'es-ar',
    'es-CL': 'es-cl',
    'es-MX': 'es-mx',
    es: 'es-es',
    it: 'it-it',
    'pt-BR': 'pt-br',
    pt: 'pt-br',
  };

const goToGympassWebsite = (language: LocalizationContext['language']) => {
  window.open(
    `https://gympass.com/${gympassWebsiteLanguages[language]}/partners`,
    '_self',
  );
};

const goToHelpCenter = (language: Language) => {
  window.open(HELP_CENTER_URLS[language], '_blank');
};

const goToPartnersPortal = () =>
  window.open('https://partners.gympass.com', '_self');

const goToGoogleBusinessRegister = () =>
  window.open(
    'https://business.google.com/create/new?service=ome&gmbsrc=ww-ww-et-gs-z-gmb-v-z-h~bhc-core-u%7Cmybb&skipLandingPage&original_intent=GMB&omesrcexp=48778922&omec=EKqdoRcyAgECOi5nbWJzcmM9d3ctd3ctZXQtZ3Mtei1nbWItdi16LWh-YmhjLWNvcmUtdXxteWJiQAFKEwizguLP-pWAAxUDjq4KHYaQAYo%3D',
    '_blank',
  );

const goToAboutW9Form = () =>
  window.open('https://www.irs.gov/forms-pubs/about-form-w-9', '_blank');

export {
  goToGympassWebsite,
  goToHelpCenter,
  goToPartnersPortal,
  goToGoogleBusinessRegister,
  goToAboutW9Form,
};
