import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

export interface StepsContext {
  total: number;
  current: number;
  next: () => void;
  prev: () => void;
  jump: (step: number) => void;
  updateLastStepSubmitted: () => number;
  updateTotalSteps: (total: number) => void;
  isRebrandingEnabled?: boolean;
}

export const StepsContext = createContext<StepsContext>({
  total: 1,
  current: 1,
  next: () => null,
  prev: () => null,
  jump: () => null,
  updateLastStepSubmitted: () => 1,
  updateTotalSteps: () => null,
  isRebrandingEnabled: false,
});

export interface StepsProviderProps {
  initialTotalSteps: number;
  initialStep?: number;
  isRebrandingEnabled?: boolean;
}

export function StepsProvider({
  children,
  initialTotalSteps,
  initialStep = 1,
  isRebrandingEnabled,
}: PropsWithChildren<StepsProviderProps>) {
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [lastStepSubmitted, setLastStepSubmitted] = useState(0);
  const [totalSteps, setTotalSteps] = useState(initialTotalSteps);

  const value = useMemo(() => {
    const next = () => {
      if (currentStep === totalSteps) return;
      setCurrentStep(currentStep + 1);
    };
    const prev = () => {
      if (currentStep === 1) return;
      setCurrentStep(currentStep - 1);
    };
    const jump = (step: number) => {
      if (step < 1 || step > totalSteps) return;
      setCurrentStep(step);
    };

    const updateLastStepSubmitted = () => {
      if (currentStep > lastStepSubmitted) {
        setLastStepSubmitted(currentStep);
        return currentStep;
      }
      return lastStepSubmitted;
    };

    const updateTotalSteps = (total: number) => {
      setTotalSteps(total);
    };

    return {
      total: totalSteps,
      current: currentStep,
      next,
      prev,
      jump,
      updateLastStepSubmitted,
      updateTotalSteps,
      isRebrandingEnabled,
    };
  }, [totalSteps, currentStep, lastStepSubmitted, isRebrandingEnabled]);

  return (
    <StepsContext.Provider value={value}>{children}</StepsContext.Provider>
  );
}

interface StepsConsumerProps {
  children: (value: StepsContext) => JSX.Element;
}

export function StepsConsumer({ children }: StepsConsumerProps) {
  return <StepsContext.Consumer>{children}</StepsContext.Consumer>;
}

export function useSteps() {
  const value = useContext(StepsContext);
  return value;
}
