import { Text } from '@gympass/yoga';
import { Time } from '@gympass/yoga-icons';
import tokens from '@gympass/yoga-tokens';

import { useLocalization } from 'providers/LocalizationProvider';

import * as S from './Footer.styles';

function Footer() {
  const { t } = useLocalization('register_page');

  return (
    <S.Footer>
      <Time stroke={tokens.colors.vibin} width={12} height={12} />
      <Text.Tiny aria-hidden>{t('form_duration')}</Text.Tiny>
    </S.Footer>
  );
}

export default Footer;
