import { theme } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';
import styled from 'styled-components';

const { colors, spacing } = theme;

export const Container = styled.div`
  max-width: 656px;
  padding-inline: ${spacing.medium}px;
  margin: ${spacing.xxlarge}px auto;

  ${media.md`
    padding: 0 ${spacing.xxxlarge}px;
    margin: ${spacing.xxxlarge}px auto;
  `};
`;

export const FullDivider = styled.div`
  height: ${spacing.xsmall}px;
  background: ${colors.clear};
  box-shadow: 0 4px 6px -4px #9898a640 inset;
`;
