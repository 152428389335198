import { CookieBanner, ICookieBanner } from '@gympass/cookies-consent-manager';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';

import { useLocalization } from 'providers/LocalizationProvider';

import {
  CookieCategories,
  bannerLanguages,
  resolveInitialCategories,
} from './utils';

export const CookiesConsentManagerContext = createContext(
  resolveInitialCategories(),
);

export function CookiesConsentManagerProvider({ children }: PropsWithChildren) {
  const { country, language } = useLocalization();
  const [consentPreferences, setConsentPreferences] =
    useState<CookieCategories>(() => resolveInitialCategories(country));

  const updateConsentPreferences = useCallback(
    (preferences: ICookieBanner['initialCategories']) => {
      setConsentPreferences(preferences as CookieCategories);
    },
    [],
  );

  return (
    <CookiesConsentManagerContext.Provider value={consentPreferences}>
      <CookieBanner
        language={bannerLanguages[language]}
        initialCategories={resolveInitialCategories(country)}
        onUserConsentOrDeny={updateConsentPreferences}
        policyVersion="1.0.0"
      />
      {children}
    </CookiesConsentManagerContext.Provider>
  );
}

export function useCookiesPreferences() {
  const { language } = useLocalization();
  const [cookies] = useCookies(['gp_consent_privacy']);
  const storedPreferences = useContext(CookiesConsentManagerContext);

  if (cookies.gp_consent_privacy) {
    const bannerLanguage = bannerLanguages[language];

    if (bannerLanguage in cookies.gp_consent_privacy) {
      return cookies.gp_consent_privacy[bannerLanguage];
    }
  }

  return storedPreferences;
}
