/* eslint-disable no-console */
import {
  BrowserTracker,
  StructuredEvent,
  clearUserData,
  disableAnonymousTracking,
  enableAnonymousTracking,
  newTracker,
  trackStructEvent,
} from '@snowplow/browser-tracker';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import snakecaseKeys from 'snakecase-keys';
import { v4 as uuidv4 } from 'uuid';

import config from 'config';
import { useCookiesPreferences } from 'providers/CookiesConsentManagerProvider';
import { useLocalization } from 'providers/LocalizationProvider';

interface EventData extends Omit<StructuredEvent, 'property'> {
  contextData?: Record<string, unknown>;
}

export type ErrorViewEventData = Omit<EventData, 'action'>;
export type PageViewEventData = Omit<EventData, 'action'>;
export type ClickEventData = Omit<EventData, 'action'>;

interface EventTrackingContext {
  userId?: string;
  record: (data: EventData) => void;
  recordPageView: (data: PageViewEventData) => void;
  recordClick: (data: ClickEventData) => void;
  recordErrorView: (data: ErrorViewEventData) => void;
}

const EventTrackingContext = createContext<EventTrackingContext>({
  record: () => undefined,
  recordPageView: () => undefined,
  recordClick: () => undefined,
  recordErrorView: () => undefined,
});

const trackerEndpoint = config.TRACKING_URL;
const trackerName = config.PARTNERS_ENVIRONMENT;

export const useEventTracking = () => {
  const context = useContext(EventTrackingContext);

  if (!context) {
    console.error(
      'useEventTracking must be used within a EventTrackingProvider',
    );
  }
  return context;
};

export function EventTrackingProvider({ children }: PropsWithChildren) {
  const { analytics } = useCookiesPreferences();
  const [tracker, setTracker] = useState<BrowserTracker | undefined>();
  const [userId, setUserId] = useState<string | undefined>();
  const { country, language } = useLocalization();

  const init = () => {
    const snowplow = newTracker(trackerName, trackerEndpoint, {
      appId: 'partners-self-signup-spa',
      encodeBase64: false,
      anonymousTracking: true,
    });

    if (snowplow) {
      setTracker(snowplow);
      setUserId(uuidv4());
    }
  };

  const record = useCallback(
    (data: EventData): void => {
      if (!tracker) return;

      const { category, action, label, value, contextData = {} } = data;

      if (!contextData.stepOrder) return;

      trackStructEvent(
        {
          category,
          action,
          label,
          property: userId,
          value,
          context: [
            {
              schema: 'iglu:com.gympass/click_stream/jsonschema/1-0-0',
              data: {
                country,
                language,
                ...snakecaseKeys(contextData),
              },
            },
          ],
        },
        [trackerName],
      );
    },
    [tracker, userId, country, language],
  );

  const recordErrorView = useCallback(
    (data: ErrorViewEventData) => {
      record({
        ...data,
        action: 'self_sign_up_error_view',
      });
    },
    [record],
  );

  const recordPageView = useCallback(
    (data: PageViewEventData) => {
      record({
        ...data,
        action: 'self_sign_up_page_view',
      });
    },
    [record],
  );

  const recordClick = useCallback(
    (data: ClickEventData): void => {
      record({
        ...data,
        action: 'self_sign_up_click_through',
      });
    },
    [record],
  );

  useEffect(() => {
    const isTrackingEnabled = Boolean(
      JSON.parse(config.TRACKING_ENABLED || 'false'),
    );

    if (!isTrackingEnabled) {
      console.info('tracker is not initialized');
      return;
    }

    init();
  }, []);

  useEffect(() => {
    if (analytics) {
      disableAnonymousTracking({
        stateStorageStrategy: 'cookieAndLocalStorage',
      });
    } else {
      clearUserData({
        preserveSession: false,
        preserveUser: false,
      });
      enableAnonymousTracking({
        stateStorageStrategy: 'none',
      });
    }
  }, [analytics]);

  const contextValue = useMemo(
    () => ({ userId, record, recordPageView, recordClick, recordErrorView }),
    [userId, record, recordPageView, recordClick, recordErrorView],
  );

  return (
    <EventTrackingContext.Provider value={contextValue}>
      {children}
    </EventTrackingContext.Provider>
  );
}
