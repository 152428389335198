import { Button as YogaButton } from '@gympass/yoga';
import { ArrowLeft } from '@gympass/yoga-icons';

import Button from 'components/Button';
import { useLocalization } from 'providers/LocalizationProvider';

import * as S from './Footer.styles';

interface FooterProps {
  onBack?: () => void;
  loading?: boolean;
  buttonType?: 'submit' | 'button';
  onClick?: () => void;
}

function Footer({ onBack, loading, buttonType, onClick }: FooterProps) {
  const { t } = useLocalization('footer');

  return (
    <S.Container hasBackButton={Boolean(onBack)}>
      {onBack && (
        <YogaButton.Outline
          type="button"
          onClick={onBack}
          icon={ArrowLeft}
          aria-label={t('back_button')}
        >
          {' '}
        </YogaButton.Outline>
      )}
      <Button loading={loading} type={buttonType} onClick={onClick}>
        {t('forward_button')}
      </Button>
    </S.Container>
  );
}

export default Footer;
