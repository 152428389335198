const addressPropertiesByCountry = {
  br: {
    postal_code: {
      property: 'postalCode',
      valueProperty: 'long_name',
    },
    route: {
      property: 'route',
      valueProperty: 'long_name',
    },
    street_number: {
      property: 'streetNumber',
      valueProperty: 'long_name',
    },
    administrative_area_level_2: {
      property: 'city',
      valueProperty: 'long_name',
    },
    administrative_area_level_1: {
      property: 'stateCode',
      valueProperty: 'short_name',
    },
  },
  mx: {
    postal_code: {
      property: 'postalCode',
      valueProperty: 'long_name',
    },
    route: {
      property: 'route',
      valueProperty: 'long_name',
    },
    street_number: {
      property: 'streetNumber',
      valueProperty: 'long_name',
    },
    locality: {
      property: 'city',
      valueProperty: 'long_name',
    },
    administrative_area_level_1: {
      property: 'stateCode',
      valueProperty: 'short_name',
    },
  },
  cl: {
    postal_code: {
      property: 'postalCode',
      valueProperty: 'long_name',
    },
    route: {
      property: 'route',
      valueProperty: 'long_name',
    },
    street_number: {
      property: 'streetNumber',
      valueProperty: 'long_name',
    },
    administrative_area_level_3: {
      property: 'city',
      valueProperty: 'long_name',
    },
    administrative_area_level_1: {
      property: 'stateCode',
      valueProperty: 'short_name',
    },
  },
  ar: {
    postal_code: {
      property: 'postalCode',
      valueProperty: 'long_name',
    },
    route: {
      property: 'route',
      valueProperty: 'long_name',
    },
    street_number: {
      property: 'streetNumber',
      valueProperty: 'long_name',
    },
    administrative_area_level_2: {
      property: 'city',
      valueProperty: 'long_name',
    },
    administrative_area_level_1: {
      property: 'stateCode',
      valueProperty: 'short_name',
    },
  },
  it: {
    administrative_area_level_2: {
      property: 'stateCode',
      valueProperty: 'short_name',
    },
    locality: {
      property: 'city',
      valueProperty: 'long_name',
    },
    route: {
      property: 'route',
      valueProperty: 'long_name',
    },
    street_number: {
      property: 'streetNumber',
      valueProperty: 'long_name',
    },
    postal_code: {
      property: 'postalCode',
      valueProperty: 'long_name',
    },
  },
  de: {
    postal_code: {
      property: 'postalCode',
      valueProperty: 'long_name',
    },
    route: {
      property: 'route',
      valueProperty: 'long_name',
    },
    street_number: {
      property: 'streetNumber',
      valueProperty: 'long_name',
    },
    locality: {
      property: 'city',
      valueProperty: 'long_name',
    },
    administrative_area_level_1: {
      property: 'stateCode',
      valueProperty: 'short_name',
    },
  },
  es: {
    postal_code: {
      property: 'postalCode',
      valueProperty: 'long_name',
    },
    route: {
      property: 'route',
      valueProperty: 'long_name',
    },
    street_number: {
      property: 'streetNumber',
      valueProperty: 'long_name',
    },
    administrative_area_level_2: {
      property: 'city',
      valueProperty: 'long_name',
    },
    administrative_area_level_1: {
      property: 'stateCode',
      valueProperty: 'short_name',
    },
  },
  gb: {
    administrative_area_level_1: {
      property: 'stateCode',
      valueProperty: 'short_name',
    },
    postal_town: {
      property: 'city',
      valueProperty: 'long_name',
    },
    route: {
      property: 'route',
      valueProperty: 'long_name',
    },
    street_number: {
      property: 'streetNumber',
      valueProperty: 'long_name',
    },
    postal_code: {
      property: 'postalCode',
      valueProperty: 'long_name',
    },
  },
  ie: {
    administrative_area_level_1: {
      property: 'stateCode',
      valueProperty: 'short_name',
    },
    locality: {
      property: 'city',
      valueProperty: 'long_name',
    },
    route: {
      property: 'route',
      valueProperty: 'long_name',
    },
    postal_code: {
      property: 'postalCode',
      valueProperty: 'long_name',
    },
    street_number: {
      property: 'streetNumber',
      valueProperty: 'long_name',
    },
  },
  us: {
    postal_code: {
      property: 'postalCode',
      valueProperty: 'long_name',
    },
    route: {
      property: 'route',
      valueProperty: 'long_name',
    },
    street_number: {
      property: 'streetNumber',
      valueProperty: 'long_name',
    },
    locality: {
      property: 'city',
      valueProperty: 'long_name',
    },
    administrative_area_level_1: {
      property: 'stateCode',
      valueProperty: 'short_name',
    },
  },
} as const;

function resolveAddressProperties(
  addressComponents: google.maps.GeocoderAddressComponent[],
) {
  const country = addressComponents.find(({ types }) =>
    types.includes('country'),
  );

  if (!country) return undefined;
  const countryCode = country.short_name.toLowerCase();
  if (!(countryCode in addressPropertiesByCountry)) return undefined;

  return addressPropertiesByCountry[
    countryCode as keyof typeof addressPropertiesByCountry
  ];
}

export function resolveAddress(
  addressComponents?: google.maps.GeocoderAddressComponent[],
) {
  if (!addressComponents) return undefined;
  const addressProperties = resolveAddressProperties(addressComponents);

  if (!addressProperties) return undefined;

  return addressComponents.reduce(
    (acc, { types: [type], ...componentValues }) => {
      if (!(type in addressProperties)) return acc;

      const { property, valueProperty } =
        addressProperties[type as keyof typeof addressProperties];
      const value = componentValues[valueProperty];

      if (property === 'route') {
        return {
          ...acc,
          street: acc.street ? `${value}, ${acc.street}` : value,
        };
      }

      if (property === 'streetNumber') {
        return {
          ...acc,
          street: acc.street ? `${acc.street}, ${value}` : value,
        };
      }

      return { ...acc, [property]: value };
    },
    {
      state: '',
      stateCode: '',
      city: '',
      street: '',
      postalCode: '',
    },
  );
}
